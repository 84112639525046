import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannerNote} from '../../../../../state/modules/plannerNotes/actions';
import PlannerNoteViewScreen from '../components/PlannerNoteViewScreen';

const mapStateToProps = (state, ownProps) => {

  const plannerNote = ownProps.match.params.id
    ? denormalize(
        state.entities.getIn(['plannerNotes', ownProps.match.params.id]),
        schema.plannerNote,
        state.entities.toJS()
      )
    : null;;

  return {
    isLoading: state.plannerNotes.get('isLoading'),
    plannerNote: plannerNote,
    id: ownProps.match.params.id,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerNote(id, cb));
    },
  }
}

const PlannerNoteViewScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerNoteViewScreen);

export default PlannerNoteViewScreenContainer;
