import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannerAccounts, deletePlannerAccount} from '../../../../../state/modules/plannerAccounts/actions';
import PlannerAccountsList from '../components/PlannerAccountsList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.plannerAccounts.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['plannerAccounts', x]),
              schema.plannerAccount,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {
          // flatten some nested objects to make table easier to work with
          return {
            owner_username: x.planner?.owner?.profile?.user?.username || '',
            ...x
          };
        })
      : [],
    isLoading: state.plannerAccounts.get('isLoading'),
    total: state.plannerAccounts.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlannerAccounts(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePlannerAccount(id, cb));
    },
  }
}

const PlannerAccountsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerAccountsList);

export default PlannerAccountsListContainer;