import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerBanksScreen from './containers/PlannerBanksScreenContainer';
import PlannerBankAddScreen from './containers/PlannerBankAddScreenContainer';
import PlannerBankEditScreen from './containers/PlannerBankEditScreenContainer';

const register = () => {
  Logger.log('debug', `plannerBanks.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerBanks', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerBankAddScreen': ['PrivateRoute', '/planner-banks/add', true, PlannerBankAddScreen, getI18n().t('planner_bank_route_add')],
        'PlannerBankEditScreen': ['PrivateRoute', '/planner-banks/edit/:id([\\w\\-]+)', true, PlannerBankEditScreen, getI18n().t('planner_bank_route_edit')],
        'PlannerBanksScreen': ['PrivateRoute', '/planner-banks/:page(\\d+)?', true, PlannerBanksScreen, getI18n().t('planner_banks_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `plannerBanks.register() loaded.`);