import {connect} from 'react-redux';

import PlannerToDoSuggestedAddScreen from '../components/PlannerToDoSuggestedAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerToDosSuggested.get('isLoading'),
    success: state.plannerToDosSuggested.getIn(['form', 'success']),
  }
}

const PlannerToDoSuggestedAddScreenContainer = connect(
  mapStateToProps
)(PlannerToDoSuggestedAddScreen);

export default PlannerToDoSuggestedAddScreenContainer;