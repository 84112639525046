import {connect} from 'react-redux';

import PlannerDocumentsScreen from '../components/PlannerDocumentsScreen';

const mapStateToProps = state => {
  return {
    total: state.plannerDocuments.get('total'),
    isLoading: state.plannerDocuments.get('isLoading')
  }
}

const PlannerDocumentsScreenContainer = connect(
  mapStateToProps
)(PlannerDocumentsScreen);

export default PlannerDocumentsScreenContainer;
