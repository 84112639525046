import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadPlannerOwner,
  updatePlannerOwner,
  createPlannerOwner,
  deletePlannerOwner,
  plannerOwnerFormDestroy
} from '../../../../../state/modules/plannerOwners/actions';
import {sendMessage} from '../../../../../state/actions';
import PlannerOwnerForm from '../components/PlannerOwnerForm';

const inputs = List([
  'account_id',
  'profile',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['plannerOwners', ownProps.id, val], '');
    if (['profile'].includes(val)) {
      errors[val] = state.plannerOwners.getIn(['form', 'errors', 'user_profile_id']);
    }
    else {
      errors[val] = state.plannerOwners.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  return {
    isLoading: state.plannerOwners.get('isLoading'),
    isSubmitting: state.plannerOwners.getIn(['form', 'isSubmitting']),
    success: state.plannerOwners.getIn(['form', 'success']),
    created_id: state.plannerOwners.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['plannerOwners', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['plannerOwners', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerOwner(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePlannerOwner(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPlannerOwner(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlannerOwner(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(plannerOwnerFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PlannerOwnerFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerOwnerForm);

export default PlannerOwnerFormContainer;
