import {connect} from 'react-redux';

import CallsScreen from '../components/CallsScreen';

const mapStateToProps = state => {
  return {
    total: state.calls.get('total'),
    isLoading: state.calls.get('isLoading')
  }
}

const CallsScreenContainer = connect(
  mapStateToProps
)(CallsScreen);

export default CallsScreenContainer;
