import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerAnswersScreen from './containers/PlannerAnswersScreenContainer';
import PlannerAnswerViewScreen from './containers/PlannerAnswerViewScreenContainer';

const register = () => {
  Logger.log('debug', `plannerAnswers.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerAnswers', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerAnswerViewScreen': ['PrivateRoute', '/planner-answers/view/:id([\\w\\-]+)', true, PlannerAnswerViewScreen, getI18n().t('planner_answer_route_view')],
        'PlannerAnswersScreen': ['PrivateRoute', '/planner-answers/:page(\\d+)?', true, PlannerAnswersScreen, getI18n().t('planner_answers_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `plannerAnswers.register() loaded.`);
