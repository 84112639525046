import {Map, List} from 'immutable';

import {
  PLANNER_ANSWER_LIST_REQUEST,
  PLANNER_ANSWER_LIST_SUCCESS,
  PLANNER_ANSWER_LIST_FAILURE,
  PLANNER_ANSWER_READ_REQUEST,
  PLANNER_ANSWER_READ_SUCCESS,
  PLANNER_ANSWER_READ_FAILURE,
  PLANNER_ANSWER_CSV_DOWNLOAD_REQUEST,
  PLANNER_ANSWER_CSV_DOWNLOAD_SUCCESS,
  PLANNER_ANSWER_CSV_DOWNLOAD_FAILURE
} from './actions'
import Logger from '../../../lib/Logger';

export default function plannerAnswers(
  state=Map({
    isLoading: false,
    isCSVDownloading: false,
    pages: {}
  }),
  action
) {
  Logger.log('debug', `[plannerAnswers.reducers] plannerAnswers(%j, %j)`, state, action);

  switch(action.type) {

    case PLANNER_ANSWER_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case PLANNER_ANSWER_LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        pages: {
          [action.order]: {
            [action.limit]: {
              [action.page]: null
            }
          }
        },
        total: action.total,
        lastUpdated: action.receivedAt
      }).setIn(['pages', action.order, action.limit, action.page], List(action.result));

    case PLANNER_ANSWER_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case PLANNER_ANSWER_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case PLANNER_ANSWER_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case PLANNER_ANSWER_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case PLANNER_ANSWER_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true
      });

    case PLANNER_ANSWER_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case PLANNER_ANSWER_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    default:
      return state;
  }
}

Logger.log('silly', `plannerAnswers.reducers loaded.`);
