import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import {
  Affix,
  Card,
  Col,
  Checkbox,
  Form,
  Input,
  Row,
  Spin,
  Upload,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';
import CategoryInput from '../../resourceCategories/containers/CategoryInputContainer';
import PlannerInput from '../../planners/containers/PlannerInputContainer';
import BodyInput from '../../resources/components/BodyInput';

import imageURL from '../../../../../lib/imageUrl';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

// upload criteria
const fileTypes = {
  'image/png': 'png',
  'image/jpeg': 'jpg',
};
const fileSizeLimit = 1024 * 1024 * 6;
const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;

const beforeUpload = (file) => {
  const isSupportedFileType = Object.keys(fileTypes).includes(file.type);
  if (!isSupportedFileType) {
    message.error(
      getI18n().t('planner_resource_form_feedback_image_file_types')
    );
  }

  const isUnderFileSizeLimit = file.size < fileSizeLimit;
  if (!isUnderFileSizeLimit) {
    message.error(
      getI18n().t('planner_resource_form_feedback_image_file_size_limit')
    );
  }

  return isSupportedFileType && isUnderFileSizeLimit
    ? true
    : Upload.LIST_IGNORE;
};

const errorMessage = () => {
  message.error(getI18n().t('planner_banks_form_feedback_image_upload_error'));
};

const PlannerResourceForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [plannerIds, setPlannerIds] = useState(data['planners']);
  const [isMultiplePlanner, setIsMultiplePlanner] = useState(false);

  const [form] = Form.useForm();
  const [mediaId, setMediaId] = useState(null);
  const [imageUrl, setImageUrl] = useState();

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <Translation>
      {(t) => (
        <div>
          {props.isSubmitting ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>{t('action_upload')}</div>
        </div>
      )}
    </Translation>
  );

  const customRequest = (e) => {
    props.getUploadUrl(
      e.file.name.split('.').pop(),
      'resources-logo',
      async (success, upload_url, file_id) => {
        if (success) {
          // upload image
          props.upload(upload_url, e.file, async (uploadSuccess) => {
            const directory = 'resources-logo/';
            file_id = file_id.replace(directory, '');
            if (uploadSuccess) {
              const matches = reFileKey.exec(file_id);
              const updateData = {
                file_directory: directory.slice(0, -1),
                file_name: matches[2],
                file_extension: matches[3],
              };

              // process image
              props.createImage(updateData, (createSuccess, imageId) => {
                if (createSuccess) {
                  setMediaId(imageId);
                  e.onSuccess(null, e.file);
                } else {
                  errorMessage();
                }
              });
            } else {
              errorMessage();
            }
          });
        } else {
          errorMessage();
        }
      }
    );
  };

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);

  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      form.setFieldsValue(dataObj);
      setPlannerIds(dataObj['planners']);
      if (dataObj['planners'].length > 0) {
        setIsMultiplePlanner(true);
      }
      if (dataObj['logo'] !== null && mediaId === null) {
        setMediaId(dataObj['logo']['id']);
        if (
          dataObj['logo']['file_bucket'] &&
          dataObj['logo']['file_directory'] &&
          dataObj['logo']['file_extension'] &&
          dataObj['logo']['file_name']
        ) {
          setImageUrl(
            imageURL(
              dataObj['logo']['file_directory'],
              dataObj['logo']['file_name'],
              dataObj['logo']['file_extension'],
              60,
              60,
              'ratio'
            )
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('PlannerResourceEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `PlannerResourceForm.submitData(###)`);
    // API POST/PUT payload
    let payload = {};

    for (const input of Object.keys(data)) {
      // transform boolean values
      if (['is_planner'].includes(input)) {
        payload[input] = true;
      } else if (['logo'].includes(input)) {
        //pass
      } else if (['is_universal', 'is_pinned'].includes(input)) {
        payload[input] = false;
      } else if (['planners'].includes(input)) {
        if (isMultiplePlanner) {
          payload['planner_ids'] = plannerIds;
        }
      } else if (['country', 'region', 'city', 'postal_code'].includes(input)) {
        payload[input + '_id'] = null;
      } else if (['resource_category'].includes(input)) {
        if (values[input]) {
          payload[input + '_id'] = values[input];
        }
      }
      // handle optional inputs
      else if (['telephone', 'website', 'email'].includes(input)) {
        if (values[input]) {
          payload[input] = values[input];
        }
      } else if (['description'].includes(input)) {
        payload[input] = values[input] ? values[input] : '';
      } else if (input in values) {
        payload[input] = values[input];
      }
    }

    if (mediaId) {
      payload['logo_id'] = mediaId;
    }

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `PlannerResourceForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `PlannerResourceForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  const handleChangePlanner = (value) => {
    setPlannerIds(value);
    return value;
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="resource-form">
            <Form
              name="resource_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id ? t('resources_edit_title') : t('resources_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <Form.Item name="address1" style={{ display: 'none' }}>
                      <Input type="hidden" />
                    </Form.Item>

                    <Form.Item name="address2" style={{ display: 'none' }}>
                      <Input type="hidden" />
                    </Form.Item>

                    <div className="form-group">
                      <Form.Item
                        name="is_multiple_planner"
                        label={t('resources_is_multiple_planner')}
                      >
                        <Checkbox
                          checked={isMultiplePlanner}
                          onChange={(evt) =>
                            setIsMultiplePlanner(evt.target.checked)
                          }
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                    </div>

                    {isMultiplePlanner && (
                      <PlannerInput
                        multiple={true}
                        name="planners"
                        handleChange={handleChangePlanner}
                        label={t('resources_planner_owners')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                      />
                    )}
                    <CategoryInput
                      multiple={true}
                      name="categories"
                      label={t('resources_category')}
                    />
                    <div className="form-group">
                      <Form.Item
                        name="name"
                        label={t('resources_name')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 2,
                            max: 120,
                            message: t('feedback_validation_char_range', {
                              min: 2,
                              max: 120,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <BodyInput
                      name="description"
                      label={t('resources_description')}
                      form={form}
                      defaultValue={form.getFieldValue(['description'])}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="telephone"
                        label={t('resources_telephone')}
                        rules={[
                          {
                            type: 'string',
                            min: 10,
                            max: 15,
                            message: t('feedback_validation_char_range', {
                              min: 10,
                              max: 15,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item name="website" label={t('resources_website')}>
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="email"
                        label={t('resources_email')}
                        rules={[
                          {
                            type: 'email',
                            message: t('feedback_validation_email'),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item name="logo" label={t('resources_logo')}>
                        <Row>
                          <Col>
                            <Upload
                              name="logo"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              customRequest={customRequest}
                              beforeUpload={beforeUpload}
                              onChange={handleChange}
                            >
                              {imageUrl ? (
                                <img
                                  src={imageUrl}
                                  alt="avatar"
                                  style={{
                                    width: '100%',
                                  }}
                                />
                              ) : (
                                uploadButton
                              )}
                            </Upload>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="PlannerResourcesScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default PlannerResourceForm;

Logger.log('silly', `PlannerResourceForm loaded.`);
