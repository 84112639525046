import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadResourceCategoriesEntry} from '../../../../../state/modules/resourceCategories/actions';
import CategoryInput from '../components/CategoryInput';

const mapStateToProps = (state, ownProps) => {

  const order = 'created_at.desc';

  const result = state.resourceCategories.getIn(['entries', order]);
  
  const categories = result
    ? result.map(x => {
        return {
          key: x,
          ...denormalize(
            state.entities.getIn(['resourceCategoryEntries', x]),
            schema.resourceCategoryEntry,
            state.entities.toJS()
          )
        };
      }).toArray()
    : [];
    return {
    options: categories.map(x => {
      return {label: x ? x.category : '', value: x ? x.id : ''};
    }),
    isLoading: state.resourceCategories.get('areEntriesLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadResourceCategoriesEntry(page, limit, order, filter, cb));
    },
  }
}

const CategoryInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryInput);

export default CategoryInputContainer;
