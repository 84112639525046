import { schema as s } from 'normalizr';

import Logger from '../lib/Logger';

const country = new s.Entity('countries');
const region = new s.Entity('regions', {
  country: country,
});
const subregion = new s.Entity('subregions', {
  region: region,
});
const city = new s.Entity('cities', {
  region: region,
  subregion: subregion,
});
const postalCode = new s.Entity('postalCodes', {
  region: region,
  subregion: subregion,
  city: city,
});
const role = new s.Entity('roles');
const termsOfService = new s.Entity('termsOfServices');
const user = new s.Entity('users', {
  roles: [role],
});
const userProfile = new s.Entity('userProfiles', {
  user: user,
});

const administrator = new s.Entity('administrators', {
  roles: [role],
});
const appKey = new s.Entity('appKeys');
const login = new s.Entity('logins');
const relationship = new s.Entity('relationships');
const passwordReset = new s.Entity('passwordResets', {
  user: user,
});
const importJob = new s.Entity('importJobs', {
  administrator: administrator,
});

// care guides
const CareGuideSegmentContent = new s.Entity('CareGuideSegmentContents');
const careGuideSegment = new s.Entity('careGuideSegments', {
  contents: [CareGuideSegmentContent],
});

const careGuideHelperTag = new s.Entity('careGuideHelperTags');

const careGuideMember = new s.Entity('careGuideMembers', {
  profile: userProfile,
});

const careGuideSectionField = new s.Entity('careGuideSectionFields');
const careGuideSection = new s.Entity('careGuideSections', {
  fields: [careGuideSectionField],
});
const careGuideTemplateSection = new s.Entity('careGuideTemplateSections', {
  section: careGuideSection,
});
const careGuideTemplate = new s.Entity('careGuideTemplates', {
  sections: [careGuideTemplateSection],
});

// accounts
const plan = new s.Entity('plans', {
  template: careGuideTemplate,
});

const account = new s.Entity('accounts', {
  plan: plan,
  owner: userProfile,
  template: careGuideTemplate,
});

const careGuideOwner = new s.Entity('careGuideOwners', {
  profile: userProfile,
  account: account,
});

const careGuide = new s.Entity('careGuides', {
  relationship: relationship,
  owner: careGuideOwner,
  account: account,
  segments: [careGuideSegment],
});

const careGuideInvitation = new s.Entity('careGuideInvitations', {
  care_guide: careGuide,
  profile: userProfile,
  inviter: userProfile,
});

const careGuideHelper = new s.Entity('careGuideHelpers', {
  care_guide: careGuide,
  profile: userProfile,
});

// partnerships

const partnership = new s.Entity('partnerships');
const userProfilesPartnership = new s.Entity('userProfilesPartnerships');

partnership.define({
  plan: plan,
  user_profiles_partnerships: [userProfilesPartnership],
});

userProfilesPartnership.define({
  profile: userProfile,
  partnership: partnership,
});

// media
const medium = new s.Entity('media', {
  care_guide: careGuide,
  owner: userProfile,
  partnership: partnership,
});

// journals
const journalPost = new s.Entity('journalPosts', {
  care_guide: careGuide,
  profile: userProfile,
  media: [medium],
});
const journalReply = new s.Entity('journalReplies', {
  profile: userProfile,
});
const journalTag = new s.Entity('journalTags');
const journalPartnerTag = new s.Entity('journalPartnerTags');
const journalPartnerTagsCareGuide = new s.Entity(
  'journalPartnerTagsCareGuides'
);

// messages
const messageRecipient = new s.Entity('messageRecipients', {
  profile: userProfile,
});
const message = new s.Entity('messages', {
  care_guide: careGuide,
  profile: userProfile,
  recipients: [messageRecipient],
});

// calendar
const calendarEventParticipant = new s.Entity('calendarEventParticipants', {
  profile: userProfile,
});

const calendarEvent = new s.Entity('calendarEvents');

calendarEvent.define({
  care_guide: careGuide,
  care_guides: [careGuide],
  partnership: partnership,
  profile: userProfile,
  participants: [calendarEventParticipant],
  parent_event: calendarEvent,
});

const calendarEventException = new s.Entity('calendarEventExceptions', {
  event: calendarEvent,
});

const plannerOwner = new s.Entity('plannerOwners', {
  profile: userProfile,
});

const planner = new s.Entity('planners', {
  owner: userProfile,
  care_guide: careGuide,
  account: account,
});

const plannerAnswer = new s.Entity('plannerAnswers', {
  profile: userProfile,
});

const plannerToDoUser = new s.Entity('plannerToDosUser', {
  planner: planner,
  profile: userProfile,
});

const plannerPoa = new s.Entity('plannerPoas', {
  planner: planner,
});

const plannerLegal = new s.Entity('plannerLegals', {
  planner: planner,
});

const plannerFinancial = new s.Entity('plannerFinancials', {
  planner: planner,
});

const plannerBudget = new s.Entity('plannerBudgets', {
  planner: planner,
});

const plannerBank = new s.Entity('plannerBanks', {});

const plannerBankEntry = new s.Entity('plannerBankEntries', {});

const plannerAccountCategory = new s.Entity('plannerAccountCategories', {});

const plannerAccount = new s.Entity('plannerAccounts', {
  planner: planner,
  planner_bank: plannerBank,
  planner_account_category: plannerAccountCategory,
});

const plannerNote = new s.Entity('plannerNotes', {
  planner: planner,
});

// media
const plannerDocument = new s.Entity('plannerDocuments', {
  planner: planner,
  profile: userProfile,
});

const plannerModule = new s.Entity('plannerModules');

const plannerToDoSuggested = new s.Entity('plannerToDosSuggested', {});

// resources
const resourceCategory = new s.Entity('resourceCategories', {});
const resourceTag = new s.Entity('resourceTags');
const resourceServiceTag = new s.Entity('resourceServiceTags');
const resource = new s.Entity('resources', {
  care_guide: careGuide,
  care_guides: [careGuide],
  partnership: partnership,
  country: country,
  region: region,
  city: city,
  postal_code: postalCode,
  countries: [country],
  regions: [region],
  cities: [city],
  postal_codes: [postalCode],
});

const plannerResource = new s.Entity('plannerResources', {
  planner: planner,
  planners: [planner],
  resource_category: resourceCategory,
});

const resourceNote = new s.Entity('resourceNotes', {
  profile: userProfile,
});

const resourceCategoryEntry = new s.Entity('resourceCategoryEntries', {});

const registrationCode = new s.Entity('registrationCodes', {
  profile: userProfile,
  plan: plan,
  partnership: partnership,
});

const plannerEntry = new s.Entity('plannerEntries', {});

// registratinos
const registration = new s.Entity('registrations', {
  invitation_code: careGuideInvitation,
  registration_code: registrationCode,
  relationship: relationship,
  terms_of_service: termsOfService,
  plan: plan,
  user: user,
});

const call = new s.Entity('calls', {});
const email = new s.Entity('emails');

const adminPartnerClass = new s.Entity('adminPartnerClasses', {
  partnership: partnership,
});

const careGuidePartnerTag = new s.Entity('careGuidePartnerTags', {
  admin_partner_class: adminPartnerClass,
});

const careGuidePartnerTagsPartnership = new s.Entity(
  'careGuidePartnerTagsPartnerships'
);

const careGuideNote = new s.Entity('careGuideNotes', {
  care_guide: careGuide,
  profile: userProfile,
});

const careGuideDocument = new s.Entity('careGuideDocuments', {
  care_guide: careGuide,
  profile: userProfile,
});

const importTask = new s.Entity('importTasks', {
  care_guide: careGuide,
  administrator: administrator,
});

const alert = new s.Entity('alerts', {
  care_guide: careGuide,
  profile: userProfile,
  subject_profile: userProfile,
});

const schema = {
  country: country,
  region: region,
  subregion: subregion,
  city: city,
  postalCode: postalCode,
  user: user,
  userProfile: userProfile,
  administrator: administrator,
  role: role,
  appKey: appKey,
  termsOfService: termsOfService,
  login: login,
  plan: plan,
  relationship: relationship,
  passwordReset: passwordReset,
  registrationCode: registrationCode,
  account: account,
  careGuide: careGuide,
  careGuideSegment: careGuideSegment,
  CareGuideSegmentContent: CareGuideSegmentContent,
  careGuideOwner: careGuideOwner,
  careGuideHelperTag: careGuideHelperTag,
  careGuideHelper: careGuideHelper,
  careGuideMember: careGuideMember,
  careGuideInvitation: careGuideInvitation,
  careGuideTemplate: careGuideTemplate,
  careGuideSection: careGuideSection,
  careGuideSectionField: careGuideSectionField,
  careGuideTemplateSection: careGuideTemplateSection,
  journalPost: journalPost,
  journalReply: journalReply,
  journalTag: journalTag,
  message: message,
  messageRecipient: messageRecipient,
  calendarEvent: calendarEvent,
  calendarEventParticipant: calendarEventParticipant,
  calendarEventException: calendarEventException,
  resourceCategory: resourceCategory,
  resourceTag: resourceTag,
  resourceServiceTag: resourceServiceTag,
  resource: resource,
  resourceNote: resourceNote,
  plannerResource: plannerResource,
  medium: medium,
  registration: registration,
  importJob: importJob,
  partnership: partnership,
  userProfilesPartnership: userProfilesPartnership,
  journalPartnerTag: journalPartnerTag,
  journalPartnerTagsCareGuide: journalPartnerTagsCareGuide,
  plannerOwner: plannerOwner,
  planner: planner,
  plannerAnswer: plannerAnswer,
  plannerToDoUser: plannerToDoUser,
  plannerPoa: plannerPoa,
  plannerLegal: plannerLegal,
  plannerFinancial: plannerFinancial,
  plannerBudget: plannerBudget,
  plannerModule: plannerModule,
  plannerDocument: plannerDocument,
  plannerBank: plannerBank,
  plannerAccountCategory: plannerAccountCategory,
  plannerAccount: plannerAccount,
  plannerBankEntry: plannerBankEntry,
  resourceCategoryEntry: resourceCategoryEntry,
  plannerEntry: plannerEntry,
  plannerToDoSuggested: plannerToDoSuggested,
  call: call,
  email: email,
  plannerNote: plannerNote,
  careGuidePartnerTag: careGuidePartnerTag,
  careGuidePartnerTagsPartnership: careGuidePartnerTagsPartnership,
  adminPartnerClass: adminPartnerClass,
  careGuideNote: careGuideNote,
  careGuideDocument: careGuideDocument,
  importTask: importTask,
  alert: alert,
};

export { schema };

Logger.log('silly', `schema loaded.`);
