import { getI18n } from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerResourcesScreenContainer from './containers/PlannerResourcesScreenContainer';
import PlannerResourceAddScreenContainer from './containers/PlannerResourceAddScreenContainer';
import PlannerResourceEditScreenContainer from './containers/PlannerResourceEditScreenContainer';

const register = () => {
  Logger.log('debug', `plannerResources.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerResources', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', {
      PlannerResourceAddScreen: [
        'PrivateRoute',
        '/planner-resources/add',
        true,
        PlannerResourceAddScreenContainer,
        getI18n().t('planner_resources_route_add'),
      ],
      PlannerResourceEditScreen: [
        'PrivateRoute',
        '/planner-resources/edit/:id([\\w\\-]+)',
        true,
        PlannerResourceEditScreenContainer,
        getI18n().t('planner_resources_route_edit'),
      ],
      PlannerResourcesScreen: [
        'PrivateRoute',
        '/planner-resources/:page(\\d+)?',
        true,
        PlannerResourcesScreenContainer,
        getI18n().t('resources_route_list'),
      ],
    });
  }
};

export default register;

Logger.log('silly', `plannerResources.register() loaded.`);
