import {connect} from 'react-redux';

import PlannerOwnerAddScreen from '../components/PlannerOwnerAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerOwners.get('isLoading'),
    success: state.plannerOwners.getIn(['form', 'success']),
  }
}

const PlannerOwnerAddScreenContainer = connect(
  mapStateToProps
)(PlannerOwnerAddScreen);

export default PlannerOwnerAddScreenContainer;
