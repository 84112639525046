import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import CallsScreen from './containers/CallsScreenContainer';
import CallEditScreen from './containers/CallEditScreenContainer';

const register = () => {
  Logger.log('debug', `calls.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'calls', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'CallEditScreen': ['PrivateRoute', '/calls/edit/:id([\\w\\-]+)', true, CallEditScreen, getI18n().t('call_route_edit')],
        'CallsScreen': ['PrivateRoute', '/calls/:page(\\d+)?', true, CallsScreen, getI18n().t('calls_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `calls.register() loaded.`);
