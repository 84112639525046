import {connect} from 'react-redux';

import PlannersScreen from '../components/PlannersScreen';

const mapStateToProps = state => {
  return {
    total: state.planners.get('total'),
    isLoading: state.planners.get('isLoading')
  }
}

const PlannersScreenContainer = connect(
  mapStateToProps
)(PlannersScreen);

export default PlannersScreenContainer;
