import {connect} from 'react-redux';

import {downloadPlannerBanksCSV} from '../../../../../state/modules/plannerBanks/actions';
import PlannerBanksCSVButton from '../components/PlannerBanksCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.plannerBanks.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPlannerBanksCSV(order, filter, cb));
    }
  }
}

const PlannerBanksCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerBanksCSVButton);

export default PlannerBanksCSVButtonContainer;