import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Affix, Card, Col, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';


const DisplayInput = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label htmlFor="planner_document_form_application" className="" title="Application">{label}</label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input value={value} bordered={false} />
      </div>
    </div>
  </div>
);

const DisplayInputText = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label htmlFor="planner_document_form_application" className="" title="Application">{label}</label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input.TextArea  rows={3} value={value} bordered={false} />
      </div>
    </div>
  </div>
);

const is_pinned = {
  true: "True",
  false: "False"
}


class PlannerNoteViewScreen extends Component {

  scrollToTop = () => {
    Logger.log('debug', `PlannerNoteViewScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }

  render() {
    const { plannerNote, isLoading } = this.props;

    return (
      <Translation>{(t) =>
        <div>
          <DocumentHead title={t('planner_notes_route_view')} />

          <div className="plannerNote-view">

            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={t('planner_notes_view_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >
                  <DisplayInput label={t('planner_notes_id')} value={plannerNote ? plannerNote.id : null} />
                  <DisplayInput label={t('planner_notes_planner_id')} value={plannerNote && plannerNote.planner ? plannerNote.planner.id : null} />
                  <DisplayInput label={t('planner_notes_owner_username')} value={plannerNote && plannerNote.planner && plannerNote.planner.owner.profile && plannerNote.planner.owner.profile.user ? plannerNote.planner.owner.profile.user.username : null} />
                  <DisplayInput label={t('planner_note_title')} value={plannerNote ? plannerNote.title : null} />
                  <DisplayInputText label={t('planner_note_body')} value={plannerNote ? plannerNote.body : ""} />
                  <DisplayInput label={t('planner_note_is_pinned')} value={plannerNote ? is_pinned[plannerNote.is_pinned] : null} />
                  <DisplayInput label={t('planner_note_pinned_at')} value={plannerNote ? Format.date(plannerNote.pinned_at, Config.get('DEFAULT_DATETIME_FORMAT')) : null} />

                </Card>
              </Col>
              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    {plannerNote && plannerNote.created_at
                      ? <p>{t('form_metadata_created_at')} <small>{Format.date(plannerNote.created_at, Config.get('DEFAULT_DATETIME_FORMAT'))}</small></p>
                      : ''}
                    {plannerNote && plannerNote.updated_at && plannerNote.updated_at !== plannerNote.created_at
                      ? <p>{t('form_metadata_updated_ad')} <small>{Format.date(plannerNote.updated_at, Config.get('DEFAULT_DATETIME_FORMAT'))}</small></p>
                      : ''}
                  </Card>
                </Affix>
              </Col>
            </Row>

          </div>

        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
    this.props.load(this.props.id);
  }
}

export default PlannerNoteViewScreen;

Logger.log('silly', `PlannerNoteViewScreen loaded.`);
