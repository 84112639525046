import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';

import {
  loadPlannerToDoUser,
  updatePlannerToDoUser,
  createPlannerToDoUser,
  deletePlannerToDoUser,
  plannerToDoUserFormDestroy,
} from '../../../../../state/modules/plannerToDosUser/actions';
import { sendMessage } from '../../../../../state/actions';
import PlannerToDoUserForm from '../components/PlannerToDoUserForm';
import Config from '../../../../../Config';

const inputs = List([
  'planner',
  'title',
  'state',
  'previous_state',
  'overdue_date_at',
  'status',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(
      ['plannerToDosUser', ownProps.id, val],
      ''
    );
    if (['planner'].includes(val)) {
      errors[val] = state.plannerToDosUser.getIn([
        'form',
        'errors',
        val + '_id',
      ]);
    } else {
      errors[val] = state.plannerToDosUser.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['overdue_date_at'] = data['overdue_date_at']
    ? moment(data['overdue_date_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  return {
    isLoading: state.plannerToDosUser.get('isLoading'),
    isSubmitting: state.plannerToDosUser.getIn(['form', 'isSubmitting']),
    success: state.plannerToDosUser.getIn(['form', 'success']),
    created_id: state.plannerToDosUser.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['plannerToDosUser', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['plannerToDosUser', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerToDoUser(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePlannerToDoUser(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPlannerToDoUser(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlannerToDoUser(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(plannerToDoUserFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const PlannerToDoUserFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerToDoUserForm);

export default PlannerToDoUserFormContainer;
