import {connect} from 'react-redux';
import {List} from 'immutable';

import {
  loadPlannerAccountCategory,
  updatePlannerAccountCategory,
  createPlannerAccountCategory,
  deletePlannerAccountCategory,
  plannerAccountCategoryFormDestroy
} from '../../../../../state/modules/plannerAccountCategories/actions';
import PlannerAccountCategoryForm from '../components/PlannerAccountCategoryForm';

const inputs = List([
  'category',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['plannerAccountCategories', ownProps.id, val], '');
    if (state.plannerAccountCategories.getIn(['form', 'errors', val])) {
      errors[val] = state.plannerAccountCategories.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  return {
    isLoading: state.plannerAccountCategories.get('isLoading'),
    isSubmitting: state.plannerAccountCategories.getIn(['form', 'isSubmitting']),
    success: state.plannerAccountCategories.getIn(['form', 'success']),
    created_id: state.plannerAccountCategories.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['plannerAccountCategories', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['plannerAccountCategories', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerAccountCategory(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePlannerAccountCategory(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPlannerAccountCategory(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlannerAccountCategory(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(plannerAccountCategoryFormDestroy(formState));
    }
  }
}

const PlannerAccountCategoryFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerAccountCategoryForm);

export default PlannerAccountCategoryFormContainer;