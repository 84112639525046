import {connect} from 'react-redux';

import {downloadPlannerToDosSuggestedCSV} from '../../../../../state/modules/plannerToDosSuggested/actions';
import PlannerToDosSuggestedCSVButton from '../components/PlannerToDosSuggestedCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.plannerToDosSuggested.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPlannerToDosSuggestedCSV(order, filter, cb));
    }
  }
}

const PlannerToDosSuggestedCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerToDosSuggestedCSVButton);

export default PlannerToDosSuggestedCSVButtonContainer;