import { connect } from 'react-redux';

import { downloadPlannerResourcesCSV } from '../../../../../state/modules/plannerResources/actions';
import PlannerResourcesCSVButton from '../components/PlannerResourcesCSVButton';

const mapStateToProps = (state) => {
  return {
    isDownloading: state.plannerResources.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPlannerResourcesCSV(order, filter, cb));
    },
  };
};

const PlannerResourcesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerResourcesCSVButton);

export default PlannerResourcesCSVButtonContainer;
