import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerAccountsScreen from './containers/PlannerAccountsScreenContainer';
import PlannerAccountViewScreen from './containers/PlannerAccountViewScreenContainer';

const register = () => {
  Logger.log('debug', `plannerAccounts.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerAccounts', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerAccountsScreen': ['PrivateRoute', '/planner-accounts/:page(\\d+)?', true, PlannerAccountsScreen, getI18n().t('planner_account_route_list')],
        'PlannerAccountViewScreen': ['PrivateRoute', '/planner-accounts/view/:id([\\w\\-]+)', true, PlannerAccountViewScreen, getI18n().t('planner_account_route_view')],
      }
    );
  }
}

export default register;

Logger.log('silly', `plannerAccounts.register() loaded.`);