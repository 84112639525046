import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const RESOURCE_CATEGORY_ENTRY_LIST_REQUEST = 'RESOURCE_CATEGORY_ENTRY_LIST_REQUEST';
export const RESOURCE_CATEGORY_ENTRY_LIST_SUCCESS = 'RESOURCE_CATEGORY_ENTRY_LIST_SUCCESS';
export const RESOURCE_CATEGORY_ENTRY_LIST_FAILURE = 'RESOURCE_CATEGORY_ENTRY_LIST_FAILURE';
export const RESOURCE_CATEGORY_LIST_REQUEST = 'RESOURCE_CATEGORY_LIST_REQUEST';
export const RESOURCE_CATEGORY_LIST_SUCCESS = 'RESOURCE_CATEGORY_LIST_SUCCESS';
export const RESOURCE_CATEGORY_LIST_FAILURE = 'RESOURCE_CATEGORY_LIST_FAILURE';
export const RESOURCE_CATEGORY_READ_REQUEST = 'RESOURCE_CATEGORY_READ_REQUEST';
export const RESOURCE_CATEGORY_READ_SUCCESS = 'RESOURCE_CATEGORY_READ_SUCCESS';
export const RESOURCE_CATEGORY_READ_FAILURE = 'RESOURCE_CATEGORY_READ_FAILURE';
export const RESOURCE_CATEGORY_UPDATE_REQUEST = 'RESOURCE_CATEGORY_UPDATE_REQUEST';
export const RESOURCE_CATEGORY_UPDATE_SUCCESS = 'RESOURCE_CATEGORY_UPDATE_SUCCESS';
export const RESOURCE_CATEGORY_UPDATE_FAILURE = 'RESOURCE_CATEGORY_UPDATE_FAILURE';
export const RESOURCE_CATEGORY_CREATE_REQUEST = 'RESOURCE_CATEGORY_CREATE_REQUEST';
export const RESOURCE_CATEGORY_CREATE_SUCCESS = 'RESOURCE_CATEGORY_CREATE_SUCCESS';
export const RESOURCE_CATEGORY_CREATE_FAILURE = 'RESOURCE_CATEGORY_CREATE_FAILURE';
export const RESOURCE_CATEGORY_DELETE_REQUEST = 'RESOURCE_CATEGORY_DELETE_REQUEST';
export const RESOURCE_CATEGORY_DELETE_SUCCESS = 'RESOURCE_CATEGORY_DELETE_SUCCESS';
export const RESOURCE_CATEGORY_DELETE_FAILURE = 'RESOURCE_CATEGORY_DELETE_FAILURE';
export const RESOURCE_CATEGORY_FORM_DESTROY = 'RESOURCE_CATEGORY_FORM_DESTROY';
export const RESOURCE_CATEGORY_CSV_DOWNLOAD_REQUEST = 'RESOURCE_CATEGORY_CSV_DOWNLOAD_REQUEST';
export const RESOURCE_CATEGORY_CSV_DOWNLOAD_SUCCESS = 'RESOURCE_CATEGORY_CSV_DOWNLOAD_SUCCESS';
export const RESOURCE_CATEGORY_CSV_DOWNLOAD_FAILURE = 'RESOURCE_CATEGORY_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function resourceCategoryListRequest(page, limit, order, filter) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: RESOURCE_CATEGORY_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function resourceCategoryListSuccess(data) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryListSuccess(%j)`, data);
  return {
    type: RESOURCE_CATEGORY_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function resourceCategoryListFailure(error) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryListFailure(%j)`, error);
  return {
    type: RESOURCE_CATEGORY_LIST_FAILURE,
    error: error
  }
}

export function resourceCategoryReadRequest(id) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryReadRequest(${id})`);
  return {
    type: RESOURCE_CATEGORY_READ_REQUEST,
    id: id
  }
}

export function resourceCategoryReadSuccess(data) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryReadSuccess(%j)`, data);
  return {
    type: RESOURCE_CATEGORY_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function resourceCategoryReadFailure(error) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryReadFailure(%j)`, error);
  return {
    type: RESOURCE_CATEGORY_READ_FAILURE,
    error: error
  }
}

export function resourceCategoryUpdateRequest(id, data) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryUpdateRequest(${id}, %j)`, data);
  return {
    type: RESOURCE_CATEGORY_UPDATE_REQUEST,
  }
}

export function resourceCategoryUpdateSuccess(data) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryUpdateSuccess(%j)`, data);
  return {
    type: RESOURCE_CATEGORY_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function resourceCategoryUpdateFailure(error) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryUpdateFailure(%j)`, error);
  return {
    type: RESOURCE_CATEGORY_UPDATE_FAILURE,
    error: error
  }
}

export function resourceCategoryCreateRequest(data) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryCreateRequest(%j)`, data);
  return {
    type: RESOURCE_CATEGORY_CREATE_REQUEST,
  }
}

export function resourceCategoryCreateSuccess(data) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryCreateSuccess(%j)`, data);
  return {
    type: RESOURCE_CATEGORY_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function resourceCategoryCreateFailure(error) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryCreateFailure(%j)`, error);
  return {
    type: RESOURCE_CATEGORY_CREATE_FAILURE,
    error: error
  }
}

export function resourceCategoryDeleteRequest(id) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryDeleteRequest(${id})`);
  return {
    type: RESOURCE_CATEGORY_DELETE_REQUEST,
    id: id
  }
}

export function resourceCategoryDeleteSuccess(id) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryDeleteSuccess(${id})`);
  return {
    type: RESOURCE_CATEGORY_DELETE_SUCCESS,
    id: id,
  }
}

export function resourceCategoryDeleteFailure(error) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryDeleteFailure(%j)`, error);
  return {
    type: RESOURCE_CATEGORY_DELETE_FAILURE,
    error: error
  }
}

export function resourceCategoryFormDestroy(formState=null) {
  Logger.log('debug', `[resourceCategories.actions] resourceCategoryFormDestroy(%j)`, formState);
  return {
    type: RESOURCE_CATEGORY_FORM_DESTROY,
    form: formState
  }
}

export function resourceCategoryCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.resourceCategories.actions] resourceCategoryCSVDownloadRequest()`);
  return {
    type: RESOURCE_CATEGORY_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function resourceCategoryCSVDownloadSuccess() {
  Logger.log('debug', `[state.resourceCategories.actions] resourceCategoryCSVDownloadSuccess()`);
  return {
    type: RESOURCE_CATEGORY_CSV_DOWNLOAD_SUCCESS,
  }
}

export function resourceCategoryCSVDownloadFailure() {
  Logger.log('debug', `[state.resourceCategories.actions] resourceCategoryCSVDownloadFailure()`);
  return {
    type: RESOURCE_CATEGORY_CSV_DOWNLOAD_FAILURE,
  }
}


export function resourceCategoryEntryListRequest(order, filter) {
  Logger.log('debug', `[state.resourceCategories.actions] resourceCategoryEntryListRequest(${order}, %j)`, filter);
  return {
    type: RESOURCE_CATEGORY_ENTRY_LIST_REQUEST,
    order: order,
    filter: filter
  }
}

export function resourceCategoryEntryListSuccess(data) {
  Logger.log('debug', `[state.resourceCategories.actions] resourceCategoryEntryListSuccess(%j)`, data);
  return {
    type: RESOURCE_CATEGORY_ENTRY_LIST_SUCCESS,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function resourceCategoryEntryListFailure(error) {
  Logger.log('debug', `[state.resourceCategories.actions] resourceCategoryEntryListFailure(%j)`, error);
  return {
    type: RESOURCE_CATEGORY_ENTRY_LIST_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadResourceCategories(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[resourceCategories.actions] loadResourceCategories(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(resourceCategoryListRequest(page, limit, order, filter));

    // call API
    const response = await api.getResourceCategories(page, limit, order, filter);
    let success = false;

    // get resource category list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API Resource Category list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'resource_categories']), [schema.resourceCategory]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceCategoryListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API resource category success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(resourceCategoryListSuccess(data));
      success = true;

    // get resource category list failure
    } else {
      Logger.log('info', `Get API resource category list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(resourceCategoryListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadResourceCategoriesEntry(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.resourceCategories.actions] loadResourceCategoriesEntry(${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(resourceCategoryEntryListRequest(order, filter));

    // call API
    const response = await api.getResourceCategories(page=1, limit=10, order, filter);
    let success = false;

    // get resoruce category list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API resource category list success. Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'resource_categories']), [schema.resourceCategoryEntry]);
      const data = {
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(resourceCategoryEntryListSuccess(data));
      success = true;

    } else if (204 === response.get('status')) {

      Logger.log('info', `Get API resoruce category success [empty]. Order: ${order}.`);
      const data = {
        order: order,
        total: 0,
        result: []
      };
      dispatch(resourceCategoryEntryListSuccess(data));
      success = true;
      
    // get resoruce category list failure
    } else {
      Logger.log('info', `Get API resoruce category list failure. Order: ${order}.`);
      dispatch(resourceCategoryEntryListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadResourceCategory(id, cb=function(){}) {
  Logger.log('debug', `[resourceCategories.actions] loadResourceCategory(${id}, ###)`);

  return async function(dispatch) {
    dispatch(resourceCategoryReadRequest(id));

    // call API
    const response = await api.getResourceCategory(id);
    let success = false;

    // get resource category success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API resource category success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'resource_category'])], [schema.resourceCategory]);
      const data = {
        id: response.getIn(['data', 'resource_category', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceCategoryReadSuccess(data));
      success = true;

    // get resource category failure
    } else {
      Logger.log('info', `Get API resource category failure. ID: ${id}.`);
      dispatch(resourceCategoryReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateResourceCategory(id, data, cb=function(){}) {
  Logger.log('debug', `[resourceCategories.actions] updateResourceCategory(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(resourceCategoryUpdateRequest(id, data));

    // call API
    const response = await api.putResourceCategory(id, data);
    let success = false;

    // put resource category success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API resource category success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'resource_category'])], [schema.resourceCategory]);
      const data = {
        id: response.getIn(['data', 'resource_category', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceCategoryUpdateSuccess(data));
      success = true;

    // get resource category failure
    } else {
      Logger.log('info', `PUT API resource category failure. ID: ${id}.`);
      dispatch(resourceCategoryUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createResourceCategory(data, cb=function(){}) {
  Logger.log('debug', `[resourceCategories.actions] createResourceCategory(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(resourceCategoryCreateRequest(data));

    // call API
    const response = await api.postResourceCategory(data);
    let success = false;

    // post resource category success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API resource category success. Post: ${response.getIn(['data', 'resourceCategory', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'resource_category'])], [schema.resourceCategory]);
      const data = {
        id: response.getIn(['data', 'resource_category', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(resourceCategoryCreateSuccess(data));
      success = true;

    // get resource category failure
    } else {
      Logger.log('info', `POST API resource category failure.`);
      dispatch(resourceCategoryCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteResourceCategory(id, cb=function(){}) {
  Logger.log('debug', `[resourceCategories.actions] deleteResourceCategory(${id}, ###)`);

  return async function(dispatch) {
    dispatch(resourceCategoryDeleteRequest(id));

    // call API
    const response = await api.deleteResourceCategory(id);
    let success = false;

    // delete resource category success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API resource category success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'resource_category', id: id}));
      dispatch(resourceCategoryDeleteSuccess(id));
      success = true;

    // get resource category failure
    } else {
      Logger.log('info', `DELETE API resource category failure. ID: ${id}.`);
      dispatch(resourceCategoryDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadResourceCategoriesCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.resourceCategories.actions] downloadResourceCategoriesCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(resourceCategoryCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getResourceCategoriesCSV(order, filter);

    // get resource category CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API resource category CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-resource-categories-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(resourceCategoryCSVDownloadSuccess());

    // get resource category CSV failure
    } else {
      Logger.log('info', `Get API resource category CSV failure.`);
      dispatch(resourceCategoryCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `resourceCategories.actions loaded.`);
