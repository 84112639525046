import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { Translation, getI18n } from 'react-i18next';
import { Affix, Card, Col, Form, Input, Row, Spin, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';
import imageURL from '../../../../../lib/imageUrl';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

// upload criteria
const fileTypes = {
  "image/png": "png",
  "image/jpeg": "jpg",
};
const fileSizeLimit = 1024 * 1024 * 6;
const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;

const beforeUpload = (file) => {

  const isSupportedFileType = Object.keys(fileTypes).includes(file.type);
  if (!isSupportedFileType) {
    message.error(
      getI18n().t("planner_bank_form_feedback_image_file_types")
    );
  }

  const isUnderFileSizeLimit = file.size < fileSizeLimit;
  if (!isUnderFileSizeLimit) {
    message.error(
      getI18n().t("planner_bank_form_feedback_image_file_size_limit")
    );
  }

  return isSupportedFileType && isUnderFileSizeLimit
    ? true
    : Upload.LIST_IGNORE;
};

const errorMessage = () => {
  message.error(
    getI18n().t("planner_banks_form_feedback_image_upload_error")
  );
};


const PlannerBankForm = ({ id, data, errors, load, destroyForm, isLoading, isSubmitting, created_id, ...props }) => {

  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();
  const [mediaId, setMediaId] = useState(null);
  const [imageUrl, setImageUrl] = useState();

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
      });
    }
  };


  const uploadButton = (
    <Translation>{(t) =>
      <div>
        {props.isSubmitting ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>{t('action_upload')}</div>
      </div>
    }</Translation>
  );

  const customRequest = (e) => {

    props.getUploadUrl(
      e.file.name.split(".").pop(),
      "banks-logos",
      async (success, upload_url, file_id) => {
        if (success) {
          // upload image
          props.upload(upload_url, e.file, async (uploadSuccess) => {
 
            const directory = 'banks-logos/';
            file_id = file_id.replace(directory, '')
            if (uploadSuccess) {
              const matches = reFileKey.exec(file_id);
              const updateData = {
                file_directory: directory.slice(0,-1),
                file_name: matches[2],
                file_extension: matches[3],
              };

              // process image
                props.createImage(
                  updateData,
                  (createSuccess, imageId) => {
                    if (createSuccess) {
                      setMediaId(imageId);
                      e.onSuccess(null, e.file);
                    } else {
                      errorMessage();
                    }
                  }
                ); 

            } else {
              errorMessage();
            } 
          }); 
        } else {
          errorMessage();
        }
      }
    );
  };

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  }

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  useEffect(() => {
    if (id && !isSubmitting) {
      form.setFieldsValue(data);
      if (data['logo'] !== null && mediaId === null) {
        setMediaId(data['logo']['id'])
        if (data['logo']['file_bucket'] && data['logo']['file_directory']
          && data['logo']['file_extension'] && data['logo']['file_name']) {
          setImageUrl(imageURL(data['logo']['file_directory'], data['logo']['file_name'], data['logo']['file_extension'], 60, 60, 'ratio'))
        }
      }
    }
    
  }, [form, data, isSubmitting, id]); // eslint-disable-line react-hooks/exhaustive-deps

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('PlannerBankEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    }
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `PlannerBankForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {

        if (['logo'].includes(input)) {
         //pass
        } else {
          payload[input] = values[input];
        }
      }
    }

    if (mediaId) {
      payload["logo_id"] = mediaId;
    }

    if (id) { // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else { // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `PlannerBankForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `PlannerBankForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>{(t) =>
      <>
        <div className="planner-bank-form">
          <Form
            name="planner_bank_form"
            form={form}
            initialValues={data}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            validateTrigger="onSubmit"
            {...layout.main}
          >
            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={id ? t('planner_bank_edit_title') : t('planner_bank_add_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

                  <div className="form-group">
                    <Form.Item
                      name="name"
                      label={t('planner_bank_name')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                        { type: 'string', min: 2, max: 100, message: t('feedback_validation_char_range', { min: 2, max: 100 }) },
                      ]}
                    >
                      <Input autoFocus disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="site_url"
                      label={t('planner_bank_site_url')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                        { type: 'string', min: 2, message: t('feedback_validation_char_range', { min: 2 }) },
                      ]}
                    >
                      <Input autoFocus disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="logo"
                      label={t('planner_bank_logo')}
                    >
                      <Row>

                        <Col>
                          <Upload
                            name="logo"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={customRequest}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                  width: '100%',
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </Col>

                      </Row>

                    </Form.Item>
                  </div>


                </Card>
              </Col>

              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    <FormMetadata
                      id={id}
                      isSubmitting={isSubmitting}
                      delete={props.delete.bind(this)}
                      deleteRedirectTo="PlannerBanksScreen"
                      createdAt={props.createdAt}
                      updatedAt={props.updatedAt}
                    />
                  </Card>
                </Affix>
              </Col>

            </Row>
          </Form>
        </div>
      </>
    }</Translation>
  );
}

export default PlannerBankForm;

Logger.log('silly', `PlannerBankForm loaded.`);