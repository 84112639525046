import {connect} from 'react-redux';

import PlannerAccountsScreen from '../components/PlannerAccountsScreen';

const mapStateToProps = state => {
  return {
    total: state.plannerAccounts.get('total'),
    isLoading: state.plannerAccounts.get('isLoading')
  }
}

const PlannerAccountsScreenContainer = connect(
  mapStateToProps
)(PlannerAccountsScreen);

export default PlannerAccountsScreenContainer;