import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannerDocument} from '../../../../../state/modules/plannerDocuments/actions';
import PlannerDocumentViewScreen from '../components/PlannerDocumentViewScreen';

const mapStateToProps = (state, ownProps) => {

  const plannerDocument = ownProps.match.params.id
    ? denormalize(
        state.entities.getIn(['plannerDocuments', ownProps.match.params.id]),
        schema.plannerDocument,
        state.entities.toJS()
      )
    : null;;

  return {
    isLoading: state.plannerDocuments.get('isLoading'),
    plannerDocument: plannerDocument,
    id: ownProps.match.params.id,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerDocument(id, cb));
    },
  }
}

const PlannerDocumentViewScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerDocumentViewScreen);

export default PlannerDocumentViewScreenContainer;
