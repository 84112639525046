import React, {useEffect} from 'react';
import {Translation} from 'react-i18next';
import {Form, Select} from 'antd';

const CategoryInput = ({name, label, options, isLoading, isSubmitting, load, order, multiple=false, rules=[], ...props}) => {

  // load members on mount
  useEffect(() => {
    load(1, 10, 'created_at.desc', {pagination: false});
  }, [load,]);

  return (
    <Translation>{(t) => 
      <div className="form-group">
        <Form.Item
          name={name}
          label={label ? label : t('planner_account_planner_bank')}
          rules={rules}
        >
          <Select
            mode={multiple ? "multiple" : null}
            showSearch
            optionFilterProp="children"
            disabled={isLoading || isSubmitting}
            loading={isLoading}
          >
            {options.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default CategoryInput;
