import { connect } from 'react-redux';

import PlannerResourcesScreen from '../components/PlannerResourcesScreen';

const mapStateToProps = (state) => {
  return {
    total: state.plannerResources.get('total'),
    isLoading: state.plannerResources.get('isLoading'),
  };
};

const PlannerResourcesScreenContainer = connect(mapStateToProps)(
  PlannerResourcesScreen
);

export default PlannerResourcesScreenContainer;
