import {connect} from 'react-redux';

import PlannerNoteAddScreen from '../components/PlannerNoteAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerNotes.get('isLoading'),
    success: state.plannerNotes.getIn(['form', 'success']),
  }
}

const PlannerNoteAddScreenContainer = connect(
  mapStateToProps
)(PlannerNoteAddScreen);

export default PlannerNoteAddScreenContainer;
