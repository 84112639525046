import {connect} from 'react-redux';

import PlannerBankAddScreen from '../components/PlannerBankAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerBanks.get('isLoading'),
    success: state.plannerBanks.getIn(['form', 'success']),
  }
}

const PlannerBankAddScreenContainer = connect(
  mapStateToProps
)(PlannerBankAddScreen);

export default PlannerBankAddScreenContainer;