import React from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';

const StateInput = ({ name, label, onChange, disabled = false, ...props }) => {

  const options = [
    {
      value: 1,
      label: 'planner_to_do_user_state_1',
    },
    {
      value: 2,
      label: 'planner_to_do_user_state_2',
    },
    {
      value: 3,
      label: 'planner_to_do_user_state_3',
    },
  ];

  return (
    <Translation>{(t) =>
      <div className="form-group">
        <Form.Item
          name={name}
          label={label}
          rules={[
            { required: true, message: t('feedback_validation_required') },
          ]}
          {...props}
        >
          <Select disabled={disabled} onChange={onChange}>
          {options.map(x => <Select.Option key={x.value} value={x.value}>{t(x.label)}</Select.Option>)}
          </Select>
        </Form.Item>
      </div>
    }</Translation>
  );
}

export default StateInput;
