import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerNotesScreen from './containers/PlannerNotesScreenContainer';
import PlannerNoteAddScreen from './containers/PlannerNoteAddScreenContainer';
import PlannerNoteEditScreen from './containers/PlannerNoteEditScreenContainer';
import PlannerNoteViewScreen from './containers/PlannerNoteViewScreenContainer';

const register = () => {
  Logger.log('debug', `plannerNotes.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerNotes', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerNoteViewScreen': ['PrivateRoute', '/planner-notes/view/:id([\\w\\-]+)', true, PlannerNoteViewScreen, getI18n().t('planner_notes_route_view')],
        'PlannerNoteAddScreen': ['PrivateRoute', '/planner-notes/add', true, PlannerNoteAddScreen, getI18n().t('planner_note_route_add')],
        'PlannerNoteEditScreen': ['PrivateRoute', '/planner-notes/edit/:id([\\w\\-]+)', true, PlannerNoteEditScreen, getI18n().t('planner_note_route_edit')],
        'PlannerNotesScreen': ['PrivateRoute', '/planner-notes/:page(\\d+)?', true, PlannerNotesScreen, getI18n().t('planner_note_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `plannerNotes.register() loaded.`);