import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerAccountCategoriesScreen from './containers/PlannerAccountCategoriesScreenContainer';
import PlannerAccountCategoryAddScreen from './containers/PlannerAccountCategoryAddScreenContainer';
import PlannerAccountCategoryEditScreen from './containers/PlannerAccountCategoryEditScreenContainer';

const register = () => {
  Logger.log('debug', `plannerAccountCategories.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerAccountCategories', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerAccountCategoryAddScreen': ['PrivateRoute', '/planner-account-categories/add', true, PlannerAccountCategoryAddScreen, getI18n().t('planner_account_category_route_add')],
        'PlannerAccountCategoryEditScreen': ['PrivateRoute', '/planner-account-categories/edit/:id([\\w\\-]+)', true, PlannerAccountCategoryEditScreen, getI18n().t('planner_account_category_route_edit')],
        'PlannerAccountCategoriesScreen': ['PrivateRoute', '/planner-account-categories/:page(\\d+)?', true, PlannerAccountCategoriesScreen, getI18n().t('planner_account_categories_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `plannerAccountCategories.register() loaded.`);