import {connect} from 'react-redux';

import ResourceCategoryAddScreen from '../components/ResourceCategoryAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.resourceCategories.get('isLoading'),
    success: state.resourceCategories.getIn(['form', 'success']),
  }
}

const ResourceCategoryAddScreenContainer = connect(
  mapStateToProps
)(ResourceCategoryAddScreen);

export default ResourceCategoryAddScreenContainer;