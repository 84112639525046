import {connect} from 'react-redux';

import PlannerNotesScreen from '../components/PlannerNotesScreen';

const mapStateToProps = state => {
  return {
    total: state.plannerNotes.get('total'),
    isLoading: state.plannerNotes.get('isLoading')
  }
}

const PlannerNotesScreenContainer = connect(
  mapStateToProps
)(PlannerNotesScreen);

export default PlannerNotesScreenContainer;