import {connect} from 'react-redux';

import PlannerToDoUserAddScreen from '../components/PlannerToDoUserAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerToDosUser.get('isLoading'),
    success: state.plannerToDosUser.getIn(['form', 'success']),
  }
}

const PlannerToDoUserAddScreenContainer = connect(
  mapStateToProps
)(PlannerToDoUserAddScreen);

export default PlannerToDoUserAddScreenContainer;
