import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { Button, Table, Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import ListActions from '../../../elements/components/ListActions';
import { StatusTag } from '../../../elements/components/Tags';
import {
  getColumnSearchProps,
  getColumnDateRangeProps,
} from '../../../elements/components/TableColumnFilters';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

const PlannerDocumentsList = ({
  component,
  page,
  limit,
  order,
  filter,
  total,
  load,
  remove,
  history,
  ...props
}) => {
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    delete filter[dataIndex];
    history.push(
      QueryString.append(props.location.pathname, { order, ...filter })
    );
  };

  const columns = [
    {
      title: getI18n().t('planner_owner_username'),
      dataIndex: 'owner_username',
      key: 'owner_username',
      ...getColumnSearchProps(
        'owner_username',
        handleSearch,
        handleReset,
        getI18n().t('planner_owner_username'),
        filter
      ),
    },
    {
      title: getI18n().t('planner_documents_file_name'),
      dataIndex: 'file_name',
      key: 'file_name',
      ...getColumnSearchProps(
        'file_name',
        handleSearch,
        handleReset,
        getI18n().t('planner_documents_file_name'),
        filter
      ),
    },
    {
      title: getI18n().t('planner_documents_category_document'),
      dataIndex: 'category_document',
      key: 'category_document',
      filters: [
        {
          text: getI18n().t('planner_documents_category_document_1'),
          value: 1,
        },
        {
          text: getI18n().t('planner_documents_category_document_2'),
          value: 2,
        },
        {
          text: getI18n().t('planner_documents_category_document_3'),
          value: 3,
        },
        {
          text: getI18n().t('planner_documents_category_document_4'),
          value: 4,
        },
        {
          text: getI18n().t('planner_documents_category_document_5'),
          value: 5,
        },
      ],
      filteredValue:
        'category_document' in filter
          ? filter['category_document'].split(',')
          : null,
      render: (val) => (
        <Tag>{getI18n().t('planner_documents_category_document_' + val)}</Tag>
      ),
    },
    {
      title: getI18n().t('planner_documents_document_type'),
      dataIndex: 'document_type',
      key: 'document_type',
      filters: [
        { text: getI18n().t('planner_documents_document_type_1'), value: 1 },
        { text: getI18n().t('planner_documents_document_type_2'), value: 2 },
      ],
      filteredValue:
        'document_type' in filter ? filter['document_type'].split(',') : null,
      render: (val) => (
        <Tag>{getI18n().t('planner_documents_document_type_' + val)}</Tag>
      ),
    },
    {
      title: getI18n().t('planner_documents_section'),
      dataIndex: 'section',
      key: 'section',
      filters: [
        { text: getI18n().t('planner_documents_section_1'), value: 1 },
        { text: getI18n().t('planner_documents_section_2'), value: 2 },
        { text: getI18n().t('planner_documents_section_3'), value: 3 },
        { text: getI18n().t('planner_documents_section_4'), value: 4 },
        { text: getI18n().t('planner_documents_section_5'), value: 5 },
        { text: getI18n().t('planner_documents_section_6'), value: 6 },
        { text: getI18n().t('planner_documents_section_7'), value: 7 },
      ],
      filteredValue: 'section' in filter ? filter['section'].split(',') : null,
      render: (val) => (
        <Tag>{getI18n().t('planner_documents_section_' + val)}</Tag>
      ),
    },
    {
      title: getI18n().t('table_header_status'),
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: getI18n().t('status_enabled'), value: 1 },
        { text: getI18n().t('status_disabled'), value: 2 },
        { text: getI18n().t('status_archived'), value: 3 },
        { text: getI18n().t('status_deleted'), value: 4 },
        { text: getI18n().t('status_pending'), value: 5 },
      ],
      filteredValue: 'status' in filter ? filter['status'].split(',') : null,
      render: (code) => <StatusTag status={code} />,
    },
    {
      title: getI18n().t('planner_documents_uploaded_at'),
      dataIndex: 'uploaded_at',
      key: 'uploaded_at',
      responsive: ['xxl'],
      render: (date) => Format.date(date),
      defaultSortOrder:
        order === 'uploaded_at.asc'
          ? 'ascend'
          : order === 'uploaded_at.desc'
          ? 'descend'
          : null,
      sorter: true,
      ...getColumnDateRangeProps(
        'uploaded_at',
        handleSearch,
        handleReset,
        filter
      ),
    },
    {
      title: getI18n().t('table_header_updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (date) => Format.date(date),
      defaultSortOrder:
        order === 'updated_at.asc'
          ? 'ascend'
          : order === 'updated_at.desc'
          ? 'descend'
          : null,
      sorter: true,
      ...getColumnDateRangeProps(
        'updated_at',
        handleSearch,
        handleReset,
        filter
      ),
    },
    {
      title: getI18n().t('table_header_actions'),
      key: 'actions',
      render: (item) =>
        [3, 4].includes(item.status) ? (
          <>
            <Button
              icon={<EyeOutlined />}
              onClick={(e) =>
                history.push(
                  pathTo('PlannerDocumentViewScreen', { id: item.id })
                )
              }
            />
          </>
        ) : (
          <ListActions
            id={item.id}
            page={page}
            limit={limit}
            order={order}
            filter={filter}
            load={load.bind(this)}
            remove={remove.bind(this)}
            editScreen="PlannerDocumentEditScreen"
          />
        ),
    },
  ];

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
    position: ['bottomCenter'],
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, { page: pagination['current'] });
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const order =
          sorter['field'] +
          '.' +
          (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = order;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    history.push(QueryString.append(path, params));
  };

  const filterString = JSON.stringify(filter);
  useEffect(() => {
    load(page, limit, order, JSON.parse(filterString));
  }, [page, limit, order, filterString, load]);

  return (
    <Translation>
      {(t) => (
        <Table
          dataSource={props.list}
          columns={columns}
          pagination={page === 1 && total < limit ? false : pagination}
          loading={props.isLoading}
          scroll={{ x: true }}
          onChange={handleTableChange}
        />
      )}
    </Translation>
  );
};

export default withRouter(PlannerDocumentsList);

Logger.log('silly', `PlannerDocumentsList loaded.`);
