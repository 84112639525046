import {connect} from 'react-redux';

import {plannerNotesCSV} from '../../../../../state/modules/plannerNotes/actions';
import PlannerNotesCSVButton from '../components/PlannerNotesCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.plannerNotes.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(plannerNotesCSV(order, filter, cb));
    }
  }
}

const PlannerNotesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerNotesCSVButton);

export default PlannerNotesCSVButtonContainer;