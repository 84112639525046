import React from 'react';
import { Translation } from 'react-i18next';
import { Tag } from 'antd';

const BooleanTag = ({ value, ...props }) => {
  return (
    <Translation>
      {(t) => (
        <Tag color={value ? 'green' : 'red'}>
          {value ? t('boolean_true') : t('boolean_false')}
        </Tag>
      )}
    </Translation>
  );
};

const RoleTag = ({ role, ...props }) => {
  const labels = {
    1: 'registration_code_role_owner',
    2: 'registration_code_role_admin',
    3: 'registration_code_role_client',
    4: 'registration_code_role_default',
  };

  const colors = {
    1: 'success',
    2: 'processing',
    3: 'warning',
    4: 'default',
  };

  return (
    <Translation>
      {(t) => <Tag color={colors[role]}>{t(labels[role])}</Tag>}
    </Translation>
  );
};

const StatusTag = ({ status, ...props }) => {
  const labels = {
    1: 'status_enabled',
    2: 'status_disabled',
    3: 'status_archived',
    4: 'status_deleted',
    5: 'status_pending',
  };

  const colors = {
    1: 'success',
    2: 'default',
    3: 'warning',
    4: 'error',
    5: 'processing',
  };

  return (
    <Translation>{(t) =>
      <Tag color={colors[status]}>{t(labels[status])}</Tag>
    }</Translation>
  )
}


const PriorityTagCall = ({priority, ...props}) => {

  const labels = {
    0: "call_priority_now",
    1: "call_priority_high",
    2: "call_priority_medium",
    3: "call_priority_low",
  };

  const colors = {
    0: "error",
    1: "warning",
    2: "processing",
    3: "success",
  };

  return (
    <Translation>{(t) =>
      <Tag color={colors[priority]}>{t(labels[priority])}</Tag>
    }</Translation>
  )
}

const PriorityTagEmail = ({ priority, ...props }) => {
  const labels = {
    0: 'email_priority_now',
    1: 'email_priority_high',
    2: 'email_priority_medium',
    3: 'email_priority_low',
  };

  const colors = {
    0: 'error',
    1: 'warning',
    2: 'processing',
    3: 'success',
  };

  return (
    <Translation>{(t) =>
      <Tag color={colors[priority]}>{t(labels[priority])}</Tag>
    }</Translation>
  )
}

const CallStatusTag = ({call_status, ...props}) => {

  const labels = {
    1: "call_call_status_queued",
    2: "call_call_status_processing",
    3: "call_call_status_complete",
    4: "call_call_status_failed",
  }
    const colors = {
      1: "default",
      2: "processing",
      3: "success",
      4: "error",
    };
  
    return (
      <Translation>{(t) =>
        <Tag color={colors[call_status]}>{t(labels[call_status])}</Tag>
      }</Translation>
    )
  }
  
const SendStatusTag = ({ send_status, ...props }) => {
  const labels = {
    1: 'email_send_status_queued',
    2: 'email_send_status_processing',
    3: 'email_send_status_complete',
    4: 'email_send_status_failed',
  };

  const colors = {
    1: 'default',
    2: 'processing',
    3: 'success',
    4: 'error',
  };

  return (
    <Translation>
      {(t) => <Tag color={colors[send_status]}>{t(labels[send_status])}</Tag>}
    </Translation>
  );
};


const StateTag = ({state, ...props}) => {

  const labels = {
    1: "planner_to_do_user_state_1",
    2: "planner_to_do_user_state_2",
    3: "planner_to_do_user_state_3",
  };

  const colors = {
    1: "processing",
    2: "warning",
    3: "success",
    4: "error",

  };

  return (
    <Translation>{(t) =>
      <Tag color={colors[state]}>{t(labels[state])}</Tag>
    }</Translation>
  )
}

const ModuleStateTag = ({state, ...props}) => {

  const labels = {
    1: "planner_module_state_1",
    2: "planner_module_state_2",
    3: "planner_module_state_3",
  };

  const colors = {
    1: "default",
    2: "processing",
    3: "success",
  };

  return (
    <Translation>{(t) =>
      <Tag color={colors[state]}>{t(labels[state])}</Tag>
    }</Translation>
  )
}

const ModuleTag = ({state, ...props}) => {

  const labels = {
    1: "planner_module_1",
    2: "planner_module_2",
    3: "planner_module_3",
    4: "planner_module_4",
  };

  const colors = {
    1: "default",
    2: "default",
    3: "default",
    4: "default",
  };

  return (
    <Translation>{(t) =>
      <Tag color={colors[state]}>{t(labels[state])}</Tag>
    }</Translation>
  )
}


const Section1ValueAnswerTag = ({value, ...props}) => {

  const labels = {
    1: "planner_to_do_section_1_value_answer_1",
    2: "planner_to_do_section_1_value_answer_2",
    3: "planner_to_do_section_1_value_answer_3",
  };

  return (
    <Translation>{(t) =>
      <Tag color="default">{t(labels[value])}</Tag>
    }</Translation>
  )
}

const ValueAnswerTag = ({value, ...props}) => {

  const labels = {
    1: "planner_to_do_value_answer_1",
    2: "planner_to_do_value_answer_2",
    3: "planner_to_do_value_answer_3",
  };

  return (
    <Translation>{(t) =>
      <Tag color="default">{t(labels[value])}</Tag>
    }</Translation>
  )
}


const ImportTaskStatusTag = ({ status, ...props }) => {
  const labels = {
    1: 'import_task_status_queued',
    2: 'import_task_status_processing',
    3: 'import_task_status_complete',
    4: 'import_task_status_failed',
  };

  const colors = {
    1: 'default',
    2: 'processing',
    3: 'success',
    4: 'error',
  };

  return (
    <Translation>
      {(t) => <Tag color={colors[status]}>{t(labels[status])}</Tag>}
    </Translation>
  );
};

const ColorTag = ({ color, ...props }) => {
  return <Translation>{(t) => <Tag color={color}>{color}</Tag>}</Translation>;
};


const AlertTypeTag = ({ alert_type, ...props }) => {

  const labels = {
    1: 'alerts_alert_type_1',
    2: 'alerts_alert_type_2',
    3: 'alerts_alert_type_3',
    4: 'alerts_alert_type_4',
    5: 'alerts_alert_type_5',
    6: 'alerts_alert_type_6',
    7: 'alerts_alert_type_7',
    8: 'alerts_alert_type_8',
    9: 'alerts_alert_type_9',
    10: 'alerts_alert_type_10',
    11: 'alerts_alert_type_11',
    12: 'alerts_alert_type_12',
    13: 'alerts_alert_type_13',
  };

  return (
    <Translation>
      {(t) => <Tag color={'default'}>{t(labels[alert_type])}</Tag>}
    </Translation>
  );
};



export {
  BooleanTag, 
  StatusTag, 
  RoleTag, 
  ColorTag, 
  StateTag, 
  ModuleStateTag, 
  ValueAnswerTag, 
  Section1ValueAnswerTag, 
  ModuleTag, 
  PriorityTagEmail,
  CallStatusTag,
  PriorityTagCall,
  SendStatusTag,
  AlertTypeTag,
  ImportTaskStatusTag,
  };


