import {connect} from 'react-redux';

import PlannerBankEditScreen from '../components/PlannerBankEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerBanks.get('isLoading'),
    success: state.plannerBanks.getIn(['form', 'success']),
  }
}

const PlannerBankEditScreenContainer = connect(
  mapStateToProps
)(PlannerBankEditScreen);

export default PlannerBankEditScreenContainer;