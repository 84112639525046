import {connect} from 'react-redux';

import {downloadPlannerDocumentsCSV} from '../../../../../state/modules/plannerDocuments/actions';
import PlannerDocumentsCSVButton from '../components/PlannerDocumentsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.plannerDocuments.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPlannerDocumentsCSV(order, filter, cb));
    }
  }
}

const PlannerDocumentsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerDocumentsCSVButton);

export default PlannerDocumentsCSVButtonContainer;
