import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { Translation, getI18n } from 'react-i18next';
import { Affix, Card, Col, DatePicker, Form, Input, Row, Spin, Space, Button } from 'antd';
import moment from 'moment';
import PriorityInput from "./PriorityInput";
import SendStatusInput from "./SendStatusInput";
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';


const RecipientsInputRow = ({ recipient, remove, index, ...props }) => {
  return (
    <Translation>
      {(t) => (
        <div className="recipient-input-row">

          <Space style={{ display: "flex" }} align="start">
            <div className="form-group">
              <Form.Item
                {...recipient}
                label={t('calls_recipient')}
                rules={[
                  { required: true, message: t('feedback_validation_required') }
                  ,
                ]}
              >
                <Input
                  autoFocus
                />
              </Form.Item>
            </div>
            <MinusCircleOutlined
              onClick={() => {
                remove(index);
              }}
            />
          </Space>
        </div>

      )}
    </Translation>
  );
};

const CallForm = ({ id, data, errors, load, destroyForm, isLoading, isSubmitting, ...props }) => {

  // eslint-disable-next-line no-unused-vars
  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();

  const apiDatetimeFormat = Config.get('API_DATETIME_FORMAT');

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  }

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      dataObj['last_attempt_at'] = dataObj['last_attempt_at']
        ? moment(dataObj['last_attempt_at'], apiDatetimeFormat)
        : null;

      dataObj['queued_at'] = dataObj['queued_at']
        ? moment(dataObj['queued_at'], apiDatetimeFormat)
        : moment();
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id, apiDatetimeFormat]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `CallForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {

        // transform entity refs to IDs
       if (['queued_at', 'last_attempt_at'].includes(input)) {
          payload[input] = Format.date(values[input], apiDatetimeFormat);
        }
        else {
          payload[input] = values[input];
        }
      }
    }

    if (id) { // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `CallForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `CallForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }


  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>{(t) =>
      <>
        <div className="call-form">
          <Form
            name="calls_form"
            form={form}
            initialValues={data}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            validateTrigger="onSubmit"
            {...layout.main}
          >
            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={id ? t('calls_edit_title') : t('calls_add_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

              <div className="form-group">
                    <Form.Item
                      name="total_count"
                      label={t('calls_total_count')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <Input
                        autoFocus
                        disabled={true}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="total_sent_count"
                      label={t('calls_total_sent_count')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <Input
                        autoFocus
                        disabled={true}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="total_delivered_count"
                      label={t('calls_total_delivered_count')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <Input
                        autoFocus
                        disabled={true}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="total_delivery_failed_count"
                      label={t('calls_total_delivery_failed_count')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <Input
                        autoFocus
                        disabled={true}
                      />
                    </Form.Item>
                  </div>
                 
                  <div className="form-group">
                    <Form.Item
                      name="attempts"
                      label={t('calls_attempts')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <Input
                        autoFocus
                        disabled={true}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <Form.Item
                      name="service_id"
                      label={t('calls_service_id')}
                      rules={[]}
                    >
                      <Input
                        autoFocus
                        disabled={isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>

                  <PriorityInput
                    name="priority"
                    label={t('calls_priority')}
                    rules={null}
                    disabled={isLoading || isSubmitting}
                  />

                  <SendStatusInput
                    name="call_status"
                    label={t('calls_call_status')}
                    rules={null}
                    disabled={isLoading || isSubmitting}
                  />

                  <div className="form-group">
                    <Form.Item
                      name="last_attempt_at"
                      label={t('calls_last_attempt_at')}
                      rules={[]}
                    >
                      <DatePicker
                        autoFocus
                        showTime
                        disabled={isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="queued_at"
                      label={t('calls_queued_at')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <DatePicker
                        autoFocus
                        showTime
                        disabled={isLoading || isSubmitting}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="body"
                      label={t('calls_body')}
                      rules={[
                        {type: 'string', min: 0, max: 250, message: t('feedback_validation_char_range', {min: 0, max: 250})},
                      ]}
                    >
                      <Input.TextArea disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                </Card>

                <Card
                  title={t("call_recipients_title")}
                  extra={
                    isLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 20 }} spin />
                        }
                      />
                    ) : null
                  }
                >
                  <Form.List name="recipients">
                    {(recipients, { add, remove, move }) => {
                      return (
                        <>
                          <div className="scroll-recipients">
                            {recipients.map((recipient, i) => (
                              <RecipientsInputRow recipient={recipient} index={i} remove={remove} />
                            ))}
                          </div>

                          <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                              block
                            >
                              <PlusOutlined /> {t("calls_add_recipient")}
                            </Button>
                          </Form.Item>
                        </>
                      );
                    }}
                  </Form.List>
                </Card>

              </Col>

              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    <FormMetadata
                      id={id}
                      isSubmitting={isSubmitting}
                      delete={props.delete.bind(this)}
                      deleteRedirectTo="CallsScreen"
                      createdAt={props.createdAt}
                      showStatus={false}
                      updatedAt={props.updatedAt}
                    />
                  </Card>
                </Affix>
              </Col>

            </Row>
          </Form>
        </div>
      </>
    }</Translation>
  );
}

export default CallForm;

Logger.log('silly', `CallForm loaded.`);
