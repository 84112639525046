import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerToDosUserScreen from './containers/PlannerToDosUserScreenContainer';
import PlannerToDoUserAddScreen from './containers/PlannerToDoUserAddScreenContainer';
import PlannerToDoUserEditScreen from './containers/PlannerToDoUserEditScreenContainer';

const register = () => {
  Logger.log('debug', `plannerToDosUser.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerToDosUser', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerToDoUserAddScreen': ['PrivateRoute', '/planner-to-dos-user/add', true, PlannerToDoUserAddScreen, getI18n().t('planner_to_do_user_route_add')],
        'PlannerToDoUserEditScreen': ['PrivateRoute', '/planner-to-dos-user/edit/:id([\\w\\-]+)', true, PlannerToDoUserEditScreen, getI18n().t('planner_to_do_user_route_edit')],
        'PlannerToDosUserScreen': ['PrivateRoute', '/planner-to-dos-user/:page(\\d+)?', true, PlannerToDosUserScreen, getI18n().t('planner_to_dos_user_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `plannerToDosUser.register() loaded.`);
