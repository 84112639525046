import {connect} from 'react-redux';

import {downloadPlannerAccountCategoriesCSV} from '../../../../../state/modules/plannerAccountCategories/actions';
import PlannerAccountCategoriesCSVButton from '../components/PlannerAccountCategoriesCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.plannerAccountCategories.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPlannerAccountCategoriesCSV(order, filter, cb));
    }
  }
}

const PlannerAccountCategoriesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerAccountCategoriesCSVButton);

export default PlannerAccountCategoriesCSVButtonContainer;