import React, { Component } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Affix, Card, Col, Input, Row, Spin, Popconfirm, Button } from 'antd';
import { LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import { pathTo } from '../../../Routes';
import { Redirect } from 'react-router-dom';

import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import message from '../../../elements/lib/MessageWrapper';

const DisplayInput = ({ label, value, textarea = false, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label
          htmlFor="registration_form_application"
          className=""
          title="Application"
        >
          {label}
        </label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        {textarea ? (
          <Input.TextArea value={value} bordered={false} rows={15} />
        ) : (
          <Input value={value} bordered={false} />
        )}
      </div>
    </div>
  </div>
);

class PlannerAccountViewScreen extends Component {
  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
  }

  state = {
    windowWidth: 0,
    redirectTo: null,
  };

  scrollToTop = () => {
    Logger.log('debug', `PlannerAccountViewScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  confirm = (id, deleteRedirectTo) => {
    this.props.delete(id, (suceess) => {
      if (suceess) {
        this.setState({ redirectTo: pathTo(deleteRedirectTo) });
        message.success(getI18n().t('feedback_delete_success'));
      } else {
        message.error(getI18n().t('feedback_delete_error'));
      }
    });
  };

  render() {
    const { planner_account, isLoading, id } = this.props;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    return (
      <Translation>
        {(t) => (
          <div>
            <DocumentHead title={t('planner_account_route_view')} />

            <div className="planner-accounts-view">
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={t('planner_account_view_title')}
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <DisplayInput
                      label={t('planner_account_planner_id')}
                      value={planner_account?.planner?.id ?? null}
                    />
                    <DisplayInput
                      label={t('planner_account_planner_account_category')}
                      value={
                        planner_account?.planner_account_category?.category ??
                        null
                      }
                    />
                    <DisplayInput
                      label={t('planner_account_planner_bank')}
                      value={planner_account?.planner_bank?.name ?? null}
                    />
                    <DisplayInput
                      label={t('planner_account_site_url_bank')}
                      value={planner_account?.site_url ?? null}
                    />
                    <DisplayInput
                      label={t('planner_account_username')}
                      value={planner_account ? '********' : null }
                    />
                    <DisplayInput
                      label={t('planner_account_note')}
                      value={planner_account ? '********' : null }
                    />
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      {planner_account && planner_account.created_at ? (
                        <p>
                          {t('form_metadata_created_at')}{' '}
                          <small>
                            {Format.date(
                              planner_account.created_at,
                              Config.get('DEFAULT_DATETIME_FORMAT')
                            )}
                          </small>
                        </p>
                      ) : (
                        ''
                      )}
                      {planner_account &&
                      planner_account.updated_at &&
                      planner_account.updated_at !==
                        planner_account.created_at ? (
                        <p>
                          {t('form_metadata_updated_ad')}{' '}
                          <small>
                            {Format.date(
                              planner_account.updated_at,
                              Config.get('DEFAULT_DATETIME_FORMAT')
                            )}
                          </small>
                        </p>
                      ) : (
                        ''
                      )}

                      {id ? (
                        <Popconfirm
                          placement="topRight"
                          title={getI18n().t('delete_confirm_body')}
                          onConfirm={(e) =>
                            this.confirm(id, 'PlannerAccountsScreen')
                          }
                          okText={getI18n().t('confirm_yes')}
                          cancelText={getI18n().t('confirm_cancel')}
                        >
                          <Button
                            danger
                            type="primary"
                            icon={<DeleteOutlined />}
                          >
                            {this.state.windowWidth < 992 ||
                            this.state.windowWidth >= 1300
                              ? t('action_delete')
                              : null}
                          </Button>
                        </Popconfirm>
                      ) : null}
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
    this.props.load(this.props.id);
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }
}

export default PlannerAccountViewScreen;

Logger.log('silly', `PlannerAccountViewScreen loaded.`);
