import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { Translation, getI18n } from 'react-i18next';
import { Affix, Card, Col, Form, Input, Row, Spin, Checkbox, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';
import BodyInput from './BodyInput';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';


const PlannerNoteForm = ({ id, data, errors, load, destroyForm, isLoading, isSubmitting, created_id, ...props }) => {

  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  }

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      dataObj['pinned_at'] = dataObj['pinned_at']
        ? moment(dataObj['pinned_at'], Config.get('API_DATETIME_FORMAT'))
        : null;
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('PlannerNoteEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    }
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `PlannerNoteForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {

        if (["planner"].includes(input)) {
          if (values[input]) {
            payload[input + '_id'] = values[input];
          }
        } 
        else if (["is_pinned"].includes(input)) {
          payload[input] = values[input] ? true : false;
        }
        else if (['pinned_at'].includes(input)) {
          payload[input] = Format.date(values[input], Config.get('API_DATETIME_FORMAT'));
        } else {
          payload[input] = values[input];
        }
      }
    }
    if (id) { // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else { // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `PlannerNoteForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `PlannerNoteForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>{(t) =>
      <>
        <div className="planner-note-form">
          <Form
            name="planner_note_form"
            form={form}
            initialValues={data}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            validateTrigger="onSubmit"
            {...layout.main}
          >
            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={id ? t('planner_note_edit_title') : t('planner_note_add_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

                  <div className="form-group">
                    <Form.Item
                      name="planner"
                      label={t('planner_note_planner_id')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                      ]}
                    >
                      <Input autoFocus disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="title"
                      label={t('planner_note_title')}
                      rules={[
                        { required: true, message: t('feedback_validation_required') },
                        { type: 'string', min: 2, max: 255, message: t('feedback_validation_char_range', { min: 2, max: 255 }) },
                      ]}
                    >
                      <Input autoFocus disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <BodyInput
                    name="body"
                    label={t('planner_note_body')}
                    rules={[
                      { required: true, message: t('feedback_validation_required') },
                    ]}
                                        form={form}
                    defaultValue={form.getFieldValue(['body'])}
                    disabled={isLoading || isSubmitting}
                  />


                  <div className="form-group">
                    <Form.Item
                      name="is_pinned"
                      label={t('planner_note_is_pinned')}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={isLoading || isSubmitting}>{t('planner_note_is_pinned_hint')}</Checkbox>
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="pinned_at"
                      label={t("planner_note_pinned_at")}
                    >
                      <DatePicker
                        showTime
                        disabled={isLoading || isSubmitting}
                        format={Config.get("DEFAULT_DATETIME_FORMAT")}
                      />
                    </Form.Item>
                  </div>

                </Card>
              </Col>

              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    <FormMetadata
                      id={id}
                      isSubmitting={isSubmitting}
                      delete={props.delete.bind(this)}
                      deleteRedirectTo="PlannerNotesScreen"
                      createdAt={props.createdAt}
                      updatedAt={props.updatedAt}
                    />
                  </Card>
                </Affix>
              </Col>

            </Row>
          </Form>
        </div>
      </>
    }</Translation>
  );
}

export default PlannerNoteForm;

Logger.log('silly', `PlannerNoteForm loaded.`);