import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannerAnswer} from '../../../../../state/modules/plannerAnswers/actions';
import PlannerAnswerViewScreen from '../components/PlannerAnswerViewScreen';

const mapStateToProps = (state, ownProps) => {
  
  const planner = ownProps.match.params.id
    ? denormalize(
        state.entities.getIn(['plannerAnswers', ownProps.match.params.id]),
        schema.plannerAnswer,
        state.entities.toJS()
      )
    : null;;

    return {
    isLoading: state.plannerAnswers.get('isLoading'),
    planner: planner,
    id: ownProps.match.params.id,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerAnswer(id, cb));
    },
  }
}

const PlannerAnswerViewScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerAnswerViewScreen);

export default PlannerAnswerViewScreenContainer;
