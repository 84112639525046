import { connect } from 'react-redux';

import PlannerResourceAddScreen from '../components/PlannerResourceAddScreen';

const mapStateToProps = (state) => {
  return {
    isLoading: state.plannerResources.get('isLoading'),
    success: state.plannerResources.getIn(['form', 'success']),
  };
};

const PlannerResourceAddScreenContainer = connect(mapStateToProps)(
  PlannerResourceAddScreen
);

export default PlannerResourceAddScreenContainer;
