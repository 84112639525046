import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { getI18n } from 'react-i18next';
import { schema } from '../../../../../state/schema';
import {
  loadPlannerResources,
  deletePlannerResource,
} from '../../../../../state/modules/plannerResources/actions';
import { sendMessage } from '../../../../../state/actions';
import PlannerResourcesList from '../components/PlannerResourcesList';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.plannerResources.getIn(['pages', order, limit, page]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['plannerResources', x]),
                schema.resource,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects to make table easier to work with
            return {
              owner_username:
                x?.planner?.owner?.profile?.user?.username ??
                (x.planners.length > 0
                  ? getI18n().t('planner_resources_is_multiple_planner')
                  : getI18n().t('planner_resources_empty')),
              ...x,
            };
          })
      : [],
    isLoading: state.plannerResources.get('isLoading'),
    total: state.plannerResources.get('total'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlannerResources(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePlannerResource(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  };
};

const PlannerResourcesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerResourcesList);

export default PlannerResourcesListContainer;
