import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadCalls, deleteCall} from '../../../../../state/modules/calls/actions';
import CallsList from '../components/CallsList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.calls.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['calls', x]),
              schema.call,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    isLoading: state.calls.get('isLoading'),
    total: state.calls.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadCalls(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteCall(id, cb));
    }
  }
}

const CallsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallsList);

export default CallsListContainer;
