import {connect} from 'react-redux';

import PlannerAccountCategoryEditScreen from '../components/PlannerAccountCategoryEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerAccountCategories.get('isLoading'),
    success: state.plannerAccountCategories.getIn(['form', 'success']),
  }
}

const PlannerAccountCategoryEditScreenContainer = connect(
  mapStateToProps
)(PlannerAccountCategoryEditScreen);

export default PlannerAccountCategoryEditScreenContainer;
