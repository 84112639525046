import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLANNER_ACCOUNT_LIST_REQUEST = 'PLANNER_ACCOUNT_LIST_REQUEST';
export const PLANNER_ACCOUNT_LIST_SUCCESS = 'PLANNER_ACCOUNT_LIST_SUCCESS';
export const PLANNER_ACCOUNT_LIST_FAILURE = 'PLANNER_ACCOUNT_LIST_FAILURE';
export const PLANNER_ACCOUNT_READ_REQUEST = 'PLANNER_ACCOUNT_READ_REQUEST';
export const PLANNER_ACCOUNT_READ_SUCCESS = 'PLANNER_ACCOUNT_READ_SUCCESS';
export const PLANNER_ACCOUNT_READ_FAILURE = 'PLANNER_ACCOUNT_READ_FAILURE';
export const PLANNER_ACCOUNT_UPDATE_REQUEST = 'PLANNER_ACCOUNT_UPDATE_REQUEST';
export const PLANNER_ACCOUNT_UPDATE_SUCCESS = 'PLANNER_ACCOUNT_UPDATE_SUCCESS';
export const PLANNER_ACCOUNT_UPDATE_FAILURE = 'PLANNER_ACCOUNT_UPDATE_FAILURE';
export const PLANNER_ACCOUNT_CREATE_REQUEST = 'PLANNER_ACCOUNT_CREATE_REQUEST';
export const PLANNER_ACCOUNT_CREATE_SUCCESS = 'PLANNER_ACCOUNT_CREATE_SUCCESS';
export const PLANNER_ACCOUNT_CREATE_FAILURE = 'PLANNER_ACCOUNT_CREATE_FAILURE';
export const PLANNER_ACCOUNT_DELETE_REQUEST = 'PLANNER_ACCOUNT_DELETE_REQUEST';
export const PLANNER_ACCOUNT_DELETE_SUCCESS = 'PLANNER_ACCOUNT_DELETE_SUCCESS';
export const PLANNER_ACCOUNT_DELETE_FAILURE = 'PLANNER_ACCOUNT_DELETE_FAILURE';
export const PLANNER_ACCOUNT_FORM_DESTROY = 'PLANNER_ACCOUNT_FORM_DESTROY';

// ACTION CREATORS

export function plannerAccountListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: PLANNER_ACCOUNT_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function plannerAccountListSuccess(data) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountListSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_ACCOUNT_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function plannerAccountListFailure(error) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountListFailure(%j)`,
    error
  );
  return {
    type: PLANNER_ACCOUNT_LIST_FAILURE,
    error: error,
  };
}

export function plannerAccountReadRequest(id) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountReadRequest(${id})`
  );
  return {
    type: PLANNER_ACCOUNT_READ_REQUEST,
    id: id,
  };
}

export function plannerAccountReadSuccess(data) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountReadSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_ACCOUNT_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function plannerAccountReadFailure(error) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountReadFailure(%j)`,
    error
  );
  return {
    type: PLANNER_ACCOUNT_READ_FAILURE,
    error: error,
  };
}

export function plannerAccountUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: PLANNER_ACCOUNT_UPDATE_REQUEST,
  };
}

export function plannerAccountUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountUpdateSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_ACCOUNT_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function plannerAccountUpdateFailure(error) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountUpdateFailure(%j)`,
    error
  );
  return {
    type: PLANNER_ACCOUNT_UPDATE_FAILURE,
    error: error,
  };
}

export function plannerAccountCreateRequest(data) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountCreateRequest(%j)`,
    data
  );
  return {
    type: PLANNER_ACCOUNT_CREATE_REQUEST,
  };
}

export function plannerAccountCreateSuccess(data) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountCreateSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_ACCOUNT_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function plannerAccountCreateFailure(error) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountCreateFailure(%j)`,
    error
  );
  return {
    type: PLANNER_ACCOUNT_CREATE_FAILURE,
    error: error,
  };
}

export function plannerAccountDeleteRequest(id) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountDeleteRequest(${id})`
  );
  return {
    type: PLANNER_ACCOUNT_DELETE_REQUEST,
    id: id,
  };
}

export function plannerAccountDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountDeleteSuccess(${id})`
  );
  return {
    type: PLANNER_ACCOUNT_DELETE_SUCCESS,
    id: id,
  };
}

export function plannerAccountDeleteFailure(error) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountDeleteFailure(%j)`,
    error
  );
  return {
    type: PLANNER_ACCOUNT_DELETE_FAILURE,
    error: error,
  };
}

export function plannerAccountFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] plannerAccountFormDestroy(%j)`,
    formState
  );
  return {
    type: PLANNER_ACCOUNT_FORM_DESTROY,
    form: formState,
  };
}

// API THUNK ACTION CREATORS

export function loadPlannerAccounts(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] loadPlannerAccounts(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(plannerAccountListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlannerAccounts(page, limit, order, filter);
    let success = false;

    // get planner account list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API planner account list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'planner_accounts']),
        [schema.plannerAccount]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerAccountListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API planner account success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(plannerAccountListSuccess(data));
      success = true;

      // get planner account list failure
    } else {
      Logger.log(
        'info',
        `Get API planner account list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(plannerAccountListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadPlannerAccount(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] loadPlannerAccount(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(plannerAccountReadRequest(id));

    // call API
    const response = await api.getPlannerAccount(id);
    let success = false;

    // get planner account success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API planner account success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'planner_account'])],
        [schema.plannerAccount]
      );
      const data = {
        id: response.getIn(['data', 'planner_account', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerAccountReadSuccess(data));
      success = true;

      // get planner account failure
    } else {
      Logger.log('info', `Get API planner account failure. ID: ${id}.`);
      dispatch(plannerAccountReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updatePlannerAccount(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] updatePlannerAccount(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(plannerAccountUpdateRequest(id, data));

    // call API
    const response = await api.putPlannerAccount(id, data);
    let success = false;

    // put planner account success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API planner account success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'planner_account'])],
        [schema.plannerAccount]
      );
      const data = {
        id: response.getIn(['data', 'planner_account', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerAccountUpdateSuccess(data));
      success = true;

      // get planner account failure
    } else {
      Logger.log('info', `PUT API planner account failure. ID: ${id}.`);
      dispatch(plannerAccountUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createPlannerAccount(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] createPlannerAccount(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(plannerAccountCreateRequest(data));

    // call API
    const response = await api.postPlannerAccounts(data);
    let success = false;

    // post planner account success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API planner account success. Post: ${response.getIn([
          'data',
          'planner_account',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'planner_account'])],
        [schema.plannerAccount]
      );
      const data = {
        id: response.getIn(['data', 'planner_account', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerAccountCreateSuccess(data));
      success = true;

      // get planner account failure
    } else {
      Logger.log('info', `POST API planner account failure.`);
      dispatch(plannerAccountCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deletePlannerAccount(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerAccounts.actions] deletePlannerAccount(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(plannerAccountDeleteRequest(id));

    // call API
    const response = await api.deletePlannerAccount(id);
    let success = false;

    // delete planner account success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API planner account success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'planner_accounts', id: id }));
      dispatch(plannerAccountDeleteSuccess(id));
      success = true;

      // get planner account failure
    } else {
      Logger.log('info', `DELETE API planner account failure. ID: ${id}.`);
      dispatch(plannerAccountDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `plannerAccounts.actions loaded.`);
