import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadPlannerToDosUser, deletePlannerToDoUser } from '../../../../../state/modules/plannerToDosUser/actions';
import { sendMessage } from '../../../../../state/actions';
import PlannerToDosUserList from '../components/PlannerToDosUserList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.plannerToDosUser.getIn(['pages', order, limit, page]);
  const list = result
    ? result.map(x => {
      return {
        key: x,
        ...denormalize(
          state.entities.getIn(['plannerToDosUser', x]),
          schema.plannerToDoUser,
          state.entities.toJS()
        )
      };
    }).toArray().map(x => {

      // flatten some nested objects to make table easier to work with
      return {
        planner_id: x.planner ? x.planner.id : '',
        owner_username: x.planner?.owner?.profile?.user?.username || '',
        ...x
      };
    })
    : [];

    return {
    list: list,
    isLoading: state.plannerToDosUser.get('isLoading'),
    total: state.plannerToDosUser.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlannerToDosUser(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePlannerToDoUser(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PlannerToDosUserListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerToDosUserList);

export default PlannerToDosUserListContainer;
