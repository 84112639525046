import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES
export const PLANNER_ENTRY_LIST_REQUEST = 'PLANNER_ENTRY_LIST_REQUEST';
export const PLANNER_ENTRY_LIST_SUCCESS = 'PLANNER_ENTRY_LIST_SUCCESS';
export const PLANNER_ENTRY_LIST_FAILURE = 'PLANNER_ENTRY_LIST_FAILURE';
export const PLANNER_LIST_REQUEST = 'PLANNER_LIST_REQUEST';
export const PLANNER_LIST_SUCCESS = 'PLANNER_LIST_SUCCESS';
export const PLANNER_LIST_FAILURE = 'PLANNER_LIST_FAILURE';
export const PLANNER_READ_REQUEST = 'PLANNER_READ_REQUEST';
export const PLANNER_READ_SUCCESS = 'PLANNER_READ_SUCCESS';
export const PLANNER_READ_FAILURE = 'PLANNER_READ_FAILURE';
export const PLANNER_UPDATE_REQUEST = 'PLANNER_UPDATE_REQUEST';
export const PLANNER_UPDATE_SUCCESS = 'PLANNER_UPDATE_SUCCESS';
export const PLANNER_UPDATE_FAILURE = 'PLANNER_UPDATE_FAILURE';
export const PLANNER_CREATE_REQUEST = 'PLANNER_CREATE_REQUEST';
export const PLANNER_CREATE_SUCCESS = 'PLANNER_CREATE_SUCCESS';
export const PLANNER_CREATE_FAILURE = 'PLANNER_CREATE_FAILURE';
export const PLANNER_DELETE_REQUEST = 'PLANNER_DELETE_REQUEST';
export const PLANNER_DELETE_SUCCESS = 'PLANNER_DELETE_SUCCESS';
export const PLANNER_DELETE_FAILURE = 'PLANNER_DELETE_FAILURE';
export const PLANNER_FORM_DESTROY = 'PLANNER_FORM_DESTROY';
export const PLANNER_CSV_DOWNLOAD_REQUEST = 'PLANNER_CSV_DOWNLOAD_REQUEST';
export const PLANNER_CSV_DOWNLOAD_SUCCESS = 'PLANNER_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_CSV_DOWNLOAD_FAILURE = 'PLANNER_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerListRequest(page, limit, order, filter) {
  Logger.log('debug', `[planners.actions] plannerListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: PLANNER_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function plannerListSuccess(data) {
  Logger.log('debug', `[planners.actions] plannerListSuccess(%j)`, data);
  return {
    type: PLANNER_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerListFailure(error) {
  Logger.log('debug', `[planners.actions] plannerListFailure(%j)`, error);
  return {
    type: PLANNER_LIST_FAILURE,
    error: error
  }
}

export function plannerReadRequest(id) {
  Logger.log('debug', `[planners.actions] plannerReadRequest(${id})`);
  return {
    type: PLANNER_READ_REQUEST,
    id: id
  }
}

export function plannerReadSuccess(data) {
  Logger.log('debug', `[planners.actions] plannerReadSuccess(%j)`, data);
  return {
    type: PLANNER_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerReadFailure(error) {
  Logger.log('debug', `[planners.actions] plannerReadFailure(%j)`, error);
  return {
    type: PLANNER_READ_FAILURE,
    error: error
  }
}

export function plannerUpdateRequest(id, data) {
  Logger.log('debug', `[planners.actions] plannerUpdateRequest(${id}, %j)`, data);
  return {
    type: PLANNER_UPDATE_REQUEST,
  }
}

export function plannerUpdateSuccess(data) {
  Logger.log('debug', `[planners.actions] plannerUpdateSuccess(%j)`, data);
  return {
    type: PLANNER_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerUpdateFailure(error) {
  Logger.log('debug', `[planners.actions] plannerUpdateFailure(%j)`, error);
  return {
    type: PLANNER_UPDATE_FAILURE,
    error: error
  }
}

export function plannerCreateRequest(data) {
  Logger.log('debug', `[planners.actions] plannerCreateRequest(%j)`, data);
  return {
    type: PLANNER_CREATE_REQUEST,
  }
}

export function plannerCreateSuccess(data) {
  Logger.log('debug', `[planners.actions] plannerCreateSuccess(%j)`, data);
  return {
    type: PLANNER_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerCreateFailure(error) {
  Logger.log('debug', `[planners.actions] plannerCreateFailure(%j)`, error);
  return {
    type: PLANNER_CREATE_FAILURE,
    error: error
  }
}

export function plannerDeleteRequest(id) {
  Logger.log('debug', `[planners.actions] plannerDeleteRequest(${id})`);
  return {
    type: PLANNER_DELETE_REQUEST,
    id: id
  }
}

export function plannerDeleteSuccess(id) {
  Logger.log('debug', `[planners.actions] plannerDeleteSuccess(${id})`);
  return {
    type: PLANNER_DELETE_SUCCESS,
    id: id,
  }
}

export function plannerDeleteFailure(error) {
  Logger.log('debug', `[planners.actions] plannerDeleteFailure(%j)`, error);
  return {
    type: PLANNER_DELETE_FAILURE,
    error: error
  }
}

export function plannerFormDestroy(formState=null) {
  Logger.log('debug', `[planners.actions] plannerFormDestroy(%j)`, formState);
  return {
    type: PLANNER_FORM_DESTROY,
    form: formState
  }
}


export function plannerCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.planners.actions] plannerCSVDownloadRequest()`);
  return {
    type: PLANNER_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function plannerCSVDownloadSuccess() {
  Logger.log('debug', `[state.planners.actions] plannerCSVDownloadSuccess()`);
  return {
    type: PLANNER_CSV_DOWNLOAD_SUCCESS,
  }
}

export function plannerCSVDownloadFailure() {
  Logger.log('debug', `[state.planners.actions] plannerCSVDownloadFailure()`);
  return {
    type: PLANNER_CSV_DOWNLOAD_FAILURE,
  }
}

export function plannerEntryListRequest(order, filter) {
  Logger.log('debug', `[state.planners.actions] plannerEntryListRequest(${order}, %j)`, filter);
  return {
    type: PLANNER_ENTRY_LIST_REQUEST,
    order: order,
    filter: filter
  }
}

export function plannerEntryListSuccess(data) {
  Logger.log('debug', `[state.planners.actions] plannerEntryListSuccess(%j)`, data);
  return {
    type: PLANNER_ENTRY_LIST_SUCCESS,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerEntryListFailure(error) {
  Logger.log('debug', `[state.planners.actions] plannerEntryListFailure(%j)`, error);
  return {
    type: PLANNER_ENTRY_LIST_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadPlanners(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[planners.actions] loadPlanners(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlanners(page, limit, order, filter);
    let success = false;

    // get planners list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planners list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planners']), [schema.planner]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API planners success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerListSuccess(data));
      success = true;
      
    // get planners list failure
    } else {
      Logger.log('info', `Get API planners list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(plannerListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadPlannersEntry(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.planners.actions] loadPlannersEntry(${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerEntryListRequest(order, filter));

    // call API
    const response = await api.getPlanners(page=1, limit=10, order, filter);
    let success = false;

    // get planner list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner list success. Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planners']), [schema.plannerEntry]);
      const data = {
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(plannerEntryListSuccess(data));
      success = true;

    } else if (204 === response.get('status')) {

      Logger.log('info', `Get API planner success [empty]. Order: ${order}.`);
      const data = {
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerEntryListSuccess(data));
      success = true;
      
    // get planner list failure
    } else {
      Logger.log('info', `Get API planner list failure. Order: ${order}.`);
      dispatch(plannerEntryListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadPlanner(id, cb=function(){}) {
  Logger.log('debug', `[planners.actions] loadPlanner(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerReadRequest(id));

    // call API
    const response = await api.getPlanner(id);
    let success = false;

    // get planner success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner'])], [schema.planner]);
      const data = {
        id: response.getIn(['data', 'planner', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerReadSuccess(data));
      success = true;
      
    // get planner failure
    } else {
      Logger.log('info', `Get API planner failure. ID: ${id}.`);
      dispatch(plannerReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updatePlanner(id, data, cb=function(){}) {
  Logger.log('debug', `[planners.actions] updatePlanner(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerUpdateRequest(id, data));

    // call API
    const response = await api.putPlanner(id, data);
    let success = false;

    // put planner success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API planner success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner'])], [schema.planner]);
      const data = {
        id: response.getIn(['data', 'planner', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerUpdateSuccess(data));
      success = true;
      
    // get planner failure
    } else {
      Logger.log('info', `PUT API planner failure. ID: ${id}.`);
      dispatch(plannerUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createPlanner(data, cb=function(){}) {
  Logger.log('debug', `[planners.actions] createPlanner(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerCreateRequest(data));

    // call API
    const response = await api.postPlanners(data);
    let success = false;

    // post planners success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API planners success. Post: ${response.getIn(['data', 'planner', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner'])], [schema.planner]);
      const data = {
        id: response.getIn(['data', 'planner', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerCreateSuccess(data));
      success = true;
      
    // get planners failure
    } else {
      Logger.log('info', `POST API planners failure.`);
      dispatch(plannerCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deletePlanners(id, cb=function(){}) {
  Logger.log('debug', `[planners.actions] deletePlanners(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerDeleteRequest(id));

    // call API
    const response = await api.deletePlanner(id);
    let success = false;

    // delete planner success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API planner success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'planners', id: id}));
      dispatch(plannerDeleteSuccess(id));
      success = true;
      
    // get planner failure
    } else {
      Logger.log('info', `DELETE API planner failure. ID: ${id}.`);
      dispatch(plannerDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadPlannersCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.planners.actions] downloadPlannersCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(plannerCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlannersCSV(order, filter);

    // get planners CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planners CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-planners-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(plannerCSVDownloadSuccess());
      
    // get planners CSV failure
    } else {
      Logger.log('info', `Get API planners CSV failure.`);
      dispatch(plannerCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `planners.actions loaded.`);
