import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLANNER_MODULE_LIST_REQUEST = 'PLANNER_MODULE_LIST_REQUEST';
export const PLANNER_MODULE_LIST_SUCCESS = 'PLANNER_MODULE_LIST_SUCCESS';
export const PLANNER_MODULE_LIST_FAILURE = 'PLANNER_MODULE_LIST_FAILURE';
export const PLANNER_MODULE_READ_REQUEST = 'PLANNER_MODULE_READ_REQUEST';
export const PLANNER_MODULE_READ_SUCCESS = 'PLANNER_MODULE_READ_SUCCESS';
export const PLANNER_MODULE_READ_FAILURE = 'PLANNER_MODULE_READ_FAILURE';
export const PLANNER_MODULE_UPDATE_REQUEST = 'PLANNER_MODULE_UPDATE_REQUEST';
export const PLANNER_MODULE_UPDATE_SUCCESS = 'PLANNER_MODULE_UPDATE_SUCCESS';
export const PLANNER_MODULE_UPDATE_FAILURE = 'PLANNER_MODULE_UPDATE_FAILURE';
export const PLANNER_MODULE_CREATE_REQUEST = 'PLANNER_MODULE_CREATE_REQUEST';
export const PLANNER_MODULE_CREATE_SUCCESS = 'PLANNER_MODULE_CREATE_SUCCESS';
export const PLANNER_MODULE_CREATE_FAILURE = 'PLANNER_MODULE_CREATE_FAILURE';
export const PLANNER_MODULE_DELETE_REQUEST = 'PLANNER_MODULE_DELETE_REQUEST';
export const PLANNER_MODULE_DELETE_SUCCESS = 'PLANNER_MODULE_DELETE_SUCCESS';
export const PLANNER_MODULE_DELETE_FAILURE = 'PLANNER_MODULE_DELETE_FAILURE';
export const PLANNER_MODULE_FORM_DESTROY = 'PLANNER_MODULE_FORM_DESTROY';
export const PLANNER_MODULE_CSV_DOWNLOAD_REQUEST =
  'PLANNER_MODULE_CSV_DOWNLOAD_REQUEST';
export const PLANNER_MODULE_CSV_DOWNLOAD_SUCCESS =
  'PLANNER_MODULE_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_MODULE_CSV_DOWNLOAD_FAILURE =
  'PLANNER_MODULE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerModuleListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: PLANNER_MODULE_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function plannerModuleListSuccess(data) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleListSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_MODULE_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function plannerModuleListFailure(error) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleListFailure(%j)`,
    error
  );
  return {
    type: PLANNER_MODULE_LIST_FAILURE,
    error: error,
  };
}

export function plannerModuleReadRequest(id) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleReadRequest(${id})`
  );
  return {
    type: PLANNER_MODULE_READ_REQUEST,
    id: id,
  };
}

export function plannerModuleReadSuccess(data) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleReadSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_MODULE_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function plannerModuleReadFailure(error) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleReadFailure(%j)`,
    error
  );
  return {
    type: PLANNER_MODULE_READ_FAILURE,
    error: error,
  };
}

export function plannerModuleUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: PLANNER_MODULE_UPDATE_REQUEST,
  };
}

export function plannerModuleUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleUpdateSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_MODULE_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function plannerModuleUpdateFailure(error) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleUpdateFailure(%j)`,
    error
  );
  return {
    type: PLANNER_MODULE_UPDATE_FAILURE,
    error: error,
  };
}

export function plannerModuleCreateRequest(data) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleCreateRequest(%j)`,
    data
  );
  return {
    type: PLANNER_MODULE_CREATE_REQUEST,
  };
}

export function plannerModuleCreateSuccess(data) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleCreateSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_MODULE_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function plannerModuleCreateFailure(error) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleCreateFailure(%j)`,
    error
  );
  return {
    type: PLANNER_MODULE_CREATE_FAILURE,
    error: error,
  };
}

export function plannerModuleDeleteRequest(id) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleDeleteRequest(${id})`
  );
  return {
    type: PLANNER_MODULE_DELETE_REQUEST,
    id: id,
  };
}

export function plannerModuleDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleDeleteSuccess(${id})`
  );
  return {
    type: PLANNER_MODULE_DELETE_SUCCESS,
    id: id,
  };
}

export function plannerModuleDeleteFailure(error) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleDeleteFailure(%j)`,
    error
  );
  return {
    type: PLANNER_MODULE_DELETE_FAILURE,
    error: error,
  };
}

export function plannerModuleFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[plannerModules.actions] plannerModuleFormDestroy(%j)`,
    formState
  );
  return {
    type: PLANNER_MODULE_FORM_DESTROY,
    form: formState,
  };
}

export function plannerModuleCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.plannerModules.actions] plannerModuleCSVDownloadRequest()`
  );
  return {
    type: PLANNER_MODULE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function plannerModuleCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.plannerModules.actions] plannerModuleCSVDownloadSuccess()`
  );
  return {
    type: PLANNER_MODULE_CSV_DOWNLOAD_SUCCESS,
  };
}

export function plannerModuleCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.plannerModules.actions] plannerModuleCSVDownloadFailure()`
  );
  return {
    type: PLANNER_MODULE_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadPlannerModules(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[plannerModules.actions] loadPlannerModules(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(plannerModuleListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlannerModules(page, limit, order, filter);
    let success = false;

    // get planner modules list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API planner modules list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'planner_modules']),
        [schema.plannerModule]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerModuleListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API planner modules success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(plannerModuleListSuccess(data));
      success = true;

      // get planner modules list failure
    } else {
      Logger.log(
        'info',
        `Get API planner modules list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(plannerModuleListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadPlannerModule(id, cb = function () {}) {
  Logger.log('debug', `[plannerModules.actions] loadPlannerModule(${id}, ###)`);

  return async function (dispatch) {
    dispatch(plannerModuleReadRequest(id));

    // call API
    const response = await api.getPlannerModule(id);
    let success = false;

    // get planner modules success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API planner modules success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'planner_module'])],
        [schema.plannerModule]
      );
      const data = {
        id: response.getIn(['data', 'planner_module', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerModuleReadSuccess(data));
      success = true;

      // get planner modules failure
    } else {
      Logger.log('info', `Get API planner modules failure. ID: ${id}.`);
      dispatch(plannerModuleReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updatePlannerModule(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerModules.actions] updatePlannerModule(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(plannerModuleUpdateRequest(id, data));

    // call API
    const response = await api.putPlannerModule(id, data);
    let success = false;

    // put planner modules success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API planner modules success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'planner_module'])],
        [schema.plannerModule]
      );
      const data = {
        id: response.getIn(['data', 'planner_module', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerModuleUpdateSuccess(data));
      success = true;

      // get planner modules failure
    } else {
      Logger.log('info', `PUT API planner modules failure. ID: ${id}.`);
      dispatch(plannerModuleUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createPlannerModule(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerModules.actions] createPlannerModule(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(plannerModuleCreateRequest(data));

    // call API
    const response = await api.postPlannerModule(data);
    let success = false;

    // post planner modules success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API planner modules success. Post: ${response.getIn([
          'data',
          'planner',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'planner_module'])],
        [schema.plannerModule]
      );
      const data = {
        id: response.getIn(['data', 'planner_module', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerModuleCreateSuccess(data));
      success = true;

      // get planner modules failure
    } else {
      Logger.log('info', `POST API planner modules failure.`);
      dispatch(plannerModuleCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deletePlannerModule(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerModules.actions] deletePlannerModule(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(plannerModuleDeleteRequest(id));

    // call API
    const response = await api.deletePlannerModule(id);
    let success = false;

    // delete planner modules success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API planner modules success. ID: ${id}.`);

      dispatch(removeEntity({ entityType: 'plannerModules', id: id }));
      dispatch(plannerModuleDeleteSuccess(id));
      success = true;

      // get planner modules failure
    } else {
      Logger.log('info', `DELETE API planner modules failure. ID: ${id}.`);
      dispatch(plannerModuleDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function downloadPlannerModulesCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.plannerModules.actions] downloadPlannerModulesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(plannerModuleCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlannerModulesCSV(order, filter);

    // get planner modules CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API planner modules CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-planner-modules-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(plannerModuleCSVDownloadSuccess());

      // get planner modules CSV failure
    } else {
      Logger.log('info', `Get API planner modules CSV failure.`);
      dispatch(plannerModuleCSVDownloadFailure());
    }

    // callback function
    cb();
  };
}

Logger.log('silly', `plannerModules.actions loaded.`);
