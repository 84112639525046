import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLANNER_RESOURCE_LIST_REQUEST = 'PLANNER_RESOURCE_LIST_REQUEST';
export const PLANNER_RESOURCE_LIST_SUCCESS = 'PLANNER_RESOURCE_LIST_SUCCESS';
export const PLANNER_RESOURCE_LIST_FAILURE = 'PLANNER_RESOURCE_LIST_FAILURE';
export const PLANNER_RESOURCE_READ_REQUEST = 'PLANNER_RESOURCE_READ_REQUEST';
export const PLANNER_RESOURCE_READ_SUCCESS = 'PLANNER_RESOURCE_READ_SUCCESS';
export const PLANNER_RESOURCE_READ_FAILURE = 'PLANNER_RESOURCE_READ_FAILURE';
export const PLANNER_RESOURCE_UPDATE_REQUEST =
  'PLANNER_RESOURCE_UPDATE_REQUEST';
export const PLANNER_RESOURCE_UPDATE_SUCCESS =
  'PLANNER_RESOURCE_UPDATE_SUCCESS';
export const PLANNER_RESOURCE_UPDATE_FAILURE =
  'PLANNER_RESOURCE_UPDATE_FAILURE';
export const PLANNER_RESOURCE_CREATE_REQUEST =
  'PLANNER_RESOURCE_CREATE_REQUEST';
export const PLANNER_RESOURCE_CREATE_SUCCESS =
  'PLANNER_RESOURCE_CREATE_SUCCESS';
export const PLANNER_RESOURCE_CREATE_FAILURE =
  'PLANNER_RESOURCE_CREATE_FAILURE';
export const PLANNER_RESOURCE_DELETE_REQUEST =
  'PLANNER_RESOURCE_DELETE_REQUEST';
export const PLANNER_RESOURCE_DELETE_SUCCESS =
  'PLANNER_RESOURCE_DELETE_SUCCESS';
export const PLANNER_RESOURCE_DELETE_FAILURE =
  'PLANNER_RESOURCE_DELETE_FAILURE';
export const PLANNER_RESOURCE_FORM_DESTROY = 'PLANNER_RESOURCE_FORM_DESTROY';
export const PLANNER_RESOURCE_CSV_DOWNLOAD_REQUEST =
  'PLANNER_RESOURCE_CSV_DOWNLOAD_REQUEST';
export const PLANNER_RESOURCE_CSV_DOWNLOAD_SUCCESS =
  'PLANNER_RESOURCE_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_RESOURCE_CSV_DOWNLOAD_FAILURE =
  'PLANNER_RESOURCE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerResourceListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: PLANNER_RESOURCE_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function plannerResourceListSuccess(data) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceListSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_RESOURCE_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function plannerResourceListFailure(error) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceListFailure(%j)`,
    error
  );
  return {
    type: PLANNER_RESOURCE_LIST_FAILURE,
    error: error,
  };
}

export function plannerResourceReadRequest(id) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceReadRequest(${id})`
  );
  return {
    type: PLANNER_RESOURCE_READ_REQUEST,
    id: id,
  };
}

export function plannerResourceReadSuccess(data) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceReadSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_RESOURCE_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function plannerResourceReadFailure(error) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceReadFailure(%j)`,
    error
  );
  return {
    type: PLANNER_RESOURCE_READ_FAILURE,
    error: error,
  };
}

export function plannerResourceUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: PLANNER_RESOURCE_UPDATE_REQUEST,
  };
}

export function plannerResourceUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceUpdateSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_RESOURCE_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function plannerResourceUpdateFailure(error) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceUpdateFailure(%j)`,
    error
  );
  return {
    type: PLANNER_RESOURCE_UPDATE_FAILURE,
    error: error,
  };
}

export function plannerResourceCreateRequest(data) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceCreateRequest(%j)`,
    data
  );
  return {
    type: PLANNER_RESOURCE_CREATE_REQUEST,
  };
}

export function plannerResourceCreateSuccess(data) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceCreateSuccess(%j)`,
    data
  );
  return {
    type: PLANNER_RESOURCE_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function plannerResourceCreateFailure(error) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceCreateFailure(%j)`,
    error
  );
  return {
    type: PLANNER_RESOURCE_CREATE_FAILURE,
    error: error,
  };
}

export function plannerResourceDeleteRequest(id) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceDeleteRequest(${id})`
  );
  return {
    type: PLANNER_RESOURCE_DELETE_REQUEST,
    id: id,
  };
}

export function plannerResourceDeleteSuccess(id) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceDeleteSuccess(${id})`
  );
  return {
    type: PLANNER_RESOURCE_DELETE_SUCCESS,
    id: id,
  };
}

export function plannerResourceDeleteFailure(error) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceDeleteFailure(%j)`,
    error
  );
  return {
    type: PLANNER_RESOURCE_DELETE_FAILURE,
    error: error,
  };
}

export function plannerResourceFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[plannerResources.actions] plannerResourceFormDestroy(%j)`,
    formState
  );
  return {
    type: PLANNER_RESOURCE_FORM_DESTROY,
    form: formState,
  };
}

export function plannerResourceCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.plannerResources.actions] plannerResourceCSVDownloadRequest()`
  );
  return {
    type: PLANNER_RESOURCE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function plannerResourceCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.plannerResources.actions] plannerResourceCSVDownloadSuccess()`
  );
  return {
    type: PLANNER_RESOURCE_CSV_DOWNLOAD_SUCCESS,
  };
}

export function plannerResourceCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.plannerResources.actions] plannerResourceCSVDownloadFailure()`
  );
  return {
    type: PLANNER_RESOURCE_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function loadPlannerResources(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[plannerResources.actions] loadPlannerResources(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(plannerResourceListRequest(page, limit, order, filter));
    // call API
    const response = await api.getPlannerResources(page, limit, order, filter);
    let success = false;
    let result = [];

    // get planner resources list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API plannerResources list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'planner_resources']),
        [schema.plannerResource]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerResourceListSuccess(data));
      success = true;
      result = normalizedEntities.result;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API planner resources success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(plannerResourceListSuccess(data));
      success = true;

      // get planner resources list failure
    } else {
      Logger.log(
        'info',
        `Get API planner resources list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(plannerResourceListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  };
}

export function loadPlannerResource(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerResources.actions] loadPlannerResource(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(plannerResourceReadRequest(id));

    // call API
    const response = await api.getPlannerResource(id);
    let success = false;

    // get planner resource success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API planner resource success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'planner_resource'])],
        [schema.plannerResource]
      );
      const data = {
        id: response.getIn(['data', 'planner_resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerResourceReadSuccess(data));
      success = true;

      // get planner resource failure
    } else {
      Logger.log('info', `Get API planner resource failure. ID: ${id}.`);
      dispatch(plannerResourceReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updatePlannerResource(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerResources.actions] updatePlannerResource(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(plannerResourceUpdateRequest(id, data));

    // call API
    const response = await api.putPlannerResource(id, data);
    let success = false;

    // put planner resource success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API planner resource success. User: ${id}.`);
      console.log([response.getIn(['data', 'planner_resource'])]);
      const normalizedEntities = normalize(
        [response.getIn(['data', 'planner_resource'])],
        [schema.plannerResource]
      );

      const data = {
        id: response.getIn(['data', 'planner_resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerResourceUpdateSuccess(data));
      success = true;

      // get planner resource failure
    } else {
      Logger.log('info', `PUT API planner resource failure. ID: ${id}.`);
      dispatch(plannerResourceUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createPlannerResource(data, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerResources.actions] createPlannerResource(%j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(plannerResourceCreateRequest(data));

    // call API
    const response = await api.postPlannerResources(data);
    let success = false;

    // post planner resources success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API planner resources success. Post: ${response.getIn([
          'data',
          'plannerResource',
          'id',
        ])}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'planner_resource'])],
        [schema.plannerResource]
      );
      const data = {
        id: response.getIn(['data', 'planner_resource', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerResourceCreateSuccess(data));
      success = true;

      // get planner resources failure
    } else {
      Logger.log('info', `POST API planner resources failure.`);
      dispatch(plannerResourceCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deletePlannerResource(id, cb = function () {}) {
  Logger.log(
    'debug',
    `[plannerResources.actions] deletePlannerResource(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(plannerResourceDeleteRequest(id));

    // call API
    const response = await api.deletePlannerResource(id);
    let success = false;

    // delete planner resource success
    if (204 === response.get('status')) {
      Logger.log('info', `DELETE API planner resource success. ID: ${id}.`);

      dispatch(plannerResourceDeleteSuccess(id));
      success = true;

      // get planner resource failure
    } else {
      Logger.log('info', `DELETE API planner resource failure. ID: ${id}.`);
      dispatch(plannerResourceDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function downloadPlannerResourcesCSV(
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.plannerResources.actions] downloadPlannerResourcesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(plannerResourceCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlannerResourcesCSV(order, filter);
    let success = false;

    // get planner resources CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API planner resources CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-planner-resources-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(plannerResourceCSVDownloadSuccess());
      success = true;

      // get planner resources CSV failure
    } else {
      Logger.log('info', `Get API planner resources CSV failure.`);
      dispatch(plannerResourceCSVDownloadFailure());
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `plannerResources.actions loaded.`);
