import {connect} from 'react-redux';

import PlannerAddScreen from '../components/PlannerAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.planners.get('isLoading'),
    success: state.planners.getIn(['form', 'success']),
  }
}

const PlannerAddScreenContainer = connect(
  mapStateToProps
)(PlannerAddScreen);

export default PlannerAddScreenContainer;
