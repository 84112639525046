import {connect} from 'react-redux';

import PlannerOwnerEditScreen from '../components/PlannerOwnerEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerOwners.get('isLoading'),
    success: state.plannerOwners.getIn(['form', 'success']),
  }
}

const PlannerOwnerEditScreenContainer = connect(
  mapStateToProps
)(PlannerOwnerEditScreen);

export default PlannerOwnerEditScreenContainer;
