import { connect } from 'react-redux';

import PlannerResourceEditScreen from '../components/PlannerResourceEditScreen';

const mapStateToProps = (state) => {
  return {
    isLoading: state.plannerResources.get('isLoading'),
    success: state.plannerResources.getIn(['form', 'success']),
  };
};

const PlannerResourceEditScreenContainer = connect(mapStateToProps)(
  PlannerResourceEditScreen
);

export default PlannerResourceEditScreenContainer;
