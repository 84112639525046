import {connect} from 'react-redux';

import PlannerModulesScreen from '../components/PlannerModulesScreen';

const mapStateToProps = state => {
  return {
    total: state.plannerModules.get('total'),
    isLoading: state.plannerModules.get('isLoading')
  }
}

const PlannerModulesScreenContainer = connect(
  mapStateToProps
)(PlannerModulesScreen);

export default PlannerModulesScreenContainer;
