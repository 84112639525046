import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLANNER_ACCOUNT_CATEGORY_LIST_REQUEST = 'PLANNER_ACCOUNT_CATEGORY_LIST_REQUEST';
export const PLANNER_ACCOUNT_CATEGORY_LIST_SUCCESS = 'PLANNER_ACCOUNT_CATEGORY_LIST_SUCCESS';
export const PLANNER_ACCOUNT_CATEGORY_LIST_FAILURE = 'PLANNER_ACCOUNT_CATEGORY_LIST_FAILURE';
export const PLANNER_ACCOUNT_CATEGORY_READ_REQUEST = 'PLANNER_ACCOUNT_CATEGORY_READ_REQUEST';
export const PLANNER_ACCOUNT_CATEGORY_READ_SUCCESS = 'PLANNER_ACCOUNT_CATEGORY_READ_SUCCESS';
export const PLANNER_ACCOUNT_CATEGORY_READ_FAILURE = 'PLANNER_ACCOUNT_CATEGORY_READ_FAILURE';
export const PLANNER_ACCOUNT_CATEGORY_UPDATE_REQUEST = 'PLANNER_ACCOUNT_CATEGORY_UPDATE_REQUEST';
export const PLANNER_ACCOUNT_CATEGORY_UPDATE_SUCCESS = 'PLANNER_ACCOUNT_CATEGORY_UPDATE_SUCCESS';
export const PLANNER_ACCOUNT_CATEGORY_UPDATE_FAILURE = 'PLANNER_ACCOUNT_CATEGORY_UPDATE_FAILURE';
export const PLANNER_ACCOUNT_CATEGORY_CREATE_REQUEST = 'PLANNER_ACCOUNT_CATEGORY_CREATE_REQUEST';
export const PLANNER_ACCOUNT_CATEGORY_CREATE_SUCCESS = 'PLANNER_ACCOUNT_CATEGORY_CREATE_SUCCESS';
export const PLANNER_ACCOUNT_CATEGORY_CREATE_FAILURE = 'PLANNER_ACCOUNT_CATEGORY_CREATE_FAILURE';
export const PLANNER_ACCOUNT_CATEGORY_DELETE_REQUEST = 'PLANNER_ACCOUNT_CATEGORY_DELETE_REQUEST';
export const PLANNER_ACCOUNT_CATEGORY_DELETE_SUCCESS = 'PLANNER_ACCOUNT_CATEGORY_DELETE_SUCCESS';
export const PLANNER_ACCOUNT_CATEGORY_DELETE_FAILURE = 'PLANNER_ACCOUNT_CATEGORY_DELETE_FAILURE';
export const PLANNER_ACCOUNT_CATEGORY_FORM_DESTROY = 'PLANNER_ACCOUNT_CATEGORY_FORM_DESTROY';
export const PLANNER_ACCOUNT_CATEGORY_CSV_DOWNLOAD_REQUEST = 'PLANNER_ACCOUNT_CATEGORY_CSV_DOWNLOAD_REQUEST';
export const PLANNER_ACCOUNT_CATEGORY_CSV_DOWNLOAD_SUCCESS = 'PLANNER_ACCOUNT_CATEGORY_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_ACCOUNT_CATEGORY_CSV_DOWNLOAD_FAILURE = 'PLANNER_ACCOUNT_CATEGORY_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerAccountCategoryListRequest(page, limit, order, filter) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function plannerAccountCategoryListSuccess(data) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryListSuccess(%j)`, data);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerAccountCategoryListFailure(error) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryListFailure(%j)`, error);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_LIST_FAILURE,
    error: error
  }
}

export function plannerAccountCategoryReadRequest(id) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryReadRequest(${id})`);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_READ_REQUEST,
    id: id
  }
}

export function plannerAccountCategoryReadSuccess(data) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryReadSuccess(%j)`, data);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerAccountCategoryReadFailure(error) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryReadFailure(%j)`, error);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_READ_FAILURE,
    error: error
  }
}

export function plannerAccountCategoryUpdateRequest(id, data) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryUpdateRequest(${id}, %j)`, data);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_UPDATE_REQUEST,
  }
}

export function plannerAccountCategoryUpdateSuccess(data) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryUpdateSuccess(%j)`, data);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerAccountCategoryUpdateFailure(error) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryUpdateFailure(%j)`, error);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_UPDATE_FAILURE,
    error: error
  }
}

export function plannerAccountCategoryCreateRequest(data) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryCreateRequest(%j)`, data);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_CREATE_REQUEST,
  }
}

export function plannerAccountCategoryCreateSuccess(data) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryCreateSuccess(%j)`, data);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerAccountCategoryCreateFailure(error) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryCreateFailure(%j)`, error);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_CREATE_FAILURE,
    error: error
  }
}

export function plannerAccountCategoryDeleteRequest(id) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryDeleteRequest(${id})`);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_DELETE_REQUEST,
    id: id
  }
}

export function plannerAccountCategoryDeleteSuccess(id) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryDeleteSuccess(${id})`);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_DELETE_SUCCESS,
    id: id,
  }
}

export function plannerAccountCategoryDeleteFailure(error) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryDeleteFailure(%j)`, error);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_DELETE_FAILURE,
    error: error
  }
}

export function plannerAccountCategoryFormDestroy(formState=null) {
  Logger.log('debug', `[plannerAccountCategories.actions] plannerAccountCategoryFormDestroy(%j)`, formState);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_FORM_DESTROY,
    form: formState
  }
}

export function plannerAccountCategoryCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.plannerAccountCategories.actions] plannerAccountCategoryCSVDownloadRequest()`);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function plannerAccountCategoryCSVDownloadSuccess() {
  Logger.log('debug', `[state.plannerAccountCategories.actions] plannerAccountCategoryCSVDownloadSuccess()`);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_CSV_DOWNLOAD_SUCCESS,
  }
}

export function plannerAccountCategoryCSVDownloadFailure() {
  Logger.log('debug', `[state.plannerAccountCategories.actions] plannerAccountCategoryCSVDownloadFailure()`);
  return {
    type: PLANNER_ACCOUNT_CATEGORY_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadPlannerAccountCategories(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[plannerAccountCategories.actions] loadPlannerAccountCategories(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerAccountCategoryListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlannerAccountCategories(page, limit, order, filter);
    let success = false;

    // get planner account category list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner Bank list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planner_account_categories']), [schema.plannerAccountCategory]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerAccountCategoryListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API planner account category success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerAccountCategoryListSuccess(data));
      success = true;

    // get planner account category list failure
    } else {
      Logger.log('info', `Get API planner account category list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(plannerAccountCategoryListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadPlannerAccountCategory(id, cb=function(){}) {
  Logger.log('debug', `[plannerAccountCategories.actions] loadPlannerAccountCategory(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerAccountCategoryReadRequest(id));

    // call API
    const response = await api.getPlannerAccountCategory(id);
    let success = false;

    // get planner account category success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner account category success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_account_category'])], [schema.plannerAccountCategory]);
      const data = {
        id: response.getIn(['data', 'planner_account_category', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerAccountCategoryReadSuccess(data));
      success = true;

    // get planner account category failure
    } else {
      Logger.log('info', `Get API planner account category failure. ID: ${id}.`);
      dispatch(plannerAccountCategoryReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updatePlannerAccountCategory(id, data, cb=function(){}) {
  Logger.log('debug', `[plannerAccountCategories.actions] updatePlannerAccountCategory(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerAccountCategoryUpdateRequest(id, data));

    // call API
    const response = await api.putPlannerAccountCategory(id, data);
    let success = false;

    // put planner account category success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API planner account category success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_account_category'])], [schema.plannerAccountCategory]);
      const data = {
        id: response.getIn(['data', 'planner_account_category', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerAccountCategoryUpdateSuccess(data));
      success = true;

    // get planner account category failure
    } else {
      Logger.log('info', `PUT API planner account category failure. ID: ${id}.`);
      dispatch(plannerAccountCategoryUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createPlannerAccountCategory(data, cb=function(){}) {
  Logger.log('debug', `[plannerAccountCategories.actions] createPlannerAccountCategory(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerAccountCategoryCreateRequest(data));

    // call API
    const response = await api.postPlannerAccountCategory(data);
    let success = false;

    // post planner account category success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API planner account category success. Post: ${response.getIn(['data', 'plannerAccountCategory', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_account_category'])], [schema.plannerAccountCategory]);
      const data = {
        id: response.getIn(['data', 'planner_account_category', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerAccountCategoryCreateSuccess(data));
      success = true;

    // get planner account category failure
    } else {
      Logger.log('info', `POST API planner account category failure.`);
      dispatch(plannerAccountCategoryCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deletePlannerAccountCategory(id, cb=function(){}) {
  Logger.log('debug', `[plannerAccountCategories.actions] deletePlannerAccountCategory(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerAccountCategoryDeleteRequest(id));

    // call API
    const response = await api.deletePlannerAccountCategory(id);
    let success = false;

    // delete planner account category success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API planner account category success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'planner_account_category', id: id}));
      dispatch(plannerAccountCategoryDeleteSuccess(id));
      success = true;

    // get planner account category failure
    } else {
      Logger.log('info', `DELETE API planner account category failure. ID: ${id}.`);
      dispatch(plannerAccountCategoryDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadPlannerAccountCategoriesCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.plannerAccountCategories.actions] downloadPlannerAccountCategoriesCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(plannerAccountCategoryCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlannerAccountCategoriesCSV(order, filter);

    // get planner account category CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner account category CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-planner-account-categories-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(plannerAccountCategoryCSVDownloadSuccess());

    // get planner account category CSV failure
    } else {
      Logger.log('info', `Get API planner account category CSV failure.`);
      dispatch(plannerAccountCategoryCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `plannerAccountCategories.actions loaded.`);
