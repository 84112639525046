import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLANNER_TO_DO_USER_LIST_REQUEST = 'PLANNER_TO_DO_USER_LIST_REQUEST';
export const PLANNER_TO_DO_USER_LIST_SUCCESS = 'PLANNER_TO_DO_USER_LIST_SUCCESS';
export const PLANNER_TO_DO_USER_LIST_FAILURE = 'PLANNER_TO_DO_USER_LIST_FAILURE';
export const PLANNER_TO_DO_USER_READ_REQUEST = 'PLANNER_TO_DO_USER_READ_REQUEST';
export const PLANNER_TO_DO_USER_READ_SUCCESS = 'PLANNER_TO_DO_USER_READ_SUCCESS';
export const PLANNER_TO_DO_USER_READ_FAILURE = 'PLANNER_TO_DO_USER_READ_FAILURE';
export const PLANNER_TO_DO_USER_UPDATE_REQUEST = 'PLANNER_TO_DO_USER_UPDATE_REQUEST';
export const PLANNER_TO_DO_USER_UPDATE_SUCCESS = 'PLANNER_TO_DO_USER_UPDATE_SUCCESS';
export const PLANNER_TO_DO_USER_UPDATE_FAILURE = 'PLANNER_TO_DO_USER_UPDATE_FAILURE';
export const PLANNER_TO_DO_USER_CREATE_REQUEST = 'PLANNER_TO_DO_USER_CREATE_REQUEST';
export const PLANNER_TO_DO_USER_CREATE_SUCCESS = 'PLANNER_TO_DO_USER_CREATE_SUCCESS';
export const PLANNER_TO_DO_USER_CREATE_FAILURE = 'PLANNER_TO_DO_USER_CREATE_FAILURE';
export const PLANNER_TO_DO_USER_DELETE_REQUEST = 'PLANNER_TO_DO_USER_DELETE_REQUEST';
export const PLANNER_TO_DO_USER_DELETE_SUCCESS = 'PLANNER_TO_DO_USER_DELETE_SUCCESS';
export const PLANNER_TO_DO_USER_DELETE_FAILURE = 'PLANNER_TO_DO_USER_DELETE_FAILURE';
export const PLANNER_TO_DO_USER_FORM_DESTROY = 'PLANNER_TO_DO_USER_FORM_DESTROY';
export const PLANNER_TO_DO_USER_CSV_DOWNLOAD_REQUEST = 'PLANNER_TO_DO_USER_CSV_DOWNLOAD_REQUEST';
export const PLANNER_TO_DO_USER_CSV_DOWNLOAD_SUCCESS = 'PLANNER_TO_DO_USER_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_TO_DO_USER_CSV_DOWNLOAD_FAILURE = 'PLANNER_TO_DO_USER_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerToDoUserListRequest(page, limit, order, filter) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: PLANNER_TO_DO_USER_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function plannerToDoUserListSuccess(data) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserListSuccess(%j)`, data);
  return {
    type: PLANNER_TO_DO_USER_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerToDoUserListFailure(error) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserListFailure(%j)`, error);
  return {
    type: PLANNER_TO_DO_USER_LIST_FAILURE,
    error: error
  }
}

export function plannerToDoUserReadRequest(id) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserReadRequest(${id})`);
  return {
    type: PLANNER_TO_DO_USER_READ_REQUEST,
    id: id
  }
}

export function plannerToDoUserReadSuccess(data) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserReadSuccess(%j)`, data);
  return {
    type: PLANNER_TO_DO_USER_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerToDoUserReadFailure(error) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserReadFailure(%j)`, error);
  return {
    type: PLANNER_TO_DO_USER_READ_FAILURE,
    error: error
  }
}

export function plannerToDoUserUpdateRequest(id, data) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserUpdateRequest(${id}, %j)`, data);
  return {
    type: PLANNER_TO_DO_USER_UPDATE_REQUEST,
  }
}

export function plannerToDoUserUpdateSuccess(data) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserUpdateSuccess(%j)`, data);
  return {
    type: PLANNER_TO_DO_USER_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerToDoUserUpdateFailure(error) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserUpdateFailure(%j)`, error);
  return {
    type: PLANNER_TO_DO_USER_UPDATE_FAILURE,
    error: error
  }
}

export function plannerToDoUserCreateRequest(data) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserCreateRequest(%j)`, data);
  return {
    type: PLANNER_TO_DO_USER_CREATE_REQUEST,
  }
}

export function plannerToDoUserCreateSuccess(data) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserCreateSuccess(%j)`, data);
  return {
    type: PLANNER_TO_DO_USER_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerToDoUserCreateFailure(error) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserCreateFailure(%j)`, error);
  return {
    type: PLANNER_TO_DO_USER_CREATE_FAILURE,
    error: error
  }
}

export function plannerToDoUserDeleteRequest(id) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserDeleteRequest(${id})`);
  return {
    type: PLANNER_TO_DO_USER_DELETE_REQUEST,
    id: id
  }
}

export function plannerToDoUserDeleteSuccess(id) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserDeleteSuccess(${id})`);
  return {
    type: PLANNER_TO_DO_USER_DELETE_SUCCESS,
    id: id,
  }
}

export function plannerToDoUserDeleteFailure(error) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserDeleteFailure(%j)`, error);
  return {
    type: PLANNER_TO_DO_USER_DELETE_FAILURE,
    error: error
  }
}

export function plannerToDoUserFormDestroy(formState=null) {
  Logger.log('debug', `[plannerToDosUser.actions] plannerToDoUserFormDestroy(%j)`, formState);
  return {
    type: PLANNER_TO_DO_USER_FORM_DESTROY,
    form: formState
  }
}

export function plannerToDoUserCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.plannerToDosUser.actions] plannerToDoUserCSVDownloadRequest()`);
  return {
    type: PLANNER_TO_DO_USER_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function plannerToDoUserCSVDownloadSuccess() {
  Logger.log('debug', `[state.plannerToDosUser.actions] plannerToDoUserCSVDownloadSuccess()`);
  return {
    type: PLANNER_TO_DO_USER_CSV_DOWNLOAD_SUCCESS,
  }
}

export function plannerToDoUserCSVDownloadFailure() {
  Logger.log('debug', `[state.plannerToDosUser.actions] plannerToDoUserCSVDownloadFailure()`);
  return {
    type: PLANNER_TO_DO_USER_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadPlannerToDosUser(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[plannerToDosUser.actions] loadPlannerToDosUser(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerToDoUserListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlannerToDosUser(page, limit, order, filter);
    let success = false;

    // get planner to do's user list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner to do's user list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planner_to_dos_user']), [schema.plannerToDoUser]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerToDoUserListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API planner to do's user success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerToDoUserListSuccess(data));
      success = true;
      
    // get planner to do's user list failure
    } else {
      Logger.log('info', `Get API planner to do's user list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(plannerToDoUserListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadPlannerToDoUser(id, cb=function(){}) {
  Logger.log('debug', `[plannerToDosUser.actions] loadPlannerToDoUser(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerToDoUserReadRequest(id));

    // call API
    const response = await api.getPlannerToDoUser(id);
    let success = false;

    // get planner to do user success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner to do user success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_to_do_user'])], [schema.plannerToDoUser]);
      const data = {
        id: response.getIn(['data', 'planner_to_do_user', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerToDoUserReadSuccess(data));
      success = true;
      
    // get planner to do use failure
    } else {
      Logger.log('info', `Get API planner to do user failure. ID: ${id}.`);
      dispatch(plannerToDoUserReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updatePlannerToDoUser(id, data, cb=function(){}) {
  Logger.log('debug', `[plannerToDosUser.actions] updatePlannerToDoUser(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerToDoUserUpdateRequest(id, data));

    // call API
    const response = await api.putPlannerToDoUser(id, data);
    let success = false;

    // put planner to do user success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API planner to do user success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_to_do_user'])], [schema.plannerToDoUser]);
      const data = {
        id: response.getIn(['data', 'planner_to_do_user', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerToDoUserUpdateSuccess(data));
      success = true;
      
    // get planner to do user failure
    } else {
      Logger.log('info', `PUT API planner to do user failure. ID: ${id}.`);
      dispatch(plannerToDoUserUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createPlannerToDoUser(data, cb=function(){}) {
  Logger.log('debug', `[plannerToDosUser.actions] createPlannerToDoUser(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerToDoUserCreateRequest(data));

    // call API
    const response = await api.postPlannerToDosUser(data);
    let success = false;

    // post planner to do's user success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API planner to do's user success. Post: ${response.getIn(['data', 'planner_to_do_user', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_to_do_user'])], [schema.plannerToDoUser]);
      const data = {
        id: response.getIn(['data', 'planner_to_do_user', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerToDoUserCreateSuccess(data));
      success = true;
      
    // get planner to do user failure
    } else {
      Logger.log('info', `POST API planner to do user failure.`);
      dispatch(plannerToDoUserCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deletePlannerToDoUser(id, cb=function(){}) {
  Logger.log('debug', `[plannerToDosUser.actions] deletePlannerToDoUser(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerToDoUserDeleteRequest(id));

    // call API
    const response = await api.deletePlannerToDoUser(id);
    let success = false;

    // delete planner to do user success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API planner to do user success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'planner_to_dos_user', id: id}));
      dispatch(plannerToDoUserDeleteSuccess(id));
      success = true;
      
    // get planner to do failure
    } else {
      Logger.log('info', `DELETE API planner to do user failure. ID: ${id}.`);
      dispatch(plannerToDoUserDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadPlannerToDosUserCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.plannerToDosUser.actions] downloadPlannerToDosUserCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(plannerToDoUserCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlannerToDosUserCSV(order, filter);

    // get planner to do's user CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner to do's user CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-planner-to-dos-user-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(plannerToDoUserCSVDownloadSuccess());
      
    // get planner to do's user CSV failure
    } else {
      Logger.log('info', `Get API planner to do's user CSV failure.`);
      dispatch(plannerToDoUserCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `plannerToDosUser.actions loaded.`);
