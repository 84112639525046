import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadPlannerAccount, deletePlannerAccount } from '../../../../../state/modules/plannerAccounts/actions';
import PlannerAccountViewScreen from '../components/PlannerAccountViewScreen';

const mapStateToProps = (state, ownProps) => {
  const planner_account = ownProps.match.params.id
    ? denormalize(
        state.entities.getIn(['plannerAccounts', ownProps.match.params.id]),
        schema.plannerAccount,
        state.entities.toJS()
      )
    : null;

  return {
    isLoading: state.plannerAccounts.get('isLoading'),
    planner_account: planner_account,
    id: ownProps.match.params.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerAccount(id, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlannerAccount(id, cb))
    },
  };
};

const PlannerAccountViewScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerAccountViewScreen);

export default PlannerAccountViewScreenContainer;
