import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadCareGuideMembersPlanner } from '../../../../../state/modules/careGuideHelpers/actions';
import TrustedHelperInputRow from '../components/TrustedHelperInputRow';

const mapStateToProps = (state, ownProps) => {

  const careGuideId = ownProps.careGuideId;
  const order = 'created_at.desc';

  const result = state.careGuideHelpers.getIn(['membersPlanner', careGuideId, 'pages', order]);

  const members = result
    ? result.map(x => {

      return {
        ...denormalize(
          state.entities.getIn(['careGuideMembers', x]),
          schema.careGuideMember,
          state.entities.toJS()
        )
      };
    }).toArray()
    : [];
    
    return {
    members: members.filter(x => 'role' in x),
    options: members.filter(x => 'role' in x)
      .map(x => ({
        label: x.profile ? x.profile.user.username : '',
        value: x.profile ? x.profile.id : ''
      })),
    isLoading: state.careGuideHelpers.get('areMembersLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (careGuideId, order, filter, cb) => {
      dispatch(loadCareGuideMembersPlanner(careGuideId, order, filter, cb));
    },
  }
}

const TrustedHelperInputRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrustedHelperInputRow);

export default TrustedHelperInputRowContainer;
