import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import {Translation, getI18n} from 'react-i18next';
import {Table} from 'antd';

import ListActions from '../../../elements/components/ListActions';
import {getColumnSearchProps} from '../../../elements/components/TableColumnFilters';
import {ModuleStateTag} from '../../../elements/components/Tags';
import {pathTo} from '../../../Routes';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

const PlannerModulesList = ({component, page, limit, order, filter, total, load, remove, history, ...props}) => {

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    delete filter[dataIndex];
    history.push(QueryString.append(props.location.pathname, {order, ...filter}));
  };

  const columns = [
    {
      title: getI18n().t('planner_owner_username'),
      dataIndex: 'owner_username',
      key: 'owner_username',
      ...getColumnSearchProps('owner_username', handleSearch, handleReset, getI18n().t('planner_owner_username'), filter),
    },
    {
      title: getI18n().t('planner_module_poa'),
      dataIndex: 'state_poa',
      key: 'state_poa',
      filters: [
        {text: getI18n().t('planner_module_state_1'), value: 1},
        {text: getI18n().t('planner_module_state_2'), value: 2},
        {text: getI18n().t('planner_module_state_3'), value: 3},

      ],
      filteredValue: 'state_poa' in filter ? filter['state_poa'].split(',') : null,
      render: val => <ModuleStateTag state={val} />,
    },
    {
      title: getI18n().t('planner_module_financial'),
      dataIndex: 'state_financial',
      key: 'state_financial',
      filters: [
        {text: getI18n().t('planner_module_state_1'), value: 1},
        {text: getI18n().t('planner_module_state_2'), value: 2},
        {text: getI18n().t('planner_module_state_3'), value: 3},

      ],
      filteredValue: 'state_poa' in filter ? filter['state_financial'].split(',') : null,
      render: val => <ModuleStateTag state={val} />,
    },
    {
      title: getI18n().t('planner_module_budget'),
      dataIndex: 'state_budget',
      key: 'state_budget',
      filters: [
        {text: getI18n().t('planner_module_state_1'), value: 1},
        {text: getI18n().t('planner_module_state_2'), value: 2},
        {text: getI18n().t('planner_module_state_3'), value: 3},

      ],
      filteredValue: 'state_budget' in filter ? filter['state_budget'].split(',') : null,
      render: val => <ModuleStateTag state={val} />,
    },
    {
      title: getI18n().t('planner_module_legal'),
      dataIndex: 'state_legal',
      key: 'state_legal',
      filters: [
        {text: getI18n().t('planner_module_state_1'), value: 1},
        {text: getI18n().t('planner_module_state_2'), value: 2},
        {text: getI18n().t('planner_module_state_3'), value: 3},

      ],
      filteredValue: 'state_legal' in filter ? filter['state_legal'].split(',') : null,
      render: val => <ModuleStateTag state={val} />,
    },

    {
      title: getI18n().t('table_header_actions'),
      dataIndex: 'id',
      key: 'actions',
      render: id =>
        <ListActions
          id={id}
          page={page}
          limit={limit}
          order={order}
          filter={filter}
          load={load.bind(this)}
          remove={remove.bind(this)}
          editScreen="PlannerModuleEditScreen"
        />,
    },
  ];

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
    position: ['bottomCenter'],
  }

  const handleTableChange = (pagination, filters, sorter) => {

    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, {page: pagination['current']});
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const order = sorter['field'] + '.' + (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = order;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    history.push(QueryString.append(path, params));
  }

  const filterString = JSON.stringify(filter);
  useEffect(() => {
    load(page, limit, order, JSON.parse(filterString));
  }, [page, limit, order, filterString, load]);

  return (
    <Translation>{(t) => 
      
      <Table
        dataSource={props.list}
        columns={columns}
        pagination={page === 1 && total < limit  ? false : pagination}
        loading={props.isLoading}
        scroll={{x: true}}
        onChange={handleTableChange}
      />

    }</Translation>
  );
}

export default withRouter(PlannerModulesList);

Logger.log('silly', `PlannerModulesList loaded.`);
