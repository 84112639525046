import React, { useEffect } from "react";
import { Translation } from "react-i18next";
import { Space, Checkbox, Form } from "antd";

import MembersInput from "./MembersInput";

const TrustedHelperInputRow = ({
  helper,
  index,
  careGuideId,
  disabled = false,
  options,
  isLoading,
  load,
  members,
  form,
  isSubmitting,
  ...props
}) => {

  // load members on mount
  useEffect(() => {

    if (careGuideId) {
      load(careGuideId, 'created_at.desc');
    }
  }, [load, careGuideId, isSubmitting]);

useEffect(() => {

  form.setFieldsValue({ trusted_helpers: members })
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [members]);


return (
    <Translation>
      {(t) => (

        <Form.List name="trusted_helpers">
            {(trusted_helpers, { add, remove, move }) => {
            return (
              <>
                <div className="scroll-trusted_helpers">
                  {trusted_helpers.map((helper, i) => (
                    <div key={helper.key} className="helper-input-row">
                      <Space style={{ display: "flex" }} align="start">
                        <>
                          <MembersInput
                            isLoading={isLoading}
                            options={options}
                            name={[helper.name, "profile", "id"]}
                            fieldKey={[helper.fieldKey, "profile", "id"]}
                            label={t("planner_trusted_helper_user")}
                            rules={[
                              {
                                required: true,
                                message: t("feedback_validation_required"),
                              },
                            ]}
                          />
                          <div className="form-group">
                            <Form.Item
                              name={[helper.name, "is_trusted"]}
                              fieldKey={[helper.fieldKey, "is_trusted"]}
                              label={t("planner_trusted_helper_is_trusted")}
                              rules={[
                                { required: true, message: t("feedback_validation_required") },
                              ]}
                              valuePropName="checked"
                            >
                              <Checkbox disabled={disabled} />
                            </Form.Item>
                          </div>
                        </>
                      </Space>
                    </div>
                  ))}
                </div>
              </>
            );
          }}
        </Form.List>

      )}
    </Translation>
  );
};

export default TrustedHelperInputRow;
