import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannerAnswers} from '../../../../../state/modules/plannerAnswers/actions';
import {sendMessage} from '../../../../../state/actions';
import PlannerAnswersList from '../components/PlannerAnswersList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.plannerAnswers.getIn(['pages', order, limit, page]);
  const list = result 
  ? result.map(x => {
      return {
        key: x,
        ...denormalize(
          state.entities.getIn(['plannerAnswers', x]),
          schema.plannerAnswer,
          state.entities.toJS()
        )
      };
    }).toArray().map(x => {
      
      let answers = {}
      x.planner_answers.map((y, i) => {
        
        answers[`answer_value_${y.answer_number}`] = y.value;

        if(y.year_born){
          answers[`answer_year_born_${y.answer_number}`] = y.year_born;
        }

        if(y.provided_care){
          answers[`answer_provided_care_${y.answer_number}`] = y.provided_care;
        }

        return y;
      })

      // flatten some nested objects to make table easier to work with
      return {
        count_trusted_helpers: x.count_trusted_helpers,
        owner_id: x.owner ? x.owner.id : '',
        owner_username: x.owner && x.owner.profile && x.owner.profile.user ? x.owner.profile.user.username : '',
        ...answers,
        ...x
      };
    })
  : [];

  return {
    list: list, 
    isLoading: state.plannerAnswers.get('isLoading'),
    total: state.plannerAnswers.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlannerAnswers(page, limit, order, filter, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PlannerAnswersListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerAnswersList);

export default PlannerAnswersListContainer;
