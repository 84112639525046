import {connect} from 'react-redux';

import PlannerToDoUserEditScreen from '../components/PlannerToDoUserEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerToDosUser.get('isLoading'),
    success: state.plannerToDosUser.getIn(['form', 'success']),
  }
}

const PlannerToDoUserEditScreenContainer = connect(
  mapStateToProps
)(PlannerToDoUserEditScreen);

export default PlannerToDoUserEditScreenContainer;
