import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Affix, Card, Col, Input, Row, Spin, Timeline, Typography } from 'antd';
import { DollarCircleOutlined, LoadingOutlined } from '@ant-design/icons';

import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import { BooleanTag } from '../../../elements/components/Tags';

const DisplayInput = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label
          htmlFor="registration_form_application"
          className=""
          title="Application"
        >
          {label}
        </label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input value={value} bordered={false} />
      </div>
    </div>
  </div>
);

const DisplayComponent = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label
          htmlFor="alert_form_application"
          className=""
          title="Application"
        >
          {label}
        </label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <div className="ant-form-item-control-input">
          <div className="ant-form-item-control-input-content">{value}</div>
        </div>
      </div>
    </div>
  </div>
);

const otp_services = {
  1: 'Application',
  2: 'SMS',
};

const sms_verified = {
  true: 'True',
  false: 'False',
};

class RegistrationViewScreen extends Component {
  scrollToTop = () => {
    Logger.log('debug', `RegistrationViewScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  render() {
    const { registration, isLoading } = this.props;
    const { Text } = Typography;
    return (
      <Translation>
        {(t) => (
          <div>
            <DocumentHead title={t('registrations_route_view')} />
            <div className="registration-view">
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={t('registrations_view_title')}
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <DisplayInput
                      label={t('registrations_id')}
                      value={registration ? registration.id : null}
                    />

                    <DisplayInput
                      label={t('registrations_first_name')}
                      value={registration ? registration.first_name : null}
                    />
                    <DisplayInput
                      label={t('registrations_last_name')}
                      value={registration ? registration.last_name : null}
                    />
                    <DisplayInput
                      label={t('registrations_email')}
                      value={registration ? registration.email : null}
                    />
                    <DisplayInput
                      label={t('registrations_prefix_telephone')}
                      value={
                        registration && registration.prefix_telephone
                          ? '+' + registration.prefix_telephone
                          : null
                      }
                    />

                    <DisplayInput
                      label={t('registrations_telephone')}
                      value={registration ? registration.telephone : null}
                    />
                    <DisplayInput
                      label={t('registrations_username')}
                      value={registration ? registration.username : null}
                    />
                    <DisplayInput
                      label={t('registrations_registration_code')}
                      value={
                        registration && registration.registration_code
                          ? registration.registration_code.code
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_invitation_code')}
                      value={
                        registration && registration.invitation_code
                          ? registration.invitation_code.code
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_care_guide_first_name')}
                      value={
                        registration ? registration.care_guide_first_name : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_care_guide_last_name')}
                      value={
                        registration ? registration.care_guide_last_name : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_care_guide_postal_code')}
                      value={
                        registration
                          ? registration.care_guide_postal_code
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_relationship')}
                      value={
                        registration && registration.relationship
                          ? registration.relationship.name
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_terms_of_service')}
                      value={
                        registration && registration.terms_of_service
                          ? registration.terms_of_service.id
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_ip_address')}
                      value={registration ? registration.ip_address : null}
                    />
                    <DisplayInput
                      label={t('registrations_timezone')}
                      value={registration ? registration.timezone : null}
                    />
                    <DisplayInput
                      label={t('registrations_plan')}
                      value={
                        registration && registration.plan
                          ? registration.plan.name
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_subscription_cycle')}
                      value={
                        registration ? registration.subscription_cycle : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_client_secret')}
                      value={registration ? registration.client_secret : null}
                    />
                    <DisplayInput
                      label={t('registrations_step_1_at')}
                      value={
                        registration
                          ? Format.date(
                              registration.step_1_at,
                              Config.get('DEFAULT_DATETIME_FORMAT')
                            )
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_step_2_at')}
                      value={
                        registration
                          ? Format.date(
                              registration.step_2_at,
                              Config.get('DEFAULT_DATETIME_FORMAT')
                            )
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_account_created_at')}
                      value={
                        registration
                          ? Format.date(
                              registration.account_created_at,
                              Config.get('DEFAULT_DATETIME_FORMAT')
                            )
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_checkout_at')}
                      value={
                        registration
                          ? Format.date(
                              registration.checkout_at,
                              Config.get('DEFAULT_DATETIME_FORMAT')
                            )
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_user')}
                      value={
                        registration && registration.user
                          ? registration.user.username
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_care_guide_id')}
                      value={registration ? registration.care_guide_id : null}
                    />

                    <DisplayInput
                      label={t('registrations_otp_default_service')}
                      value={
                        registration
                          ? otp_services[registration.otp_default_service]
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_is_telephone_verified')}
                      value={
                        registration
                          ? sms_verified[registration.is_telephone_verified]
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_is_remember_login')}
                      value={
                        registration
                          ? sms_verified[registration.is_remember_login]
                          : null
                      }
                    />
                    <DisplayInput
                      label={t('registrations_remember_at')}
                      value={
                        registration
                          ? Format.date(
                              registration.remember_at,
                              Config.get('DEFAULT_DATETIME_FORMAT')
                            )
                          : null
                      }
                    />

                    <DisplayInput
                      label={t(
                        'registrations_registration_care_guide_partner_tags'
                      )}
                      value={
                        registration
                          ? registration.registrations_care_partner_tags?.join(
                              ', '
                            )
                          : null
                      }
                    />
                    <DisplayComponent
                      label={t('registrations_agree_reminders_updates')}
                      value={
                        <BooleanTag
                          value={registration?.agree_reminders_updates}
                        />
                      }
                    />
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <Timeline mode="left">
                        <Timeline.Item
                          color={
                            registration && registration.step_1_at
                              ? 'blue'
                              : 'gray'
                          }
                        >
                          <Text>{t('registrations_step_1')}</Text>
                        </Timeline.Item>
                        <Timeline.Item
                          color={
                            registration && registration.step_2_at
                              ? 'blue'
                              : 'gray'
                          }
                        >
                          <Text
                            delete={
                              registration && registration.invitation_code
                            }
                          >
                            {t('registrations_step_2')}
                          </Text>
                        </Timeline.Item>
                        <Timeline.Item
                          color={
                            registration && registration.account_created_at
                              ? 'green'
                              : 'gray'
                          }
                        >
                          <Text
                            strong={
                              registration && registration.account_created_at
                            }
                          >
                            {t('registrations_account_created')}
                          </Text>
                        </Timeline.Item>

                        <Timeline.Item
                          color={
                            registration && registration.checkout_at
                              ? 'green'
                              : 'gray'
                          }
                          dot={<DollarCircleOutlined />}
                        >
                          <Text
                            strong={registration && registration.checkout_at}
                            delete={
                              registration && registration.invitation_code
                            }
                          >
                            {t('registrations_checkout')}
                          </Text>
                        </Timeline.Item>
                      </Timeline>

                      {registration && registration.created_at ? (
                        <p>
                          {t('form_metadata_created_at')}{' '}
                          <small>
                            {Format.date(
                              registration.created_at,
                              Config.get('DEFAULT_DATETIME_FORMAT')
                            )}
                          </small>
                        </p>
                      ) : (
                        ''
                      )}
                      {registration &&
                      registration.updated_at &&
                      registration.updated_at !== registration.created_at ? (
                        <p>
                          {t('form_metadata_updated_ad')}{' '}
                          <small>
                            {Format.date(
                              registration.updated_at,
                              Config.get('DEFAULT_DATETIME_FORMAT')
                            )}
                          </small>
                        </p>
                      ) : (
                        ''
                      )}
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
    this.props.load(this.props.id);
  }
}

export default RegistrationViewScreen;

Logger.log('silly', `RegistrationViewScreen loaded.`);
