import {connect} from 'react-redux';

import PlannerAnswersScreen from '../components/PlannerAnswersScreen';

const mapStateToProps = state => {
  return {
    total: state.plannerAnswers.get('total'),
    isLoading: state.plannerAnswers.get('isLoading')
  }
}

const PlannerAnswersScreenContainer = connect(
  mapStateToProps
)(PlannerAnswersScreen);

export default PlannerAnswersScreenContainer;
