import {connect} from 'react-redux';

import PlannerOwnersScreen from '../components/PlannerOwnersScreen';

const mapStateToProps = state => {
  return {
    total: state.plannerOwners.get('total'),
    isLoading: state.plannerOwners.get('isLoading')
  }
}

const PlannerOwnersScreenContainer = connect(
  mapStateToProps
)(PlannerOwnersScreen);

export default PlannerOwnersScreenContainer;
