import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Affix, Card, Col, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';


const DisplayInput = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label htmlFor="planner_document_form_application" className="" title="Application">{label}</label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input value={value} bordered={false} />
      </div>
    </div>
  </div>
);

const category_document =
{
  1: "planner_documents_category_document_1",
  2: "planner_documents_category_document_2",
  3: "planner_documents_category_document_3",
  4: "planner_documents_category_document_4",
  5: "planner_documents_category_document_5",
};

const section_document = {
    1: 'planner_documents_section_1',
    2: 'planner_documents_section_2',
    3: 'planner_documents_section_3',
    4: 'planner_documents_section_4',
    5: 'planner_documents_section_5',
    6: 'planner_documents_section_6',
    7: 'planner_documents_section_7',
  };

const document_type = 
  {
    1: "planner_documents_document_type_1",
    2: "planner_documents_document_type_2",
  };


class PlannerDocumentViewScreen extends Component {

  scrollToTop = () => {
    Logger.log('debug', `PlannerDocumentViewScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }

  render() {
    const { plannerDocument, isLoading } = this.props;
    
    return (
      <Translation>{(t) =>
        <div>
          <DocumentHead title={t('planner_documents_route_view')} />

          <div className="plannerDocument-view">

            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={t('planner_documents_view_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >
                  <DisplayInput label={t('planner_documents_id')} value={plannerDocument ? plannerDocument.id : null} />
                  <DisplayInput label={t('planner_documents_planner_id')} value={plannerDocument && plannerDocument.planner ? plannerDocument.planner.id : null} />
                  <DisplayInput label={t('planner_documents_owner_username')} value={plannerDocument && plannerDocument.planner && plannerDocument.planner.owner.profile && plannerDocument.planner.owner.profile.user ? plannerDocument.planner.owner.profile.user.username : null} />
                  <DisplayInput label={t('planner_documents_file_name')} value={plannerDocument ? plannerDocument.file_name : null} />
                  <DisplayInput label={t('planner_documents_user_file_name')} value={plannerDocument ? plannerDocument.user_file_name : null} />
                  <DisplayInput label={t('planner_documents_file_extension')} value={plannerDocument ? plannerDocument.file_extension : null} />
                  <DisplayInput label={t('planner_documents_file_directory')} value={plannerDocument ? plannerDocument.file_directory : null} />
                  <DisplayInput label={t('planner_documents_file_bucket')} value={plannerDocument ? plannerDocument.file_bucket : null} />
                  <DisplayInput label={t('planner_documents_category_document')} value={plannerDocument ? t(category_document[plannerDocument.category_document]) : null} />
                  <DisplayInput label={t('planner_documents_document_type')} value={plannerDocument ? t(document_type[plannerDocument.document_type]) : null} />
                  <DisplayInput label={t('planner_documents_section')} value={plannerDocument ? t(section_document[plannerDocument.section]) : null} />
                  <DisplayInput label={t('planner_documents_note')} value={plannerDocument ? plannerDocument.note : null} />
                  <DisplayInput label={t('planner_documents_size_bytes')} value={plannerDocument ? plannerDocument.size_bytes : null} />
                  <DisplayInput label={t('planner_documents_uploaded_at')} value={plannerDocument ? plannerDocument.uploaded_at : null} />
                </Card>
              </Col>
              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                      {plannerDocument && plannerDocument.created_at
                        ? <p>{t('form_metadata_created_at')} <small>{Format.date(plannerDocument.created_at, Config.get('DEFAULT_DATETIME_FORMAT'))}</small></p>
                        : ''}
                      {plannerDocument && plannerDocument.updated_at && plannerDocument.updated_at !== plannerDocument.created_at
                        ? <p>{t('form_metadata_updated_ad')} <small>{Format.date(plannerDocument.updated_at, Config.get('DEFAULT_DATETIME_FORMAT'))}</small></p>
                        : ''}
                  </Card>
                </Affix>
              </Col>
            </Row>

          </div>

        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
    this.props.load(this.props.id);
  }
}

export default PlannerDocumentViewScreen;

Logger.log('silly', `PlannerDocumentViewScreen loaded.`);
