import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Translation, getI18n } from "react-i18next";
import {
  Affix,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
  Collapse,
} from "antd";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";

import TrustedHelperInputRow from "../containers/TrustedHelperInputRowContainer";
import message from "../../../elements/lib/MessageWrapper";
import FormMetadata from "../../../elements/components/FormMetadata";
import { pathTo } from "../../../Routes";
import Format from "../../../../../lib/Format";
import Logger from "../../../../../lib/Logger";
import Config from "../../../../../Config";

const PlannerForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  ...props
}) => {

  const [redirectTo, setRedirectTo] = useState(null);
  const [careGuideId, setCareGuideId] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [ownerId, setOwnerId] = useState(null);

  const [form] = Form.useForm();

  const { Panel } = Collapse;

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {   
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if ((id) && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      dataObj["started_at"] = dataObj["started_at"]
        ? moment(dataObj["started_at"], Config.get("API_DATETIME_FORMAT"))
        : moment();
      setCareGuideId(dataObj['care_guide']);
      setOwnerId(dataObj['owner']);
      setAccountId(dataObj['account_id']);
      delete dataObj.trusted_helpers;
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = "";
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === "") {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo("PlannerEditScreen", { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);


  // submit data handler
  const submitData = async (values) => {
    Logger.log("debug", `PlannerForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {

      if (input in values) {
        // transform entity refs to integer IDs
        if (["relationship"].includes(input)) {
          payload[input + "_id"] = parseInt(values[input]);
        }
        // transform entity refs to IDs
        else if (["owner"].includes(input)) {
          payload["owner_id"] = ownerId;
        }
        else if (["care_guide"].includes(input)) {
          payload["care_guide_id"] = careGuideId;
        }
        else if (["account_id"].includes(input)) {
          payload["account_id"] = accountId;
        }
        // transform dates into ISO
        else if (["started_at"].includes(input)) {
          payload[input] = Format.date(
            values[input],
            Config.get("API_DATETIME_FORMAT")
          );
        } else {
          payload[input] = values[input];
        }
      } else {
        // transform entity refs to IDs
        if (["owner"].includes(input)) {
          payload["owner_id"] = ownerId;
        }
        else if (["care_guide"].includes(input)) {
          payload["care_guide_id"] = careGuideId;
        }
        else if (["account_id"].includes(input)) {
          payload["account_id"] = accountId;
        }
      }
    }

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t("feedback_form_success"));
        } else {
          message.error(getI18n().t("feedback_form_error"));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t("feedback_form_success"));
        } else {
          message.error(getI18n().t("feedback_form_error"));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log("debug", `PlannerForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log("debug", `PlannerForm.handleFinishFailed(###)`);
    message.error(getI18n().t("feedback_form_error"));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }


  return (
    <Translation>
      {(t) => (
        <>
          <div className="planner-form">
            <Form
              name="planner_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t("planner_edit_title")
                        : t("planner_add_title")
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    {id ? (
                      <div className="form-group">
                        <Form.Item label={t("planner_planner_id")}>
                          <span className="ant-form-text">{id}</span>
                        </Form.Item>
                      </div>
                    ) : null}


                    <Form.Item label={t("planner_trusted_helpers")}>
                      <TrustedHelperInputRow
                      isSubmitting={isSubmitting}
                        form={form}
                        disabled={isLoading || isSubmitting}
                        careGuideId={careGuideId}
                      />
                    </Form.Item>

                    <div className="form-group">
                      <Form.Item
                        name="started_at"
                        label={t("planner_started_at")}
                        rules={[
                          {
                            required: true,
                            message: t("feedback_validation_required"),
                          },
                        ]}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        wrapperCol={{
                          offset: 3,
                        }}
                      >
                        <Collapse ghost>
                          <Panel header="ID's" key="planner-ids">

                            <div className="form-group">
                              <Form.Item
                                labelCol={{ span: 3 }}
                                wrapperCol={{
                                  offset: 0,
                                }}

                                name="account_id"
                                label={t("planner_account_id")}
                                rules={[
                                  {
                                    required: true,
                                    message: t("feedback_validation_required"),
                                  },
                                ]}
                              >
                                <Input disabled={isLoading || isSubmitting}
                                  onChange={(e) =>
                                    setAccountId(e.target.value)
                                  } />
                              </Form.Item>
                            </div>

                            <div className="form-group">
                              <Form.Item
                                labelCol={{ span: 3 }}
                                wrapperCol={{
                                  offset: 0,
                                }}
                                name="owner"
                                label={t("planner_owner_id")}
                                rules={[
                                  {
                                    required: true,
                                    message: t("feedback_validation_required"),
                                  },
                                ]}
                              >
                                <Input disabled={isLoading || isSubmitting}
                                  onChange={(e) =>
                                    setOwnerId(e.target.value)
                                  } />
                              </Form.Item>
                            </div>


                            <div className="form-group">
                              <Form.Item
                                labelCol={{ span: 3 }}
                                wrapperCol={{
                                  offset: 0,
                                }}
                                name="care_guide"
                                label={t('planner_care_guide_id')}
                                rules={[
                                  { required: true, message: t('feedback_validation_required') },
                                ]}
                              >
                                <Input
                                  autoFocus
                                  disabled={isLoading || isSubmitting}
                                  onChange={(e) =>
                                    setCareGuideId(e.target.value)
                                  } />
                              </Form.Item>
                            </div>



                          </Panel>
                        </Collapse>
                      </Form.Item>

                    </div>



                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t("form_metadata_header")}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="PlannersScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default PlannerForm;

Logger.log("silly", `PlannerForm loaded.`);
