import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';

import {
  loadPlannerModule,
  updatePlannerModule,
  createPlannerModule,
  deletePlannerModule,
  plannerModuleFormDestroy,
} from '../../../../../state/modules/plannerModules/actions';
import { sendMessage } from '../../../../../state/actions';
import PlannerModuleForm from '../components/PlannerModuleForm';
import Config from '../../../../../Config';

const inputs = List([
  'id',
  'planner_poa',
  'planner_legal',
  'planner_financial',
  'planner_budget',
]);


const mapStateToProps = (state, ownProps) => {

  const plannerModuleId = ownProps.id || ownProps.copyId;

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['plannerModules', plannerModuleId, val], '');

    if (val === 'id') {
      errors[val] = state.plannerModules.getIn(['form', 'errors', val]);
    } else {
      errors[`${val}_state`] = state.plannerModules.getIn(['form', 'errors', val]);
    }
  }

  Object.keys(data).forEach(x => {

    if (typeof data[x] === 'object' && data[x] !== null) {

      if ('id' in data[x]) {
        data[`${x}_id`] = data[x]['id'];
      }

      if ('state' in data[x]) {
        data[`${x}_state`] = data[x]['state'];
      }

      if ('state_at' in data[x]) {
        data[`${x}_state_at`] = data[x]['state_at']
          ? moment(data[x]['state_at'], Config.get('API_DATETIME_FORMAT'))
          : null;
      }

      if ('step_1' in data[x]) {
        data[`${x}_step_1`] = data[x]['step_1'];
      }

      if ('step_1_at' in data[x]) {
        data[`${x}_step_1_at`] = data[x]['step_1_at']
          ? moment(data[x]['step_1_at'], Config.get('API_DATETIME_FORMAT'))
          : null;
      }

      if ('step_2' in data[x]) {
        data[`${x}_step_2`] = data[x]['step_2'];
      }

      if ('step_2_at' in data[x]) {
        data[`${x}_step_2_at`] = data[x]['step_2_at']
          ? moment(data[x]['step_2_at'], Config.get('API_DATETIME_FORMAT'))
          : null;
      }

      if ('step_3' in data[x]) {
        data[`${x}_step_3`] = data[x]['step_3'];
      }

      if ('step_3_at' in data[x]) {
        data[`${x}_step_3_at`] = data[x]['step_3_at']
          ? moment(data[x]['step_3_at'], Config.get('API_DATETIME_FORMAT'))
          : null;
      }

      if ('step_4' in data[x]) {
        data[`${x}_step_4`] = data[x]['step_4'];
      }

      if ('step_4_at' in data[x]) {
        data[`${x}_step_4_at`] = data[x]['step_4_at']
          ? moment(data[x]['step_4_at'], Config.get('API_DATETIME_FORMAT'))
          : null;
      }

      if ('step_5' in data[x]) {
        data[`${x}_step_5`] = data[x]['step_5'];
      }

      if ('step_5_at' in data[x]) {
        data[`${x}_step_5_at`] = data[x]['step_5_at']
          ? moment(data[x]['step_5_at'], Config.get('API_DATETIME_FORMAT'))
          : null;
      }

      delete data[x];
    }
  });

  return {
    payloadValues: [
      'id',
      'planner_poa',
      'planner_legal',
      'planner_financial',
      'planner_budget',
    ],
    isLoading: state.plannerModules.get('isLoading'),
    isSubmitting: state.plannerModules.getIn(['form', 'isSubmitting']),
    success: state.plannerModules.getIn(['form', 'success']),
    created_id: state.plannerModules.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['plannerModules', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['plannerModules', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerModule(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePlannerModule(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPlannerModule(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlannerModule(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(plannerModuleFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PlannerModuleFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerModuleForm);

export default PlannerModuleFormContainer;
