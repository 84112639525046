import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerModulesScreen from './containers/PlannerModulesScreenContainer';
import PlannerModuleAddScreen from './containers/PlannerModuleAddScreenContainer';
import PlannerModuleEditScreen from './containers/PlannerModuleEditScreenContainer';

const register = () => {
  Logger.log('debug', `plannerModules.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerModules', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerModuleAddScreen': ['PrivateRoute', '/planner-modules/add', true, PlannerModuleAddScreen, getI18n().t('planner_module_route_add')],
        'PlannerModuleEditScreen': ['PrivateRoute', '/planner-modules/edit/:id([\\w\\-]+)', true, PlannerModuleEditScreen, getI18n().t('planner_module_route_edit')],
        'PlannerModulesScreen': ['PrivateRoute', '/planner-modules/:page(\\d+)?', true, PlannerModulesScreen, getI18n().t('planner_modules_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `plannerModules.register() loaded.`);
