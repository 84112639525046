import {connect} from 'react-redux';

import {downloadPlannerToDosUserCSV} from '../../../../../state/modules/plannerToDosUser/actions';
import PlannerToDosUserCSVButton from '../components/PlannerToDosUserCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.plannerToDosUser.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPlannerToDosUserCSV(order, filter, cb));
    }
  }
}

const PlannerToDosUserCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerToDosUserCSVButton);

export default PlannerToDosUserCSVButtonContainer;
