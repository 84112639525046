import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Card, Col, Input, Row } from 'antd';
import DocumentHead from '../../../elements/components/DocumentHead';

import Logger from '../../../../../lib/Logger';


const options =
{
  value_1: 'planner_to_do_value_answer_1',
  value_2: 'planner_to_do_value_answer_2',
  value_3: 'planner_to_do_value_answer_3',
};

const optionsSection1 =
{
  value_1: 'planner_to_do_section_1_value_answer_1',
  value_2: 'planner_to_do_section_1_value_answer_2',
  value_3: 'planner_to_do_section_1_value_answer_3',
};


const DisplayInput = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label htmlFor="planner_answer_form_application" className="" title="Application">{label}</label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input value={value} bordered={false} />
      </div>
    </div>
  </div>
);


const DisplayInputText = ({ label, value, ...props }) => (
  <div className="form-group">
    <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
      <div className="ant-col ant-col-5 ant-form-item-label">
        <label htmlFor="planner_answer_form_application" className="" title="Application">{label}</label>
      </div>
      <div className="ant-col ant-col-19 ant-form-item-control">
        <Input.TextArea value={value} bordered={false} />
      </div>
    </div>
  </div>
);

class RegistrationViewScreen extends Component {

  scrollToTop = () => {
    Logger.log('debug', `RegistrationViewScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }

  render() {

    const { planner } = this.props;


    if (planner?.planner_answers) {
      planner.planner_answers.sort((a, b) => a.answer_number - b.answer_number);
    }


    return (
      <Translation>{(t) =>
        <div>
          <DocumentHead title={t('planner_answers_route_view')} />

          <div className="planner_answer-view">

            <Row gutter={24}>

              <Col xs={24} lg={24}>
               
                  {planner?.planner_answers.map(x => {

                    return (
                      x && (
                        <>
                          <Card
                            title={t(`planner_answer_number_${x.answer_number}`)}
                            key={x.id}
                          >
                            {x.value && <DisplayInput label={t('planner_answer_value')} value={x && x.answer_number === 1 ? t(optionsSection1[`value_${x.value}`]):  t(options[`value_${x.value}`])} />}
                            {x.year_born && <DisplayInput label={t('planner_answer_year_born')} value={x ? x.year_born : null} />}
                            {x.provided_care && <DisplayInput label={t('planner_answer_provided_care')} value={x ? x.provided_care : null} />}
                           <DisplayInputText label={t('planner_answer_comments')} value={x ? x.comments : ""} />

                          </Card>
                        </>
                      )
                    )
                  })}
              </Col>
            </Row>

          </div>

        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
    this.props.load(this.props.id);
  }
}

export default RegistrationViewScreen;

Logger.log('silly', `RegistrationViewScreen loaded.`);
