import {connect} from 'react-redux';

import PlannerEditScreen from '../components/PlannerEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.planners.get('isLoading'),
    success: state.planners.getIn(['form', 'success']),
  }
}

const PlannerEditScreenContainer = connect(
  mapStateToProps
)(PlannerEditScreen);

export default PlannerEditScreenContainer;
