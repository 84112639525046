import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerDocumentsScreen from './containers/PlannerDocumentsScreenContainer';
import PlannerDocumentAddScreen from './containers/PlannerDocumentAddScreenContainer';
import PlannerDocumentEditScreen from './containers/PlannerDocumentEditScreenContainer';
import PlannerDocumentViewScreen from './containers/PlannerDocumentViewScreenContainer';

const register = () => {
  Logger.log('debug', `planner_documents.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerDocuments', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerDocumentViewScreen': ['PrivateRoute', '/planner-documents/view/:id([\\w\\-]+)', true, PlannerDocumentViewScreen, getI18n().t('planner_documents_route_view')],
        'PlannerDocumentAddScreen': ['PrivateRoute', '/planner-documents/add', true, PlannerDocumentAddScreen, getI18n().t('planner_documents_route_add')],
        'PlannerDocumentEditScreen': ['PrivateRoute', '/planner-documents/edit/:id([\\w\\-]+)', true, PlannerDocumentEditScreen, getI18n().t('planner_documents_route_edit')],
        'PlannerDocumentsScreen': ['PrivateRoute', '/planner-documents/:page(\\d+)?', true, PlannerDocumentsScreen, getI18n().t('planner_documents_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `planner_documents.register() loaded.`);
