import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadUser,
  updateUser,
  createUser,
  deleteUser,
  userFormDestroy
} from '../../../../../state/modules/users/actions';
import {sendMessage} from '../../../../../state/actions';
import UserForm from '../components/UserForm';
import Config from '../../../../../Config';

const inputs = List([
  'username',
  'email',
  'roles',
  'is_verified',
  'is_enrolled_otp',
  'is_remember_login',
  'otp_secret',
  'otp_default_service',
  'is_telephone_verified',
  'registration_code',
  'password',
  'status',
  'first_name',
  'last_name',
  'prefix_telephone',
  'telephone',
  'timezone',
  'joined_at',
  'remember_at',
  'alert_summary',
  'alert_summary_at',
  'alerts_in_app',
  'alerts_email',
  'alerts_sms',
  'reminders_email',
  'reminders_sms',
  'customer_id',
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['users', ownProps.id, val], '');
    if (state.users.getIn(['form', 'errors', 'profile', val])) {
      errors[val] = state.users.getIn(['form', 'errors', 'profile', val]);
    } 
    else if (['registration_code'].includes(val)) {
      errors[val] = state.users.getIn(['form', 'errors', val + '_id']);
    }
    else if (state.users.getIn(['form', 'errors', val])) {
      errors[val] = state.users.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['joined_at'] = data['joined_at']
    ? moment(data['joined_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['remember_at'] = data['remember_at']
    ? moment(data['remember_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  data['password_changed_at'] = data['password_changed_at']
    ? moment(data['password_changed_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['alerts_in_app'] = data['alerts_in_app']
    ? Object.keys(data['alerts_in_app']).filter(x => data['alerts_in_app'][x])
    : null;

  data['alerts_email'] = data['alerts_email']
    ? Object.keys(data['alerts_email']).filter(x => data['alerts_email'][x])
    : null;

  data['alerts_sms'] = data['alerts_sms']
    ? Object.keys(data['alerts_sms']).filter(x => data['alerts_sms'][x])
    : null;

  data['reminders_email'] = data['reminders_email']
    ? Object.keys(data['reminders_email']).filter(x => data['reminders_email'][x])
    : null;

  data['reminders_sms'] = data['reminders_sms']
    ? Object.keys(data['reminders_sms']).filter(x => data['reminders_sms'][x])
    : null;

  data['registration_code'] = data['registration_code']
    ? data['registration_code'].id
    : null;

  data['alert_summary'] = data['alert_summary'] ? 2 : 1;

  return {
    isLoading: state.users.get('isLoading'),
    isSubmitting: state.users.getIn(['form', 'isSubmitting']),
    success: state.users.getIn(['form', 'success']),
    created_id: state.users.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['users', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['users', ownProps.id, 'updated_at'], null),
    passwordChangedAt: state.entities.getIn(['users', ownProps.id, 'password_changed_at'], null),
    profileId: state.entities.getIn(['users', ownProps.id, 'profile_id'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadUser(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateUser(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createUser(data, cb));
    },
    remove: (id, cb) => {
      dispatch(deleteUser(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(userFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    }
  }
}

const UserFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);

export default UserFormContainer;
