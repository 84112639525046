import {connect} from 'react-redux';

import PlannerModuleAddScreen from '../components/PlannerModuleAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerModules.get('isLoading'),
    success: state.plannerModules.getIn(['form', 'success']),
  }
}

const PlannerModuleAddScreenContainer = connect(
  mapStateToProps
)(PlannerModuleAddScreen);

export default PlannerModuleAddScreenContainer;
