import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerOwnersScreen from './containers/PlannerOwnersScreenContainer';
import PlannerOwnerAddScreen from './containers/PlannerOwnerAddScreenContainer';
import PlannerOwnerEditScreen from './containers/PlannerOwnerEditScreenContainer';

const register = () => {
  Logger.log('debug', `plannerOwners.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerOwners', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerOwnerAddScreen': ['PrivateRoute', '/planner-owners/add', true, PlannerOwnerAddScreen, getI18n().t('planner_owner_route_add')],
        'PlannerOwnerEditScreen': ['PrivateRoute', '/planner-owners/edit/:id([\\w\\-]+)', true, PlannerOwnerEditScreen, getI18n().t('planner_owner_route_edit')],
        'PlannerOwnersScreen': ['PrivateRoute', '/planner-owners/:page(\\d+)?', true, PlannerOwnersScreen, getI18n().t('planner_owners_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `plannerOwners.register() loaded.`);
