import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import ResourceCategoriesScreen from './containers/ResourceCategoriesScreenContainer';
import ResourceCategoryAddScreen from './containers/ResourceCategoryAddScreenContainer';
import ResourceCategoryEditScreen from './containers/ResourceCategoryEditScreenContainer';

const register = () => {
  Logger.log('debug', `resourceCategories.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'resourceCategories', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'ResourceCategoryAddScreen': ['PrivateRoute', '/resource-categories/add', true, ResourceCategoryAddScreen, getI18n().t('resource_category_route_add')],
        'ResourceCategoryEditScreen': ['PrivateRoute', '/resource-categories/edit/:id([\\w\\-]+)', true, ResourceCategoryEditScreen, getI18n().t('resource_category_route_edit')],
        'ResourceCategoriesScreen': ['PrivateRoute', '/resource-categories/:page(\\d+)?', true, ResourceCategoriesScreen, getI18n().t('resource_categories_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `resourceCategories.register() loaded.`);