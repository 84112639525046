import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';

import {
  loadPlannerNote,
  updatePlannerNote,
  createPlannerNote,
  deletePlannerNote,
  plannerNoteFormDestroy
} from '../../../../../state/modules/plannerNotes/actions';
import PlannerNoteForm from '../components/PlannerNoteForm';
import Config from '../../../../../Config';

const inputs = List([
  'planner',
  'body',
  'title',
  'is_pinned',
  'pinned_at',
  'status'
]);

const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['plannerNotes', ownProps.id, val], '');
    if (['planner'].includes(val)) {
      errors[val] = state.plannerNotes.getIn(['form', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.plannerNotes.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach(x => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['pinned_at'] = data['pinned_at']
    ? moment(data['pinned_at'], Config.get('API_DATETIME_FORMAT'))
    : null;

  return {
    isLoading: state.plannerNotes.get('isLoading'),
    isSubmitting: state.plannerNotes.getIn(['form', 'isSubmitting']),
    success: state.plannerNotes.getIn(['form', 'success']),
    created_id: state.plannerNotes.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['plannerNotes', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['plannerNotes', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerNote(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePlannerNote(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPlannerNote(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlannerNote(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(plannerNoteFormDestroy(formState));
    },
  }
}

const PlannerNoteFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerNoteForm);

export default PlannerNoteFormContainer;