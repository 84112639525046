import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const CALL_LIST_REQUEST = 'CALL_LIST_REQUEST';
export const CALL_LIST_SUCCESS = 'CALL_LIST_SUCCESS';
export const CALL_LIST_FAILURE = 'CALL_LIST_FAILURE';
export const CALL_READ_REQUEST = 'CALL_READ_REQUEST';
export const CALL_READ_SUCCESS = 'CALL_READ_SUCCESS';
export const CALL_READ_FAILURE = 'CALL_READ_FAILURE';
export const CALL_UPDATE_REQUEST = 'CALL_UPDATE_REQUEST';
export const CALL_UPDATE_SUCCESS = 'CALL_UPDATE_SUCCESS';
export const CALL_UPDATE_FAILURE = 'CALL_UPDATE_FAILURE';
export const CALL_DELETE_REQUEST = 'CALL_DELETE_REQUEST';
export const CALL_DELETE_SUCCESS = 'CALL_DELETE_SUCCESS';
export const CALL_DELETE_FAILURE = 'CALL_DELETE_FAILURE';
export const CALL_FORM_DESTROY = 'CALL_FORM_DESTROY';
export const CALL_CSV_DOWNLOAD_REQUEST = 'CALL_CSV_DOWNLOAD_REQUEST';
export const CALL_CSV_DOWNLOAD_SUCCESS = 'CALL_CSV_DOWNLOAD_SUCCESS';
export const CALL_CSV_DOWNLOAD_FAILURE = 'CALL_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function callListRequest(page, limit, order, filter) {
  Logger.log('debug', `[calls.actions] callListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: CALL_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function callListSuccess(data) {
  Logger.log('debug', `[calls.actions] callListSuccess(%j)`, data);
  return {
    type: CALL_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function callListFailure(error) {
  Logger.log('debug', `[calls.actions] callListFailure(%j)`, error);
  return {
    type: CALL_LIST_FAILURE,
    error: error
  }
}

export function callReadRequest(id) {
  Logger.log('debug', `[calls.actions] callReadRequest(${id})`);
  return {
    type: CALL_READ_REQUEST,
    id: id
  }
}

export function callReadSuccess(data) {
  Logger.log('debug', `[calls.actions] callReadSuccess(%j)`, data);
  return {
    type: CALL_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function callReadFailure(error) {
  Logger.log('debug', `[calls.actions] callReadFailure(%j)`, error);
  return {
    type: CALL_READ_FAILURE,
    error: error
  }
}

export function callUpdateRequest(id, data) {
  Logger.log('debug', `[calls.actions] callUpdateRequest(${id}, %j)`, data);
  return {
    type: CALL_UPDATE_REQUEST,
  }
}

export function callUpdateSuccess(data) {
  Logger.log('debug', `[calls.actions] callUpdateSuccess(%j)`, data);
  return {
    type: CALL_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function callUpdateFailure(error) {
  Logger.log('debug', `[calls.actions] callUpdateFailure(%j)`, error);
  return {
    type: CALL_UPDATE_FAILURE,
    error: error
  }
}

export function callDeleteRequest(id) {
  Logger.log('debug', `[calls.actions] callDeleteRequest(${id})`);
  return {
    type: CALL_DELETE_REQUEST,
    id: id
  }
}

export function callDeleteSuccess(id) {
  Logger.log('debug', `[calls.actions] callDeleteSuccess(${id})`);
  return {
    type: CALL_DELETE_SUCCESS,
    id: id,
  }
}

export function callDeleteFailure(error) {
  Logger.log('debug', `[calls.actions] callDeleteFailure(%j)`, error);
  return {
    type: CALL_DELETE_FAILURE,
    error: error
  }
}

export function callFormDestroy(formState=null) {
  Logger.log('debug', `[calls.actions] callFormDestroy(%j)`, formState);
  return {
    type: CALL_FORM_DESTROY,
    form: formState
  }
}

export function callCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.calls.actions] callCSVDownloadRequest()`);
  return {
    type: CALL_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function callCSVDownloadSuccess() {
  Logger.log('debug', `[state.calls.actions] callCSVDownloadSuccess()`);
  return {
    type: CALL_CSV_DOWNLOAD_SUCCESS,
  }
}

export function callCSVDownloadFailure() {
  Logger.log('debug', `[state.calls.actions] callCSVDownloadFailure()`);
  return {
    type: CALL_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadCalls(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[calls.actions] loadCalls(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(callListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCalls(page, limit, order, filter);
    let success = false;

    // get calls list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API calls list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'calls']), [schema.call]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(callListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API calls success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(callListSuccess(data));
      success = true;
      
    // get calls list failure
    } else {
      Logger.log('info', `Get API calls list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(callListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadCall(id, cb=function(){}) {
  Logger.log('debug', `[calls.actions] loadCall(${id}, ###)`);

  return async function(dispatch) {
    dispatch(callReadRequest(id));

    // call API
    const response = await api.getCall(id);
    let success = false;

    // get call success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API call success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'call'])], [schema.call]);
      const data = {
        id: response.getIn(['data', 'call', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(callReadSuccess(data));
      success = true;
      
    // get call failure
    } else {
      Logger.log('info', `Get API call failure. ID: ${id}.`);
      dispatch(callReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updateCall(id, data, cb=function(){}) {
  Logger.log('debug', `[calls.actions] updateCall(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(callUpdateRequest(id, data));

    // call API
    const response = await api.putCall(id, data);
    let success = false;

    // put call success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API call success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'call'])], [schema.call]);
      const data = {
        id: response.getIn(['data', 'call', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(callUpdateSuccess(data));
      success = true;
      
    // get call failure
    } else {
      Logger.log('info', `PUT API call failure. ID: ${id}.`);
      dispatch(callUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deleteCall(id, cb=function(){}) {
  Logger.log('debug', `[calls.actions] deleteCall(${id}, ###)`);

  return async function(dispatch) {
    dispatch(callDeleteRequest(id));

    // call API
    const response = await api.deleteCall(id);
    let success = false;

    // delete call success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API call success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'calls', id: id}));
      dispatch(callDeleteSuccess(id));
      success = true;
      
    // get call failure
    } else {
      Logger.log('info', `DELETE API call failure. ID: ${id}.`);
      dispatch(callDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadCallsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.calls.actions] downloadCallsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(callCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getCallsCSV(order, filter);

    // get calls CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API calls CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-calls-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(callCSVDownloadSuccess());
      
    // get calls CSV failure
    } else {
      Logger.log('info', `Get API calls CSV failure.`);
      dispatch(callCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `calls.actions loaded.`);
