import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { Form, Select } from 'antd';
import { List } from 'immutable';
import { schema } from '../../../../../state/schema';
import store from '../../../../../state/store';
import { denormalize } from 'normalizr';
import useDebounce from '../../../elements/components/useDebounce';

const { Option } = Select;

const UserInput = ({
  form,
  name,
  label,
  isLoading,
  disabled,
  load,
  total,
  rules,
  onChange,
  emptyOption = false,
  style = null,
  loadOnMount = true,
  multiple = false,
  ...props
}) => {
  const [query, setQuery] = useState('');
  const [lastPage, setLastPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [firstDebouncedQuery, setFirstDebouncedQuery] = useState(true);
  const [debouncedTotal, setDebouncedTotal] = useState(0);
  const [useDebouncedTotal, setUseDebouncedTotal] = useState(false);
  const [data, setData] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [result, setResult] = useState(List([]));
  const limit = 10;
  const order = 'id.asc';
  const state = store.getState();
  const debouncedQuery = useDebounce(query, 1000);

  const cleanDebounced = () => {
    setFilter({});
    setPage(lastPage);
    setFirstDebouncedQuery(true);
    setUseDebouncedTotal(false);
    setDebouncedTotal(0);
  };

  useEffect(() => {
    if (debouncedQuery.trim() === '') {
      cleanDebounced();
      return;
    } else {
      if (firstDebouncedQuery) {
        setLastPage(page);
      }
      setUseDebouncedTotal(true);
      setFirstDebouncedQuery(false);
      setPage(1);
      setFilter({ username: debouncedQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  // load sections on mount
  useEffect(() => {
    if (loadOnMount) {
      load(page, limit, order, filter, (success, result) => {
        if (success) {
          setResult(List(result));
        }
      });
    }
  }, [load, loadOnMount, page, limit, filter]);

  const onScroll = async (event) => {
    const target = event.target;

    let totalCurrent = data.length + countFilter;

    if (useDebouncedTotal) {
      totalCurrent = debouncedTotal;
    }

    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      total > totalCurrent
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const options = result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['users', x]),
                schema.user,
                state.entities.toJS()
              ),
            };
          })
          .filter((x) => x.profile_id)
          .toArray()
      : [];
    setCountFilter(countFilter + (result.size - options.length));
    if (useDebouncedTotal) {
      setDebouncedTotal(
        (prevState) =>
          prevState + (result.size - options.length) + options.length
      );
    }
    setData((prevState) => {
      const filteredOptions = options.filter(
        (newOption) =>
          !prevState.some((x) => x.profile_id === newOption.profile_id)
      );
      return prevState.concat(filteredOptions);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    if (firstLoad) {
      const objectId = form.getFieldValue(name);

      if (objectId) {
        const object = objectId
          ? denormalize(
              state.entities.getIn(['userProfiles', objectId]),
              schema.userProfile,
              state.entities.toJS()
            )
          : null;

        setData((prevState) => {
          let existObject = prevState.find((x) => x.profile_id === object.id);
          if (!existObject) {
            prevState.push({ ...object.user, profile_id: object.id });
            if (useDebouncedTotal) {
              setDebouncedTotal((prevState) => prevState + 1);
            }
          }

          return prevState;
        });
        setFirstLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad]);

  return (
    <Translation>
      {(t) => (
        <div className="form-group">
          <Form.Item name={name} label={label} style={style} rules={rules}>
            <Select
              showSearch
              onPopupScroll={onScroll}
              optionFilterProp="children"
              disabled={disabled}
              loading={isLoading}
              mode={multiple ? 'multiple' : null}
              maxTagCount={6}
              onSearch={(value) => setQuery(value)}
              onBlur={() => setQuery('')}
            >
              {emptyOption ? (
                <Option key="empty-option" value={null}>
                  {t('form_input_options_none')}
                </Option>
              ) : null}

              {data.map((x) => (
                <Option key={x.profile_id} value={x.profile_id}>
                  {x.username}
                </Option>
              ))}
              {isLoading && <Option>{t('form_input_loading')}</Option>}
            </Select>
          </Form.Item>
        </div>
      )}
    </Translation>
  );
};

export default UserInput;
