import {connect} from 'react-redux';

import PlannerAccountCategoryAddScreen from '../components/PlannerAccountCategoryAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerAccountCategories.get('isLoading'),
    success: state.plannerAccountCategories.getIn(['form', 'success']),
  }
}

const PlannerAccountCategoryAddScreenContainer = connect(
  mapStateToProps
)(PlannerAccountCategoryAddScreen);

export default PlannerAccountCategoryAddScreenContainer;