import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLANNER_NOTE_LIST_REQUEST = 'PLANNER_NOTE_LIST_REQUEST';
export const PLANNER_NOTE_LIST_SUCCESS = 'PLANNER_NOTE_LIST_SUCCESS';
export const PLANNER_NOTE_LIST_FAILURE = 'PLANNER_NOTE_LIST_FAILURE';
export const PLANNER_NOTE_READ_REQUEST = 'PLANNER_NOTE_READ_REQUEST';
export const PLANNER_NOTE_READ_SUCCESS = 'PLANNER_NOTE_READ_SUCCESS';
export const PLANNER_NOTE_READ_FAILURE = 'PLANNER_NOTE_READ_FAILURE';
export const PLANNER_NOTE_UPDATE_REQUEST = 'PLANNER_NOTE_UPDATE_REQUEST';
export const PLANNER_NOTE_UPDATE_SUCCESS = 'PLANNER_NOTE_UPDATE_SUCCESS';
export const PLANNER_NOTE_UPDATE_FAILURE = 'PLANNER_NOTE_UPDATE_FAILURE';
export const PLANNER_NOTE_CREATE_REQUEST = 'PLANNER_NOTE_CREATE_REQUEST';
export const PLANNER_NOTE_CREATE_SUCCESS = 'PLANNER_NOTE_CREATE_SUCCESS';
export const PLANNER_NOTE_CREATE_FAILURE = 'PLANNER_NOTE_CREATE_FAILURE';
export const PLANNER_NOTE_DELETE_REQUEST = 'PLANNER_NOTE_DELETE_REQUEST';
export const PLANNER_NOTE_DELETE_SUCCESS = 'PLANNER_NOTE_DELETE_SUCCESS';
export const PLANNER_NOTE_DELETE_FAILURE = 'PLANNER_NOTE_DELETE_FAILURE';
export const PLANNER_NOTE_FORM_DESTROY = 'PLANNER_NOTE_FORM_DESTROY';
export const PLANNER_NOTE_CSV_DOWNLOAD_REQUEST = 'PLANNER_NOTE_CSV_DOWNLOAD_REQUEST';
export const PLANNER_NOTE_CSV_DOWNLOAD_SUCCESS = 'PLANNER_NOTE_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_NOTE_CSV_DOWNLOAD_FAILURE = 'PLANNER_NOTE_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerNoteListRequest(page, limit, order, filter) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: PLANNER_NOTE_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function plannerNoteListSuccess(data) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteListSuccess(%j)`, data);
  return {
    type: PLANNER_NOTE_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerNoteListFailure(error) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteListFailure(%j)`, error);
  return {
    type: PLANNER_NOTE_LIST_FAILURE,
    error: error
  }
}

export function plannerNoteReadRequest(id) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteReadRequest(${id})`);
  return {
    type: PLANNER_NOTE_READ_REQUEST,
    id: id
  }
}

export function plannerNoteReadSuccess(data) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteReadSuccess(%j)`, data);
  return {
    type: PLANNER_NOTE_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerNoteReadFailure(error) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteReadFailure(%j)`, error);
  return {
    type: PLANNER_NOTE_READ_FAILURE,
    error: error
  }
}

export function plannerNoteUpdateRequest(id, data) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteUpdateRequest(${id}, %j)`, data);
  return {
    type: PLANNER_NOTE_UPDATE_REQUEST,
  }
}

export function plannerNoteUpdateSuccess(data) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteUpdateSuccess(%j)`, data);
  return {
    type: PLANNER_NOTE_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerNoteUpdateFailure(error) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteUpdateFailure(%j)`, error);
  return {
    type: PLANNER_NOTE_UPDATE_FAILURE,
    error: error
  }
}

export function plannerNoteCreateRequest(data) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteCreateRequest(%j)`, data);
  return {
    type: PLANNER_NOTE_CREATE_REQUEST,
  }
}

export function plannerNoteCreateSuccess(data) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteCreateSuccess(%j)`, data);
  return {
    type: PLANNER_NOTE_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerNoteCreateFailure(error) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteCreateFailure(%j)`, error);
  return {
    type: PLANNER_NOTE_CREATE_FAILURE,
    error: error
  }
}

export function plannerNoteDeleteRequest(id) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteDeleteRequest(${id})`);
  return {
    type: PLANNER_NOTE_DELETE_REQUEST,
    id: id
  }
}

export function plannerNoteDeleteSuccess(id) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteDeleteSuccess(${id})`);
  return {
    type: PLANNER_NOTE_DELETE_SUCCESS,
    id: id,
  }
}

export function plannerNoteDeleteFailure(error) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteDeleteFailure(%j)`, error);
  return {
    type: PLANNER_NOTE_DELETE_FAILURE,
    error: error
  }
}

export function plannerNoteFormDestroy(formState=null) {
  Logger.log('debug', `[plannerNotes.actions] plannerNoteFormDestroy(%j)`, formState);
  return {
    type: PLANNER_NOTE_FORM_DESTROY,
    form: formState
  }
}

export function plannerNoteCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.plannerNotes.actions] plannerNoteCSVDownloadRequest()`);
  return {
    type: PLANNER_NOTE_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function plannerNoteCSVDownloadSuccess() {
  Logger.log('debug', `[state.plannerNotes.actions] plannerNoteCSVDownloadSuccess()`);
  return {
    type: PLANNER_NOTE_CSV_DOWNLOAD_SUCCESS,
  }
}

export function plannerNoteCSVDownloadFailure() {
  Logger.log('debug', `[state.plannerNotes.actions] plannerNoteCSVDownloadFailure()`);
  return {
    type: PLANNER_NOTE_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadPlannerNotes(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[plannerNotes.actions] loadPlannerNotes(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerNoteListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlannerNotes(page, limit, order, filter);
    let success = false;

    // get planner note list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner note list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planner_notes']), [schema.plannerNote]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerNoteListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API planner note success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerNoteListSuccess(data));
      success = true;

    // get planner note list failure
    } else {
      Logger.log('info', `Get API planner note list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(plannerNoteListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadPlannerNote(id, cb=function(){}) {
  Logger.log('debug', `[plannerNotes.actions] loadPlannerNote(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerNoteReadRequest(id));

    // call API
    const response = await api.getPlannerNote(id);
    let success = false;

    // get planner note success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner note success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_note'])], [schema.plannerNote]);
      const data = {
        id: response.getIn(['data', 'planner_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerNoteReadSuccess(data));
      success = true;

    // get planner note failure
    } else {
      Logger.log('info', `Get API planner note failure. ID: ${id}.`);
      dispatch(plannerNoteReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updatePlannerNote(id, data, cb=function(){}) {
  Logger.log('debug', `[plannerNotes.actions] updatePlannerNote(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerNoteUpdateRequest(id, data));

    // call API
    const response = await api.putPlannerNote(id, data);
    let success = false;

    // put planner note success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API planner note success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_note'])], [schema.plannerNote]);
      const data = {
        id: response.getIn(['data', 'planner_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerNoteUpdateSuccess(data));
      success = true;

    // get planner note failure
    } else {
      Logger.log('info', `PUT API planner note failure. ID: ${id}.`);
      dispatch(plannerNoteUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createPlannerNote(data, cb=function(){}) {
  Logger.log('debug', `[plannerNotes.actions] createPlannerNote(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerNoteCreateRequest(data));

    // call API
    const response = await api.postPlannerNotes(data);
    let success = false;

    // post planner note success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API planner note success. Post: ${response.getIn(['data', 'planner_note', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_note'])], [schema.plannerNote]);
      const data = {
        id: response.getIn(['data', 'planner_note', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerNoteCreateSuccess(data));
      success = true;

    // get planner note failure
    } else {
      Logger.log('info', `POST API planner note failure.`);
      dispatch(plannerNoteCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deletePlannerNote(id, cb=function(){}) {
  Logger.log('debug', `[plannerNotes.actions] deletePlannerNote(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerNoteDeleteRequest(id));

    // call API
    const response = await api.deletePlannerNote(id);
    let success = false;

    // delete planner note success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API planner note success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'planner_notes', id: id}));
      dispatch(plannerNoteDeleteSuccess(id));
      success = true;

    // get planner note failure
    } else {
      Logger.log('info', `DELETE API planner note failure. ID: ${id}.`);
      dispatch(plannerNoteDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function plannerNotesCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.plannerNotes.actions] plannerNotesCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(plannerNoteCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlannerNotesCSV(order, filter);

    // get planner note CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner note CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-planner-notes-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(plannerNoteCSVDownloadSuccess());

    // get planner note CSV failure
    } else {
      Logger.log('info', `Get API planner note CSV failure.`);
      dispatch(plannerNoteCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `plannerNotes.actions loaded.`);