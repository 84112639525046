import {connect} from 'react-redux';

import PlannerDocumentEditScreen from '../components/PlannerDocumentEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerDocuments.get('isLoading'),
    success: state.plannerDocuments.getIn(['form', 'success']),
  }
}

const PlannerDocumentEditScreenContainer = connect(
  mapStateToProps
)(PlannerDocumentEditScreen);

export default PlannerDocumentEditScreenContainer;
