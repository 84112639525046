import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities} from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLANNER_ANSWER_LIST_REQUEST = 'PLANNER_ANSWER_LIST_REQUEST';
export const PLANNER_ANSWER_LIST_SUCCESS = 'PLANNER_ANSWER_LIST_SUCCESS';
export const PLANNER_ANSWER_LIST_FAILURE = 'PLANNER_ANSWER_LIST_FAILURE';
export const PLANNER_ANSWER_READ_REQUEST = 'PLANNER_ANSWER_READ_REQUEST';
export const PLANNER_ANSWER_READ_SUCCESS = 'PLANNER_ANSWER_READ_SUCCESS';
export const PLANNER_ANSWER_READ_FAILURE = 'PLANNER_ANSWER_READ_FAILURE';
export const PLANNER_ANSWER_CSV_DOWNLOAD_REQUEST = 'PLANNER_ANSWER_CSV_DOWNLOAD_REQUEST';
export const PLANNER_ANSWER_CSV_DOWNLOAD_SUCCESS = 'PLANNER_ANSWER_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_ANSWER_CSV_DOWNLOAD_FAILURE = 'PLANNER_ANSWER_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerAnswerListRequest(page, limit, order, filter) {
  Logger.log('debug', `[plannerAnswers.actions] plannerAnswerListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: PLANNER_ANSWER_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function plannerAnswerListSuccess(data) {
  Logger.log('debug', `[plannerAnswers.actions] plannerAnswerListSuccess(%j)`, data);
  return {
    type: PLANNER_ANSWER_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerAnswerListFailure(error) {
  Logger.log('debug', `[plannerAnswers.actions] plannerAnswerListFailure(%j)`, error);
  return {
    type: PLANNER_ANSWER_LIST_FAILURE,
    error: error
  }
}

export function plannerAnswerReadRequest(id) {
  Logger.log('debug', `[plannerAnswers.actions] plannerAnswerReadRequest(${id})`);
  return {
    type: PLANNER_ANSWER_READ_REQUEST,
    id: id
  }
}

export function plannerAnswerReadSuccess(data) {
  Logger.log('debug', `[plannerAnswers.actions] plannerAnswerReadSuccess(%j)`, data);
  return {
    type: PLANNER_ANSWER_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerAnswerReadFailure(error) {
  Logger.log('debug', `[plannerAnswers.actions] plannerAnswerReadFailure(%j)`, error);
  return {
    type: PLANNER_ANSWER_READ_FAILURE,
    error: error
  }
}


export function plannerAnswerCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.plannerAnswers.actions] plannerAnswerCSVDownloadRequest()`);
  return {
    type: PLANNER_ANSWER_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function plannerAnswerCSVDownloadSuccess() {
  Logger.log('debug', `[state.plannerAnswers.actions] plannerAnswerCSVDownloadSuccess()`);
  return {
    type: PLANNER_ANSWER_CSV_DOWNLOAD_SUCCESS,
  }
}

export function plannerAnswerCSVDownloadFailure() {
  Logger.log('debug', `[state.plannerAnswers.actions] plannerAnswerCSVDownloadFailure()`);
  return {
    type: PLANNER_ANSWER_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadPlannerAnswers(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[plannerAnswers.actions] loadPlannerAnswers(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerAnswerListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlannerAnswers(page, limit, order, filter);
    let success = false;

    // get planner answers list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner answers list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planner_answers']), [schema.plannerAnswer]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerAnswerListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API planner answers success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerAnswerListSuccess(data));
      success = true;
      
    // get planner answers list failure
    } else {
      Logger.log('info', `Get API planner answers list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(plannerAnswerListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadPlannerAnswer(id, cb=function(){}) {
  Logger.log('debug', `[plannerAnswers.actions] loadPlannerAnswer(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerAnswerReadRequest(id));

    // call API
    const response = await api.getPlannerAnswer(id);
    let success = false;

    // get planner answer success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner answer success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_answer'])], [schema.plannerAnswer]);
      const data = {
        id: response.getIn(['data', 'planner_answer', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerAnswerReadSuccess(data));
      success = true;
      
    // get planner answer failure
    } else {
      Logger.log('info', `Get API planner answer failure. ID: ${id}.`);
      dispatch(plannerAnswerReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `plannerAnswers.actions loaded.`);
