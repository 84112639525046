import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadCall,
  updateCall,
  deleteCall,
  callFormDestroy
} from '../../../../../state/modules/calls/actions';
import CallForm from '../components/CallForm';
import Config from '../../../../../Config';

const inputs = List([
  'service_id',
  'priority',
  'call_status',
  'total_count',
  'total_sent_count',
  'total_delivered_count',
  'total_delivery_failed_count',
  'attempts',
  'queued_at',
  'last_attempt_at',
  'recipients',
  'body'
]);


const mapStateToProps = (state, ownProps) => {

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['calls', ownProps.id, val], '');
      errors[val] = state.calls.getIn(['form', 'errors', val]);
  }
  data['last_attempt_at'] = data['last_attempt_at']
    ? moment(data['last_attempt_at'], Config.get('API_DATETIME_FORMAT'))
    : null;
  
  data['queued_at'] = data['queued_at']
    ? moment(data['queued_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  return {
    isLoading: state.calls.get('isLoading'),
    isSubmitting: state.calls.getIn(['form', 'isSubmitting']),
    success: state.calls.getIn(['form', 'success']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['calls', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['calls', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadCall(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateCall(id, data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteCall(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(callFormDestroy(formState));
    },
  }
}

const CallFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallForm);

export default CallFormContainer;
