import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannerToDosSuggestedScreen from './containers/PlannerToDosSuggestedScreenContainer';
import PlannerToDoSuggestedAddScreen from './containers/PlannerToDoSuggestedAddScreenContainer';
import PlannerToDoSuggestedEditScreen from './containers/PlannerToDoSuggestedEditScreenContainer';

const register = () => {
  Logger.log('debug', `plannerToDosSuggested.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'plannerToDosSuggested', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerToDoSuggestedAddScreen': ['PrivateRoute', '/planner-to-dos-suggested/add', true, PlannerToDoSuggestedAddScreen, getI18n().t('planner_to_do_suggested_route_add')],
        'PlannerToDoSuggestedEditScreen': ['PrivateRoute', '/planner-to-dos-suggested/edit/:id([\\w\\-]+)', true, PlannerToDoSuggestedEditScreen, getI18n().t('planner_to_do_suggested_route_edit')],
        'PlannerToDosSuggestedScreen': ['PrivateRoute', '/planner-to-dos-suggested/:page(\\d+)?', true, PlannerToDosSuggestedScreen, getI18n().t('planner_to_dos_suggested_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `plannerToDosSuggested.register() loaded.`);