import {connect} from 'react-redux';

import PlannerBanksScreen from '../components/PlannerBanksScreen';

const mapStateToProps = state => {
  return {
    total: state.plannerBanks.get('total'),
    isLoading: state.plannerBanks.get('isLoading')
  }
}

const PlannerBanksScreenContainer = connect(
  mapStateToProps
)(PlannerBanksScreen);

export default PlannerBanksScreenContainer;