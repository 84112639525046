import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannerDocuments, deletePlannerDocument} from '../../../../../state/modules/plannerDocuments/actions';
import PlannerDocumentsList from '../components/PlannerDocumentsList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.plannerDocuments.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['plannerDocuments', x]),
              schema.plannerDocument,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {

          // flatten some nested objects to make table easier to work with
          return {
            profile_id: x.profile ? x.profile.id : '',
            owner_username:  x.planner && x.planner.owner.profile && x.planner.owner.profile.user ? x.planner.owner.profile.user.username : '',
            profile_username: x.profile && x.profile.user ? x.profile.user.username : '',
            ...x
          };
        })
      : [],
    isLoading: state.plannerDocuments.get('isLoading'),
    total: state.plannerDocuments.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlannerDocuments(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePlannerDocument(id, cb));
    },
  }
}

const PlannerDocumentsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerDocumentsList);

export default PlannerDocumentsListContainer;
