import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import {Translation, getI18n} from 'react-i18next';
import {Table} from 'antd';

import ListActions from '../../../elements/components/ListActions';
import {getColumnSearchProps, getColumnDateRangeProps} from '../../../elements/components/TableColumnFilters';
import {PriorityTagCall, CallStatusTag} from '../../../elements/components/Tags';
import {pathTo} from '../../../Routes';
import Format from '../../../../../lib/Format';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

const CallsList = ({component, page, limit, order, filter, total, load, remove, history, ...props}) => {

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    delete filter[dataIndex];
    history.push(QueryString.append(props.location.pathname, {order, ...filter}));
  };

  const columns = [
    {
      title: getI18n().t('table_header_id'),
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: order === 'id.asc' ? 'ascend' : (order === 'id.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnSearchProps('id', handleSearch, handleReset, getI18n().t('table_header_id'), filter),
    },
    {
      title: getI18n().t('calls_total_count'),
      dataIndex: 'total_count',
      key: 'total_count',
      defaultSortOrder: order === 'total_count.asc' ? 'ascend' : (order === 'total_count.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnSearchProps('total_count', handleSearch, handleReset, getI18n().t('calls_total_count'), filter),
    },
    {
      title: getI18n().t('calls_total_sent_count'),
      dataIndex: 'total_sent_count',
      key: 'total_sent_count',
      defaultSortOrder: order === 'total_sent_count.asc' ? 'ascend' : (order === 'total_sent_count.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnSearchProps('total_sent_count', handleSearch, handleReset, getI18n().t('calls_total_sent_count'), filter),
    },
    {
      title: getI18n().t('calls_total_delivered_count'),
      dataIndex: 'total_delivered_count',
      key: 'total_delivered_count',
      defaultSortOrder: order === 'total_delivered_count.asc' ? 'ascend' : (order === 'total_delivered_count.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnSearchProps('total_delivered_count', handleSearch, handleReset, getI18n().t('calls_total_delivered_count'), filter),
    },
    {
      title: getI18n().t('calls_total_delivery_failed_count'),
      dataIndex: 'total_delivery_failed_count',
      key: 'total_delivery_failed_count',
      defaultSortOrder: order === 'total_delivery_failed_count.asc' ? 'ascend' : (order === 'total_delivery_failed_count.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnSearchProps('total_delivery_failed_count', handleSearch, handleReset, getI18n().t('calls_total_delivery_failed_count'), filter),
    },
    {
      title: getI18n().t('calls_attempts'),
      dataIndex: 'attempts',
      key: 'attempts',
      defaultSortOrder: order === 'attempts.asc' ? 'ascend' : (order === 'attempts.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnSearchProps('attempts', handleSearch, handleReset, getI18n().t('calls_attempts'), filter),
    },
    {
      title: getI18n().t('calls_priority'),
      dataIndex: 'priority',
      key: 'priority',
      filters: [
        {text: getI18n().t('call_priority_now'), value: 0},
        {text: getI18n().t('call_priority_high'), value: 1},
        {text: getI18n().t('call_priority_medium'), value: 2},
        {text: getI18n().t('call_priority_low'), value: 2},
      ],
      filteredValue: 'priority' in filter ? filter['priority'].split(',') : null,
      render: priority => <PriorityTagCall priority={priority} />
    },
    {
      title: getI18n().t('calls_call_status'),
      dataIndex: 'call_status',
      key: 'call_status',
      filters: [
        {text: getI18n().t('call_call_status_queued'), value: 1},
        {text: getI18n().t('call_call_status_processing'), value: 2},
        {text: getI18n().t('call_call_status_complete'), value: 3},
        {text: getI18n().t('call_call_status_failed'), value: 4},
      ],
      filteredValue: 'call_status' in filter ? filter['call_status'].split(',') : null,
      render: call_status => <CallStatusTag call_status={call_status} />
    },
    {
      title: getI18n().t('calls_recipients'),
      dataIndex: 'recipients',
      key: 'recipients',
      render: recipients => (recipients && Array.isArray(recipients) ? recipients.join(', ') : ''),
    },
    {
      title: getI18n().t('calls_last_attempt_at'),
      dataIndex: 'last_attempt_at',
      key: 'last_attempt_at',
      render: date => Format.date(date),
      defaultSortOrder: order === 'last_attempt_at.asc' ? 'ascend' : (order === 'last_attempt_at.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnDateRangeProps('last_attempt_at', handleSearch, handleReset, filter),
    },
    {
      title: getI18n().t('calls_queued_at'),
      dataIndex: 'queued_at',
      key: 'queued_at',
      render: date => Format.date(date),
      defaultSortOrder: order === 'queued_at.asc' ? 'ascend' : (order === 'queued_at.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnDateRangeProps('queued_at', handleSearch, handleReset, filter),
    },
    {
      title: getI18n().t('table_header_updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: date => Format.date(date),
      defaultSortOrder: order === 'updated_at.asc' ? 'ascend' : (order === 'updated_at.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnDateRangeProps('updated_at', handleSearch, handleReset, filter),
    },
    {
      title: getI18n().t('table_header_created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['xl'],
      render: date => Format.date(date),
      defaultSortOrder: order === 'created_at.asc' ? 'ascend' : (order === 'created_at.desc' ? 'descend' : null),
      sorter: true,
      ...getColumnDateRangeProps('created_at', handleSearch, handleReset, filter),
    },
    {
      title: getI18n().t('table_header_actions'),
      dataIndex: 'id',
      key: 'actions',
      render: id =>
        <ListActions
          id={id}
          page={page}
          limit={limit}
          order={order}
          filter={filter}
          load={load.bind(this)}
          remove={remove.bind(this)}
          editScreen="CallEditScreen"
        />,
    },
  ];

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
    position: ['bottomCenter'],
  }

  const handleTableChange = (pagination, filters, sorter) => {

    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, {page: pagination['current']});
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const order = sorter['field'] + '.' + (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = order;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    history.push(QueryString.append(path, params));
  }

  const filterString = JSON.stringify(filter);
  useEffect(() => {
    load(page, limit, order, JSON.parse(filterString));
  }, [page, limit, order, filterString, load]);

  return (
    <Translation>{(t) => 
      <Table
        dataSource={props.list}
        columns={columns}
        pagination={page === 1 && total < limit  ? false : pagination}
        loading={props.isLoading}
        scroll={{x: true}}
        onChange={handleTableChange}
      />

    }</Translation>
  );
}

export default withRouter(CallsList);

Logger.log('silly', `CallsList loaded.`);
