import {connect} from 'react-redux';

import PlannerToDosUserScreen from '../components/PlannerToDosUserScreen';

const mapStateToProps = state => {
  return {
    total: state.plannerToDosUser.get('total'),
    isLoading: state.plannerToDosUser.get('isLoading')
  }
}

const PlannerToDosUserScreenContainer = connect(
  mapStateToProps
)(PlannerToDosUserScreen);

export default PlannerToDosUserScreenContainer;
