import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Translation, getI18n } from "react-i18next";
import {
  Affix,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Spin,
} from "antd";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";

import message from "../../../elements/lib/MessageWrapper";
import FormMetadataPlanner from "./FormMetadataPlanner";
import { pathTo } from "../../../Routes";
import Format from "../../../../../lib/Format";
import Logger from "../../../../../lib/Logger";
import Config from "../../../../../Config";
import StateInput from "../components/StateInput";
import StepInput from "../components/StepInput";

const PlannerModuleForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  payloadValues,
  ...props
}) => {

  const [redirectTo, setRedirectTo] = useState(null);

  const [form] = Form.useForm();

  const [plannerPoaId, setPlannerPoaId] = useState(null);
  const [plannerFinancialId, setPlannerFinancialId] = useState(null);
  const [plannerLegalId, setPlannerLegalId] = useState(null);
  const [plannerBudgetId, setPlannerBudgetId] = useState(null);

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if ((id) && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
    
      for (var input of payloadValues) {
      
        dataObj[`${input}_state_at`] = dataObj[`${input}_state_at`]
        ? moment(dataObj[`${input}_state_at`], Config.get("API_DATETIME_FORMAT"))
        : null;
        dataObj[`${input}_step_1_at`] = dataObj[`${input}_step_1_at`]
        ? moment(dataObj[`${input}_step_1_at`], Config.get("API_DATETIME_FORMAT"))
        : null;
      dataObj[`${input}_step_2_at`] = dataObj[`${input}_step_2_at`]
        ? moment(dataObj[`${input}_step_2_at`], Config.get("API_DATETIME_FORMAT"))
        : null;
      dataObj[`${input}_step_3_at`] = dataObj[`${input}_step_3_at`]
        ? moment(dataObj[`${input}_step_3_at`], Config.get("API_DATETIME_FORMAT"))
        : null;
      dataObj[`${input}_step_4_at`] = dataObj[`${input}_step_4_at`]
        ? moment(dataObj[`${input}_step_4_at`], Config.get("API_DATETIME_FORMAT"))
        : null;
      dataObj[`${input}_step_5_at`] = dataObj[`${input}_step_5_at`]
        ? moment(dataObj[`${input}_step_5_at`], Config.get("API_DATETIME_FORMAT"))
        : null;
      }

      setPlannerPoaId(dataObj["planner_poa_id"]);
      setPlannerLegalId(dataObj["planner_legal_id"]);
      setPlannerFinancialId(dataObj["planner_financial_id"]);
      setPlannerBudgetId(dataObj["planner_budget_id"]);


      form.setFieldsValue(dataObj);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = "";
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === "") {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo("PlannerModuleEditScreen", { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);


  // submit data handler
  const submitData = async (values) => {
    Logger.log("debug", `PlannerModuleForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};

    for (var input of payloadValues) {

      payload[input] = {}

      if (`${input}_id` in values) {
        payload[input]['id'] = values[`${input}_id`];
      }
      if (`${input}_state` in values) {
        payload[input]['state'] = values[`${input}_state`];
      }
      if(`${input}_state_at` in values) {
        payload[input]['state_at'] = Format.date(
          values[`${input}_state_at`],
          Config.get("API_DATETIME_FORMAT")
        );
      }
      if(`${input}_step_1_at` in values) {
        payload[input]['step_1_at'] = Format.date(
          values[`${input}_step_1_at`],
          Config.get("API_DATETIME_FORMAT")
        );
      }
      if(`${input}_step_2_at` in values) {
        payload[input]['step_2_at'] = Format.date(
          values[`${input}_step_2_at`],
          Config.get("API_DATETIME_FORMAT")
        );
      }
      if(`${input}_step_3_at` in values) {
        payload[input]['step_3_at'] = Format.date(
          values[`${input}_step_3_at`],
          Config.get("API_DATETIME_FORMAT")
        );
      }
      if(`${input}_step_4_at` in values) {
        payload[input]['step_4_at'] = Format.date(
          values[`${input}_step_4_at`],
          Config.get("API_DATETIME_FORMAT")
        );
      }
      if(`${input}_step_5_at` in values) {
        payload[input]['step_5_at'] = Format.date(
          values[`${input}_step_5_at`],
          Config.get("API_DATETIME_FORMAT")
        );
      }
      if(`${input}_step_1` in values) {
        payload[input]['step_1'] = values[`${input}_step_1`];
      }
      if(`${input}_step_2` in values) {
        payload[input]['step_2'] = values[`${input}_step_2`];
      }
      if(`${input}_step_3` in values) {
        payload[input]['step_3'] = values[`${input}_step_3`];
      }
      if(`${input}_step_4` in values) {
        payload[input]['step_4'] = values[`${input}_step_4`];
      }
      if(`${input}_step_5` in values) {
        payload[input]['step_5'] = values[`${input}_step_5`];
      }
    }

    payload['id'] = values['id'];

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t("feedback_form_success"));
        } else {
          message.error(getI18n().t("feedback_form_error"));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t("feedback_form_success"));
        } else {
          message.error(getI18n().t("feedback_form_error"));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log("debug", `PlannerModuleForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log("debug", `PlannerModuleForm.handleFinishFailed(###)`);
    message.error(getI18n().t("feedback_form_error"));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }


  return (
    <Translation>
      {(t) => (
        <>
          <div className="planner-module-form">
            <Form
              name="planner-module_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={14}>
                <Col xs={24} lg={16}>
                  <Card
                    title={
                      id
                        ? t("planner_module_poa_edit_title")
                        : t("planner_module_poa_add_title")
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >

                    {id ? (
                      <div className="form-group">
                        <Form.Item name="planner_poa_id" label={t("planner_module_poa_id")}>
                          <span className="ant-form-text">{plannerPoaId}</span>
                        </Form.Item>
                      </div>
                    ) :
                      null
                    }

                    <StateInput
                      name="planner_poa_state"
                      label={t('planner_module_state')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_poa_state_at"
                        label={t("planner_module_state_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_poa_step_1"
                      label={t('planner_module_step_1')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_poa_step_1_at"
                        label={t("planner_module_step_1_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_poa_step_2"
                      label={t('planner_module_step_2')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_poa_step_2_at"
                        label={t("planner_module_step_2_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_poa_step_3"
                      label={t('planner_module_step_3')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_poa_step_3_at"
                        label={t("planner_module_step_3_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_poa_step_4"
                      label={t('planner_module_step_4')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_poa_step_4_at"
                        label={t("planner_module_step_4_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_poa_step_5"
                      label={t('planner_module_step_5')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_poa_step_5_at"
                        label={t("planner_module_step_5_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                  </Card>

                  <Card
                    title={
                      id
                        ? t("planner_module_financial_edit_title")
                        : t("planner_module_financial_add_title")
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >


                    {id ? (
                      <div className="form-group">
                        <Form.Item name="planner_financial_id" label={t("planner_module_financial_id")}>
                          <span className="ant-form-text">{plannerFinancialId}</span>
                        </Form.Item>
                      </div>
                    ) :
                      null
                    }

                    <StateInput
                      name="planner_financial_state"
                      label={t('planner_module_state')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_financial_state_at"
                        label={t("planner_module_state_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_financial_step_1"
                      label={t('planner_module_step_1')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_financial_step_1_at"
                        label={t("planner_module_step_1_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_financial_step_2"
                      label={t('planner_module_step_2')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_financial_step_2_at"
                        label={t("planner_module_step_2_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_financial_step_3"
                      label={t('planner_module_step_3')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_financial_step_3_at"
                        label={t("planner_module_step_3_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>


                    <StepInput
                      name="planner_financial_step_4"
                      label={t('planner_module_step_4')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_financial_step_4_at"
                        label={t("planner_module_step_4_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                  </Card>

                  <Card
                    title={
                      id
                        ? t("planner_module_budget_edit_title")
                        : t("planner_module_budget_add_title")
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >

                    {id ? (
                      <div className="form-group">
                        <Form.Item name="planner_budget_id" label={t("planner_module_budget_id")}>
                          <span className="ant-form-text">{plannerBudgetId}</span>
                        </Form.Item>
                      </div>
                    ) :
                      null
                    }

                    <StateInput
                      name="planner_budget_state"
                      label={t('planner_module_state')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_budget_state_at"
                        label={t("planner_module_state_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_budget_step_1"
                      label={t('planner_module_step_1')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_budget_step_1_at"
                        label={t("planner_module_step_1_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_budget_step_2"
                      label={t('planner_module_step_2')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_budget_step_2_at"
                        label={t("planner_module_step_2_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_budget_step_3"
                      label={t('planner_module_step_3')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_budget_step_3_at"
                        label={t("planner_module_step_3_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_budget_step_4"
                      label={t('planner_module_step_4')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_budget_step_4_at"
                        label={t("planner_module_step_4_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                  </Card>

                  <Card
                    title={
                      id
                        ? t("planner_module_legal_edit_title")
                        : t("planner_module_legal_add_title")
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >

                    {id ? (
                      <div className="form-group">
                        <Form.Item name="planner_legal_id" label={t("planner_module_legal_id")}>
                          <span className="ant-form-text">{plannerLegalId}</span>
                        </Form.Item>
                      </div>
                    ) :
                      null
                    }

                    <StateInput
                      name="planner_legal_state"
                      label={t('planner_module_state')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_legal_state_at"
                        label={t("planner_module_state_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_legal_step_1"
                      label={t('planner_module_step_1')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_legal_step_1_at"
                        label={t("planner_module_step_1_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_legal_step_2"
                      label={t('planner_module_step_2')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_legal_step_2_at"
                        label={t("planner_module_step_2_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_legal_step_3"
                      label={t('planner_module_step_3')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_legal_step_3_at"
                        label={t("planner_module_step_3_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>

                    <StepInput
                      name="planner_legal_step_4"
                      label={t('planner_module_step_4')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="planner_legal_step_4_at"
                        label={t("planner_module_step_4_at")}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get("DEFAULT_DATETIME_FORMAT")}
                        />
                      </Form.Item>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} lg={8}>
                  <Affix offsetTop={10}>
                    <Card title={t("form_metadata_header")}>
                      <FormMetadataPlanner
                        id={id}
                        isSubmitting={isSubmitting}
                        isLoading={isLoading} />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default PlannerModuleForm;

Logger.log("silly", `PlannerModuleForm loaded.`);
