import {connect} from 'react-redux';

import {downloadPlannersCSV} from '../../../../../state/modules/planners/actions';
import PlannersCSVButton from '../components/PlannersCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.planners.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPlannersCSV(order, filter, cb));
    }
  }
}

const PlannersCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannersCSVButton);

export default PlannersCSVButtonContainer;
