import {connect} from 'react-redux';

import PlannerToDoSuggestedEditScreen from '../components/PlannerToDoSuggestedEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerToDosSuggested.get('isLoading'),
    success: state.plannerToDosSuggested.getIn(['form', 'success']),
  }
}

const PlannerToDoSuggestedEditScreenContainer = connect(
  mapStateToProps
)(PlannerToDoSuggestedEditScreen);

export default PlannerToDoSuggestedEditScreenContainer;
