import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannersEntry} from '../../../../../state/modules/planners/actions';
import PlannerInput from '../components/PlannerInput';

const mapStateToProps = (state, ownProps) => {

  const order = 'created_at.desc';

  const result = state.planners.getIn(['entries', order]);

  const planners = result
    ? result.map(x => {
        return {
          key: x,
          ...denormalize(
            state.entities.getIn(['plannerEntries', x]),
            schema.plannerEntry,
            state.entities.toJS()
          )
        };
      }).toArray()
    : [];

    return {
    options: planners.map(x => {
      return {label: x && x?.owner && x?.owner?.profile && x?.owner?.profile?.user? x.owner.profile.user.username  : '', value: x ? x.id : ''};
    }),
    isLoading: state.planners.get('areEntriesLoading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlannersEntry(page, limit, order, filter, cb));
    },
  }
}

const PlannerInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerInput);

export default PlannerInputContainer;
