import {connect} from 'react-redux';

import CallEditScreen from '../components/CallEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.calls.get('isLoading'),
    success: state.calls.getIn(['form', 'success']),
  }
}

const CallEditScreenContainer = connect(
  mapStateToProps
)(CallEditScreen);

export default CallEditScreenContainer;
