import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import {
  Affix,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Spin,
} from 'antd';
import { GlobalOutlined, LoadingOutlined } from '@ant-design/icons';

import TagInput from '../../resourceTags/containers/TagInputContainer';
import ServiceTagInput from '../../resourceServiceTags/containers/ServiceTagInputContainer';
import CountryInput from '../../locations/country/containers/CountryInputContainer';
import RegionInput from '../../locations/region/containers/RegionInputContainer';
import SubregionInput from '../../locations/subregion/containers/SubRegionInputContainer';
import CityInput from '../../locations/city/containers/CityInputContainer';
import PostalCodeInput from '../../locations/postalCode/containers/PostalCodeInputContainer';
import BodyInput from './BodyInput';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';
import CareGuidePartnershipInput from '../../careGuides/containers/CareGuidePartnershipInputContainer';

import MediaInputPartnership from '../../media/containers/MediaInputPartnershipContainer';
import AdminTagInput from '../../adminPartnerClasses/containers/TagInputContainer';
import PartnershipInput from '../../partnerships/containers/PartnershipInputContainer';
import CareGuideInput from '../../careGuides/containers/CareGuideInputContainer';
import UserCareGuidesInput from '../../users/containers/UserCareGuidesInputContainer';
import UserPartnerPartnershipInput from '../../users/containers/UserPartnerPartnershipInputContainer';

const ResourceForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  ...props
}) => {
  const [classes, setClasses] = useState([]);
  const [redirectTo, setRedirectTo] = useState(null);
  const [careGuideIds, setCareGuideIds] = useState(data['care_guides']);

  const [isUniversal, setIsUniversal] = useState(data['is_universal']);
  const [isEventPartner, setEventPartner] = useState(
    data['partnership'] ? true : false
  );
  const [isMultipleCareGuide, setIsMultipleCareGuide] = useState(false);
  const [partnershipId, setPartnershipId] = useState(data['partnership']);
  const [isPinned, setIsPinned] = useState(data['is_pinned']);
  const [countryId, setCountryId] = useState(data['country']);
  const [regionId, setRegionId] = useState(data['region']);
  const [cityId, setCityId] = useState(data['city']);
  const [countryIds, setCountryIds] = useState(
    data['countries'] ? data['countries'].join(',') : null
  );
  const [regionsIds, setRegionIds] = useState(
    data['regions'] ? data['regions'].join(',') : null
  );
  const [subregionIds, setSubregionIds] = useState(
    data['subregions'] ? data['subregions'].join(',') : null
  );
  const [cityIds, setCityIds] = useState(
    data['cities'] ? data['cities'].join(',') : null
  );
  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);

  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      setIsUniversal(dataObj['is_universal']);
      setIsPinned(dataObj['is_pinned']);
      setCountryId(dataObj['country']);
      setRegionId(dataObj['region']);
      setCityId(dataObj['city']);
      setCountryIds(
        dataObj['countries'] ? dataObj['countries'].join(',') : null
      );
      setRegionIds(dataObj['regions'] ? dataObj['regions'].join(',') : null);
      setSubregionIds(
        dataObj['subregions'] ? dataObj['subregions'].join(',') : null
      );
      setCityIds(dataObj['cities'] ? dataObj['cities'].join(',') : null);
      setCareGuideIds(dataObj['care_guides']);
      setPartnershipId(dataObj['partnership']);

      if (dataObj['partnership']) {
        setEventPartner(true);
        if (dataObj['care_guides'].length > 0) {
          setIsMultipleCareGuide(true);
        }
      }

      form.setFieldsValue({
        ...dataObj,
        ...data.resources_care_partner_tags.reduce((acc, item) => {
          const key = item.admin_partner_class.name;
          const value = item.id;
          acc[key] = value;
          return acc;
        }, {}),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('ResourceEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `ResourceForm.submitData(###)`);
    // API POST/PUT payload
    let payload = {};
    let resources_care_partner_tags = [];

    for (const input of Object.keys(data)) {
      // transform boolean values
      if (['is_universal', 'is_pinned'].includes(input)) {
        payload[input] = values[input] ? true : false;
      } else if (['is_planner'].includes(input)) {
        payload[input] = false;
      }

      // transform profile refs to UUIDs
      else if (['profile'].includes(input)) {
        if (!id) {
          payload['user_profile_id'] = values[input];
        }
      }

      // transform profile refs to UUIDs
      else if (['care_guides'].includes(input)) {
        if (!isUniversal) {
          if (isEventPartner) {
            if (isMultipleCareGuide) {
              payload['care_guide_ids'] = careGuideIds;
            }
          } else {
            payload['care_guide_ids'] = [values[input]];
          }
        }
      } else if (['care_guide'].includes(input)) {
        payload['care_guide_ids'] = careGuideIds;
      } else if (['partnership'].includes(input)) {
        if (!isUniversal) {
          if (isEventPartner) {
            payload[input + '_id'] = values[input];
          }
        }
      } else if (['logo'].includes(input)) {
        if (isEventPartner && values[input] !== '') {
          payload[input + '_id'] = values[input];
        }
      }

      // transform entity refs to integer IDs
      else if (['country', 'region', 'city', 'postal_code'].includes(input)) {
        payload[input + '_id'] = parseInt(values[input]);
      }

      // transform multiple country refs to IDs
      else if (['countries'].includes(input)) {
        payload['country_ids'] = values[input]
          ? values[input].map((x) => parseInt(x))
          : [];
      }

      // transform multiple region refs to IDs
      else if (['regions'].includes(input)) {
        payload['region_ids'] = values[input]
          ? values[input].map((x) => parseInt(x))
          : [];
      }

      // transform multiple subregion refs to IDs
      else if (['subregions'].includes(input)) {
        payload['subregion_ids'] = values[input]
          ? values[input].map((x) => parseInt(x))
          : [];
      }

      // transform multiple city refs to IDs
      else if (['cities'].includes(input)) {
        payload['city_ids'] = values[input]
          ? values[input].map((x) => parseInt(x))
          : [];
      }

      // transform multiple postal code refs to IDs
      else if (['postal_codes'].includes(input)) {
        payload['postal_code_ids'] = values[input]
          ? values[input].map((x) => parseInt(x))
          : [];
      }

      // transform lat/lng into geo point
      else if (['latitude', 'longitude'].includes(input)) {
        if (!('geo' in payload)) {
          payload['geo'] = {};
        }
        if (values[input]) {
          payload['geo'][input] = values[input];
        }
      }

      // handle optional inputs
      else if (
        ['telephone', 'website', 'email', 'postal_code'].includes(input)
      ) {
        if (values[input]) {
          payload[input] = values[input];
        }
      } else if (['description'].includes(input)) {
        payload[input] = values[input] ? values[input] : '';
      } else if (input in values) {
        payload[input] = values[input];
      }
    }

    for (const input of classes) {
      if (values[input] && values[input] !== '') {
        resources_care_partner_tags.push(values[input]);
      }
    }

    delete payload['resources_care_partner_tags'];
    payload['resources_care_partner_tags_list'] = resources_care_partner_tags;

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  const onChangeIsUniversalHandler = (e) => {
    setEventPartner(false);
    setIsUniversal(e.target.checked);
  };

  const onChangeIsEventPartnerHandler = (e) => {
    setIsMultipleCareGuide(false);
    setEventPartner(e.target.checked);
  };

  const onChangeIsPinnedHandler = (e) => {
    setIsPinned(e.target.checked);
  };

  const onChangePartnership = (e) => {
    setPartnershipId(e);
  };

  const onClickGeocodeHandler = (e) => {
    console.log('Feature removed temporarily.');
    // const country = form.getFieldValue('country');
    // const region = form.getFieldValue('region');
    // const city = form.getFieldValue('city');
    // const postalCode = form.getFieldValue('postal_code');
    // const address1 = form.getFieldValue('address1');
    // const address2 = form.getFieldValue('address2');
    // const location = `${address1} ${address2}, ${city}, ${region}, ${country}, ${postalCode}`;
    // if (location) {
    //   props.getGeocode(location, (success, data) => {
    //     if (success) {
    //       form.setFieldsValue({
    //         latitude: data.latitude,
    //         longitude: data.longitude,
    //       });
    //     }
    //   });
    // }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `ResourceForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `ResourceForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const handleChange = (value) => {
    setCareGuideIds(value);
    return value;
  };

  const onChangeCareGuide = (e) => {
    setCareGuideIds([e]);
    return e;
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div className="resource-form">
            <Form
              name="resource_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id ? t('resources_edit_title') : t('resources_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <Form.Item name="is_planner" style={{ display: 'none' }}>
                      <Input type="hidden" />
                    </Form.Item>

                    <div className="form-group">
                      <Form.Item
                        name="is_universal"
                        label={t('resources_is_universal')}
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={onChangeIsUniversalHandler}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <Form.Item
                        name="is_pinned"
                        label={t('resources_is_pinned')}
                        valuePropName="checked"
                      >
                        <Checkbox
                          value={isPinned}
                          onChange={onChangeIsPinnedHandler}
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Item>
                    </div>

                    {!isUniversal && (
                      <div className="form-group">
                        <Form.Item
                          name="is_event_partner"
                          label={t('resources_is_event_partner')}
                        >
                          <Checkbox
                            checked={isEventPartner}
                            onChange={onChangeIsEventPartnerHandler}
                            disabled={isLoading || isSubmitting}
                          />
                        </Form.Item>
                      </div>
                    )}
                    {isEventPartner && (
                      <>
                        <div className="form-group">
                          <Form.Item
                            name="is_multiple_care_guide"
                            label={t('resources_is_multiple_care_guide')}
                          >
                            <Checkbox
                              checked={isMultipleCareGuide}
                              onChange={(evt) =>
                                setIsMultipleCareGuide(evt.target.checked)
                              }
                              disabled={isLoading || isSubmitting}
                            />
                          </Form.Item>
                        </div>

                        <PartnershipInput
                          form={form}
                          name="partnership"
                          label={t('resources_partnership')}
                          disabled={isLoading || isSubmitting}
                          onChange={onChangePartnership}
                          rules={[
                            {
                              required: true,
                              message: t('feedback_validation_required'),
                            },
                          ]}
                        />
                      </>
                    )}

                    {isEventPartner ? (
                      <>
                        {!id && (
                          <UserPartnerPartnershipInput
                            partnershipId={partnershipId}
                            name="profile"
                            label={t('resource_profile')}
                            rules={[
                              {
                                required: true,
                                message: t('feedback_validation_required'),
                              },
                            ]}
                            form={form}
                            disabled={isLoading || isSubmitting}
                          />
                        )}

                        {isMultipleCareGuide ? (
                          <>
                            <CareGuidePartnershipInput
                              form={form}
                              multiple={true}
                              name="care_guides"
                              label={t('resource_care_guides')}
                              partnershipId={partnershipId}
                              onChange={handleChange}
                              rules={[
                                {
                                  required: true,
                                  message: t('feedback_validation_required'),
                                },
                              ]}
                            />
                          </>
                        ) : null}
                      </>
                    ) : (
                      !isUniversal && (
                        <>
                          <CareGuideInput
                            form={form}
                            name="care_guide"
                            label={t('resource_care_guide')}
                            disabled={isUniversal || isLoading || isSubmitting}
                            onChange={onChangeCareGuide}
                            rules={[
                              {
                                required: true,
                                message: t('feedback_validation_required'),
                              },
                            ]}
                          />
                          {!id && (
                            <UserCareGuidesInput
                              careGuideIds={careGuideIds}
                              name="profile"
                              label={t('resource_profile')}
                              rules={[
                                {
                                  required: true,
                                  message: t('feedback_validation_required'),
                                },
                              ]}
                              form={form}
                              disabled={isLoading || isSubmitting}
                            />
                          )}
                        </>
                      )
                    )}

                    <div className="form-group">
                      <Form.Item
                        name="name"
                        label={t('resources_name')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 2,
                            max: 120,
                            message: t('feedback_validation_char_range', {
                              min: 2,
                              max: 120,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <BodyInput
                      name="description"
                      label={t('resources_description')}
                      form={form}
                      defaultValue={form.getFieldValue(['description'])}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="telephone"
                        label={t('resources_telephone')}
                        rules={[
                          {
                            type: 'string',
                            min: 10,
                            max: 15,
                            message: t('feedback_validation_char_range', {
                              min: 10,
                              max: 15,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item name="website" label={t('resources_website')}>
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="email"
                        label={t('resources_email')}
                        rules={[
                          {
                            type: 'email',
                            message: t('feedback_validation_email'),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <TagInput
                      name="tags"
                      label={t('resources_tags')}
                      disabled={isLoading || isSubmitting}
                    />

                    <ServiceTagInput
                      name="service_tags"
                      label={t('resources_service_tags')}
                      disabled={isLoading || isSubmitting}
                    />

                    {isEventPartner && (
                      <>
                        <div className="form-group">
                          <AdminTagInput
                            partnershipId={partnershipId}
                            disabled={isLoading || isSubmitting}
                            setClasses={setClasses}
                          />
                        </div>
                        <Form.Item
                          style={{ display: 'none' }}
                          name="resources_care_partner_tags"
                        >
                          <Input type="hidden" />
                        </Form.Item>
                        <Row>
                          <Col offset={layout.main.labelCol.span}>
                            {form.getFieldError(
                              'resources_care_partner_tags'
                            ) && (
                              <h2 className="error-label">
                                {form.getFieldError(
                                  'resources_care_partner_tags'
                                )}
                              </h2>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}

                    {isEventPartner && (
                      <MediaInputPartnership
                        name="logo"
                        label={t('media_media')}
                        disabled={isLoading || isSubmitting}
                        partnershipId={partnershipId}
                        multiple={false}
                        allowNone={true}
                      />
                    )}
                  </Card>

                  <Card
                    title={t('resources_address_title')}
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <CountryInput
                      name="country"
                      label={t('resources_country')}
                      disabled={isLoading || isSubmitting}
                      onChange={(value) => {
                        setCountryId(value);
                      }}
                    />

                    <RegionInput
                      name="region"
                      label={t('resources_region')}
                      disabled={isLoading || isSubmitting}
                      onChange={(value) => setRegionId(value)}
                      country_id={countryId}
                    />

                    <CityInput
                      name="city"
                      label={t('resources_city')}
                      disabled={isLoading || isSubmitting}
                      onChange={(value) => setCityId(value)}
                      region_id={regionId}
                    />

                    <PostalCodeInput
                      name="postal_code"
                      label={t('resources_postal_code')}
                      disabled={isLoading || isSubmitting}
                      city_id={cityId}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="address1"
                        label={t('resources_address1')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 40,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 40,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="address2"
                        label={t('resources_address2')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 40,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 40,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        label={t('resources_latitude')}
                        style={{ marginBottom: 0 }}
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="latitude"
                              rules={[
                                {
                                  type: 'number',
                                  message: t('feedback_validation_number'),
                                },
                                {
                                  type: 'number',
                                  min: -90,
                                  max: 90,
                                  message: t('feedback_validation_range', {
                                    min: -90,
                                    max: 90,
                                  }),
                                },
                              ]}
                            >
                              <InputNumber
                                min={-90}
                                max={90}
                                disabled={isLoading || isSubmitting}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Button
                              icon={<GlobalOutlined />}
                              onClick={onClickGeocodeHandler}
                              loading={props.isGeocodeLoading}
                            >
                              {t('resources_update_geocode')}
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        label={t('resources_longitude')}
                        style={{ marginBottom: 0 }}
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="longitude"
                              rules={[
                                {
                                  type: 'number',
                                  message: t('feedback_validation_number'),
                                },
                                {
                                  type: 'number',
                                  min: -180,
                                  max: 180,
                                  message: t('feedback_validation_range', {
                                    min: -180,
                                    max: 180,
                                  }),
                                },
                              ]}
                            >
                              <InputNumber
                                min={-180}
                                max={180}
                                disabled={isLoading || isSubmitting}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    </div>
                  </Card>

                  <Card
                    title={t('resources_service_area_title')}
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <CountryInput
                      name="countries"
                      label={t('resources_countries')}
                      multiple={true}
                      disabled={isLoading || isSubmitting}
                      onChange={(values) => setCountryIds(values.join(','))}
                    />

                    <RegionInput
                      name="regions"
                      label={t('resources_regions')}
                      multiple={true}
                      disabled={isLoading || isSubmitting}
                      onChange={(values) => setRegionIds(values.join(','))}
                      country_ids={countryIds}
                    />

                    <SubregionInput
                      name="subregions"
                      label={t('resources_subregions')}
                      multiple={true}
                      disabled={isLoading || isSubmitting}
                      onChange={(values) => setSubregionIds(values.join(','))}
                      region_ids={regionsIds}
                    />

                    <CityInput
                      name="cities"
                      label={t('resources_cities')}
                      multiple={true}
                      disabled={isLoading || isSubmitting}
                      onChange={(values) => setCityIds(values.join(','))}
                      subregion_ids={subregionIds}
                    />

                    <PostalCodeInput
                      name="postal_codes"
                      label={t('resources_postal_codes')}
                      multiple={true}
                      disabled={isLoading || isSubmitting}
                      city_ids={cityIds}
                    />
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="ResourcesScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default ResourceForm;

Logger.log('silly', `ResourceForm loaded.`);
