import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES
export const PLANNER_BANK_ENTRY_LIST_REQUEST = 'PLANNER_BANK_ENTRY_LIST_REQUEST';
export const PLANNER_BANK_ENTRY_LIST_SUCCESS = 'PLANNER_BANK_ENTRY_LIST_SUCCESS';
export const PLANNER_BANK_ENTRY_LIST_FAILURE = 'PLANNER_BANK_ENTRY_LIST_FAILURE';
export const PLANNER_BANK_LIST_REQUEST = 'PLANNER_BANK_LIST_REQUEST';
export const PLANNER_BANK_LIST_SUCCESS = 'PLANNER_BANK_LIST_SUCCESS';
export const PLANNER_BANK_LIST_FAILURE = 'PLANNER_BANK_LIST_FAILURE';
export const PLANNER_BANK_READ_REQUEST = 'PLANNER_BANK_READ_REQUEST';
export const PLANNER_BANK_READ_SUCCESS = 'PLANNER_BANK_READ_SUCCESS';
export const PLANNER_BANK_READ_FAILURE = 'PLANNER_BANK_READ_FAILURE';
export const PLANNER_BANK_UPDATE_REQUEST = 'PLANNER_BANK_UPDATE_REQUEST';
export const PLANNER_BANK_UPDATE_SUCCESS = 'PLANNER_BANK_UPDATE_SUCCESS';
export const PLANNER_BANK_UPDATE_FAILURE = 'PLANNER_BANK_UPDATE_FAILURE';
export const PLANNER_BANK_CREATE_REQUEST = 'PLANNER_BANK_CREATE_REQUEST';
export const PLANNER_BANK_CREATE_SUCCESS = 'PLANNER_BANK_CREATE_SUCCESS';
export const PLANNER_BANK_CREATE_FAILURE = 'PLANNER_BANK_CREATE_FAILURE';
export const PLANNER_BANK_DELETE_REQUEST = 'PLANNER_BANK_DELETE_REQUEST';
export const PLANNER_BANK_DELETE_SUCCESS = 'PLANNER_BANK_DELETE_SUCCESS';
export const PLANNER_BANK_DELETE_FAILURE = 'PLANNER_BANK_DELETE_FAILURE';
export const PLANNER_BANK_FORM_DESTROY = 'PLANNER_BANK_FORM_DESTROY';
export const PLANNER_BANK_CSV_DOWNLOAD_REQUEST = 'PLANNER_BANK_CSV_DOWNLOAD_REQUEST';
export const PLANNER_BANK_CSV_DOWNLOAD_SUCCESS = 'PLANNER_BANK_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_BANK_CSV_DOWNLOAD_FAILURE = 'PLANNER_BANK_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerBankListRequest(page, limit, order, filter) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: PLANNER_BANK_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function plannerBankListSuccess(data) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankListSuccess(%j)`, data);
  return {
    type: PLANNER_BANK_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerBankListFailure(error) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankListFailure(%j)`, error);
  return {
    type: PLANNER_BANK_LIST_FAILURE,
    error: error
  }
}

export function plannerBankReadRequest(id) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankReadRequest(${id})`);
  return {
    type: PLANNER_BANK_READ_REQUEST,
    id: id
  }
}

export function plannerBankReadSuccess(data) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankReadSuccess(%j)`, data);
  return {
    type: PLANNER_BANK_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerBankReadFailure(error) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankReadFailure(%j)`, error);
  return {
    type: PLANNER_BANK_READ_FAILURE,
    error: error
  }
}

export function plannerBankUpdateRequest(id, data) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankUpdateRequest(${id}, %j)`, data);
  return {
    type: PLANNER_BANK_UPDATE_REQUEST,
  }
}

export function plannerBankUpdateSuccess(data) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankUpdateSuccess(%j)`, data);
  return {
    type: PLANNER_BANK_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerBankUpdateFailure(error) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankUpdateFailure(%j)`, error);
  return {
    type: PLANNER_BANK_UPDATE_FAILURE,
    error: error
  }
}

export function plannerBankCreateRequest(data) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankCreateRequest(%j)`, data);
  return {
    type: PLANNER_BANK_CREATE_REQUEST,
  }
}

export function plannerBankCreateSuccess(data) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankCreateSuccess(%j)`, data);
  return {
    type: PLANNER_BANK_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerBankCreateFailure(error) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankCreateFailure(%j)`, error);
  return {
    type: PLANNER_BANK_CREATE_FAILURE,
    error: error
  }
}

export function plannerBankDeleteRequest(id) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankDeleteRequest(${id})`);
  return {
    type: PLANNER_BANK_DELETE_REQUEST,
    id: id
  }
}

export function plannerBankDeleteSuccess(id) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankDeleteSuccess(${id})`);
  return {
    type: PLANNER_BANK_DELETE_SUCCESS,
    id: id,
  }
}

export function plannerBankDeleteFailure(error) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankDeleteFailure(%j)`, error);
  return {
    type: PLANNER_BANK_DELETE_FAILURE,
    error: error
  }
}

export function plannerBankFormDestroy(formState=null) {
  Logger.log('debug', `[plannerBanks.actions] plannerBankFormDestroy(%j)`, formState);
  return {
    type: PLANNER_BANK_FORM_DESTROY,
    form: formState
  }
}

export function plannerBankCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.plannerBanks.actions] plannerBankCSVDownloadRequest()`);
  return {
    type: PLANNER_BANK_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function plannerBankCSVDownloadSuccess() {
  Logger.log('debug', `[state.plannerBanks.actions] plannerBankCSVDownloadSuccess()`);
  return {
    type: PLANNER_BANK_CSV_DOWNLOAD_SUCCESS,
  }
}

export function plannerBankCSVDownloadFailure() {
  Logger.log('debug', `[state.plannerBanks.actions] plannerBankCSVDownloadFailure()`);
  return {
    type: PLANNER_BANK_CSV_DOWNLOAD_FAILURE,
  }
}

export function plannerBankEntryListRequest(order, filter) {
  Logger.log('debug', `[state.plannerBanks.actions] plannerBankEntryListRequest(${order}, %j)`, filter);
  return {
    type: PLANNER_BANK_ENTRY_LIST_REQUEST,
    order: order,
    filter: filter
  }
}

export function plannerBankEntryListSuccess(data) {
  Logger.log('debug', `[state.plannerBanks.actions] plannerBankEntryListSuccess(%j)`, data);
  return {
    type: PLANNER_BANK_ENTRY_LIST_SUCCESS,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerBankEntryListFailure(error) {
  Logger.log('debug', `[state.plannerBanks.actions] plannerBankEntryListFailure(%j)`, error);
  return {
    type: PLANNER_BANK_ENTRY_LIST_FAILURE,
    error: error
  }
}


// API THUNK ACTION CREATORS

export function loadPlannerBanks(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[plannerBanks.actions] loadPlannerBanks(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerBankListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlannerBanks(page, limit, order, filter);
    let success = false;

    // get planner bank list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner Bank list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planner_banks']), [schema.plannerBank]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerBankListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API planner bank success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerBankListSuccess(data));
      success = true;

    // get planner bank list failure
    } else {
      Logger.log('info', `Get API planner bank list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(plannerBankListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadPlannerBank(id, cb=function(){}) {
  Logger.log('debug', `[plannerBanks.actions] loadPlannerBank(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerBankReadRequest(id));

    // call API
    const response = await api.getPlannerBank(id);
    let success = false;

    // get planner bank success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner bank success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_bank'])], [schema.plannerBank]);
      const data = {
        id: response.getIn(['data', 'planner_bank', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerBankReadSuccess(data));
      success = true;

    // get planner bank failure
    } else {
      Logger.log('info', `Get API planner bank failure. ID: ${id}.`);
      dispatch(plannerBankReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updatePlannerBank(id, data, cb=function(){}) {
  Logger.log('debug', `[plannerBanks.actions] updatePlannerBank(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerBankUpdateRequest(id, data));

    // call API
    const response = await api.putPlannerBank(id, data);
    let success = false;

    // put planner bank success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API planner bank success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_bank'])], [schema.plannerBank]);
      const data = {
        id: response.getIn(['data', 'planner_bank', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerBankUpdateSuccess(data));
      success = true;

    // get planner bank failure
    } else {
      Logger.log('info', `PUT API planner bank failure. ID: ${id}.`);
      dispatch(plannerBankUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createPlannerBank(data, cb=function(){}) {
  Logger.log('debug', `[plannerBanks.actions] createPlannerBank(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerBankCreateRequest(data));

    // call API
    const response = await api.postPlannerBanks(data);
    let success = false;

    // post planner bank success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API planner bank success. Post: ${response.getIn(['data', 'plannerBank', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_bank'])], [schema.plannerBank]);
      const data = {
        id: response.getIn(['data', 'planner_bank', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerBankCreateSuccess(data));
      success = true;

    // get planner bank failure
    } else {
      Logger.log('info', `POST API planner bank failure.`);
      dispatch(plannerBankCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deletePlannerBank(id, cb=function(){}) {
  Logger.log('debug', `[plannerBanks.actions] deletePlannerBank(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerBankDeleteRequest(id));

    // call API
    const response = await api.deletePlannerBank(id);
    let success = false;

    // delete planner bank success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API planner bank success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'planner_bank', id: id}));
      dispatch(plannerBankDeleteSuccess(id));
      success = true;

    // get planner bank failure
    } else {
      Logger.log('info', `DELETE API planner bank failure. ID: ${id}.`);
      dispatch(plannerBankDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadPlannerBanksCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.plannerBanks.actions] downloadPlannerBanksCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(plannerBankCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlannerBanksCSV(order, filter);

    // get planner bank CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner bank CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-planner-banks-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(plannerBankCSVDownloadSuccess());

    // get planner bank CSV failure
    } else {
      Logger.log('info', `Get API planner bank CSV failure.`);
      dispatch(plannerBankCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

export function loadPlannerBanksEntry(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.plannerBanks.actions] loadPlannerBanksEntry(${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerBankEntryListRequest(order, filter));

    // call API
    const response = await api.getPlannerBanks(page=1, limit=10, order, filter);
    let success = false;

    // get care guide members list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner Bank list success. Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planner_banks']), [schema.plannerBankEntry]);
      const data = {
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(plannerBankEntryListSuccess(data));
      success = true;

    } else if (204 === response.get('status')) {

      Logger.log('info', `Get API planner bank success [empty]. Order: ${order}.`);
      const data = {
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerBankEntryListSuccess(data));
      success = true;
      
    // get care guide helpers list failure
    } else {
      Logger.log('info', `Get API planner bank list failure. Order: ${order}.`);
      dispatch(plannerBankEntryListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

Logger.log('silly', `plannerBanks.actions loaded.`);
