import {getI18n} from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import PlannersScreen from './containers/PlannersScreenContainer';
import PlannerAddScreen from './containers/PlannerAddScreenContainer';
import PlannerEditScreen from './containers/PlannerEditScreenContainer';

const register = () => {
  Logger.log('debug', `planners.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'planners', 'routes'])) {
    Events.dispatch('ADD_MAIN_ROUTES', 
      {
        'PlannerAddScreen': ['PrivateRoute', '/planners/add', true, PlannerAddScreen, getI18n().t('planner_route_add')],
        'PlannerEditScreen': ['PrivateRoute', '/planners/edit/:id([\\w\\-]+)', true, PlannerEditScreen, getI18n().t('planner_route_edit')],
        'PlannersScreen': ['PrivateRoute', '/planners/:page(\\d+)?', true, PlannersScreen, getI18n().t('planners_route_list')],
      }
    );
  }
}

export default register;

Logger.log('silly', `planners.register() loaded.`);
