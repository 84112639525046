import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannerNotes, deletePlannerNote} from '../../../../../state/modules/plannerNotes/actions';
import PlannerNotesList from '../components/PlannerNotesList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.plannerNotes.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['plannerNotes', x]),
              schema.plannerNote,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {

          // flatten some nested objects to make table easier to work with
          return {
            owner_username: x.planner?.owner?.profile?.user?.username || '',
            ...x
          };
        })
      : [],
    isLoading: state.plannerNotes.get('isLoading'),
    total: state.plannerNotes.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlannerNotes(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePlannerNote(id, cb));
    },
  }
}

const PlannerNotesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerNotesList);

export default PlannerNotesListContainer;