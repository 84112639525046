import {connect} from 'react-redux';

import PlannerNoteEditScreen from '../components/PlannerNoteEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerNotes.get('isLoading'),
    success: state.plannerNotes.getIn(['form', 'success']),
  }
}

const PlannerNoteEditScreenContainer = connect(
  mapStateToProps
)(PlannerNoteEditScreen);

export default PlannerNoteEditScreenContainer;
