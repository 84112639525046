import {connect} from 'react-redux';

import {downloadPlannerOwndersCSV} from '../../../../../state/modules/plannerOwners/actions';
import PlannerOwnersCSVButton from '../components/PlannerOwnersCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.plannerOwners.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPlannerOwndersCSV(order, filter, cb));
    }
  }
}

const PlannerOwnersCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerOwnersCSVButton);

export default PlannerOwnersCSVButtonContainer;
