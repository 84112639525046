import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom'
import {Translation, getI18n} from 'react-i18next';
import {Affix, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Space, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2'
import moment from "moment";

import RoleInput from '../../roles/containers/RoleInputContainer';
import TimezoneInput from '../../timezones/containers/TimezoneInputContainer';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import {pathTo} from '../../../Routes';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import ServiceInput from './ServiceInput';

import 'react-phone-input-2/lib/style.css';

const UserForm = ({id, data, errors, load, destroyForm, isLoading, isSubmitting, created_id, ...props}) => {

  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();
  const phoneNumberRegex = new RegExp('^\\d{9,15}$');

  // form column settings
  const layout = {
    main: {
      labelCol: {span: 5},
      wrapperCol: {span: 19},
    },
  }

  const alertOptions = ['journal_new', 'journal_comment', 'journal_emoji', 'calendar_event_new',
    'calendar_event_respond', 'calendar_event_update', 'care_guide_update', 'member_new',
    'member_invite_respond', 'resource_new', 'resource_update'];

  const reminderOptions = ['calendar_event_1_hour', 'calendar_event_24_hours'];

  const [telephoneData, setTelephoneData] = useState({'prefix': '1', 'telephone': ''});

  const handleOnChange = (value, data, event, formattedValue) => {    
    setTelephoneData({'prefix': data.dialCode, 'telephone': value.slice(data.dialCode.length)})
  }

  const validateTelephone = (rule, value) => {
    
    const val = value.slice(telephoneData.prefix.length).replace(/\D/g, '');
    if (val && !phoneNumberRegex.test(val)) {
      return Promise.reject(getI18n().t('feedback_validation_format'));
    } else {
      return Promise.resolve();
    }
  }


  // initialize summary at options
  const summaryAtOptions = [];
  const interval = 60;
  const ap = ['AM', 'PM'];
  let tt = 0;
  
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60);
    var mm = (tt % 60);
    let valueSA = ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2) + ':00';
    let labelSA = ("" + ((hh === 12 || hh === 0) ? 12 : hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)];
    summaryAtOptions[i] = {
      value: valueSA,
      label: labelSA
    }
    tt = tt + interval;
  }

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  useEffect(() => {
    if (id && !isSubmitting) {
      setTelephoneData({'prefix': data.prefix_telephone, 'telephone': data.telephone})
      data.telephone = data.prefix_telephone + data.telephone;
      data.recurring_end_at = data.recurring_end_at
      ? moment(data.recurring_end_at, Config.get("API_DATETIME_FORMAT"))
      : null;

      form.setFieldsValue(data);
    }
  }, [form, data, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{name: field, errors: errors[field]}]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('UserEditScreen', {id: created_id}));
    }
    return () => {
      destroyForm();
    }
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `UserForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {
      'profile': {}
    };
    for (const input of Object.keys(data)) {
      if (input in values) {
        if (['first_name', 'last_name', 'timezone', 'alert_summary_at'].includes(input)) {
          payload['profile'][input] = values[input];
        }

        if (['telephone'].includes(input)) {
          payload['profile']['telephone'] = telephoneData.telephone;
          payload['profile']['prefix_telephone'] = telephoneData.prefix;
          payload['prefix_telephone'] = telephoneData.prefix;
          payload['telephone'] = telephoneData.telephone;
        } else if (['customer_id'].includes(input)) {
          payload['profile'][input] = values[input] ? values[input] : null;
        }
        else if (['remember_at'].includes(input)) {
          payload[input] = Format.date(values[input], Config.get('API_DATETIME_FORMAT'));
        }
        else if (['joined_at'].includes(input)) {
          payload['profile'][input] = Format.date(values[input], Config.get('API_DATETIME_FORMAT'));
        }
        
        else if (['is_verified', 'is_enrolled_otp', 'is_remember_login'].includes(input)) {
          payload[input] = values[input] ? true : false;
        }

        // transform entity refs to IDs
        else if (['registration_code'].includes(input)) {
          payload[input + '_id'] = values[input];
        }

        // alert bools
        else if (['alerts_in_app', 'alerts_email', 'alerts_sms'].includes(input)) {
          const alertValues = {};
          for (const alrt of alertOptions) {
            if (values[input]) {
              alertValues[alrt] = values[input].includes(alrt);
            }
          }
          payload['profile'][input] = alertValues;
        }
        // reminder bools
        else if (['reminders_email', 'reminders_sms'].includes(input)) {
          const reminderValues = {};
          for (const rmndr of reminderOptions) {
            if (values[input]) {
              reminderValues[rmndr] = values[input].includes(rmndr);
            }
          }
          payload['profile'][input] = reminderValues;
        }

        // handle radio buttons
        else if (['alert_summary'].includes(input)) {
          payload['profile'][input] = values[input] === 2 ? true : false;
        }
        else {
          payload[input] = values[input];
        }
      }
    }

if (id) { // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else { // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });      
    }
  }

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `UserForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  }

  // form error handler
  const handleFinishFailed = ({values, errorFields, outOfDate}) => {
    Logger.log('debug', `UserForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>{(t) => 
      <>
        <div className="user-form">
          <Form
            name="user_form"
            form={form}
            initialValues={data}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            validateTrigger="onSubmit"
            {...layout.main}
          >
            <Row gutter={16}>

              <Col xs={24} lg={18}>
                <Card
                  title={id ? t('users_edit_title') : t('users_add_title')}
                  extra={isLoading
                    ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                    : null}
                >

                  <div className="form-group">
                    <Form.Item
                      name="username"
                      label={t('users_username')}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                        {type: 'string', min: 2, max: 40, message: t('feedback_validation_char_range', {min: 2, max: 40})},
                        {pattern: /^\w+$/, message: t('feedback_validation_alphanumeric')},
                        {pattern: /(?!^\d+$)^.+$/, message: t('feedback_validation_not_number')},
                      ]}
                    >
                      <Input autoFocus disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="email"
                      label={t('users_email')}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                        {type: 'email', message: t('feedback_validation_email')},
                      ]}
                    >
                      <Input disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <RoleInput
                      name="roles"
                      label={t('users_roles')}
                      type="user"
                    />
                  </div>
                  
                  <div className="form-group">
                    <Form.Item
                      name="is_verified"
                      label={t('users_is_verified')}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <ServiceInput
                    name="otp_default_service"
                    label={t('users_otp_default_service')}
                    disabled={isLoading || isSubmitting}
                  />

                  <div className="form-group">
                    <Form.Item
                      name="is_enrolled_otp"
                      label={t('users_is_enrolled_otp')}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="is_telephone_verified"
                      label={t('users_is_telephone_verified')}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="is_remember_login"
                      label={t('users_is_remember_login')}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="remember_at"
                      label={t('users_remember_at')}
                      rules={[]}
                    >
                      <DatePicker
                        showTime
                        disabled={isLoading || isSubmitting}
                        format={Config.get('DEFAULT_DATETIME_FORMAT')}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="otp_secret"
                      label={t('users_otp_secret')}
                      rules={[
                        {type: 'string', min: 0, max: 64, message: t('feedback_validation_char_range', {min: 0, max: 64})},
                      ]}
                    >
                      <Input.Password disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="registration_code"
                      label={t('users_registration_code_id')}
                    >
                      <Input disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                </Card>

                <Card
                  title={t('users_header_profile')}
                >

                  {props.profileId
                    ? <div className="form-group">
                        <Form.Item label={t('users_profile_id')}>
                          <span className="ant-form-text">{props.profileId}</span>
                        </Form.Item>
                      </div>
                    : null}

                  <div className="form-group">
                    <Form.Item
                      name="first_name"
                      label={t('users_first_name')}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                        {type: 'string', min: 1, max: 40, message: t('feedback_validation_char_range', {min: 1, max: 40})},
                      ]}
                    >
                      <Input disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="last_name"
                      label={t('users_last_name')}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                        {type: 'string', min: 2, max: 40, message: t('feedback_validation_char_range', {min: 2, max: 40})},
                      ]}
                    >
                      <Input disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="telephone"
                      label={t('users_telephone')}
                      rules={[
                            {required: true, message: t('feedback_validation_format')},
                            {validator: validateTelephone}]}
                    >
                      <PhoneInput
                        disabled={isLoading || isSubmitting}
                        enableSearch={true} 
                        country={'us'}
                        onChange={handleOnChange}
                  />

                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <TimezoneInput
                      name="timezone"
                      label={t('users_timezones')}
                    />
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="joined_at"
                      label={t('users_joined_at')}
                      rules={[
                        {required: true, message: t('feedback_validation_required')},
                      ]}
                    >
                      <DatePicker
                        showTime
                        disabled={isLoading || isSubmitting}
                        format={Config.get('DEFAULT_DATETIME_FORMAT')}
                      />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="customer_id"
                      label={t('users_customer_id')}
                      rules={[
                        {type: 'string', min: 0, max: 40, message: t('feedback_validation_char_range', {min: 0, max: 40})},
                      ]}
                    >
                      <Input disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                </Card>

                <Card
                  title={t('users_header_alerts')}
                >

                  <div className="form-group checkbox-list">
                    <Form.Item
                      name="alerts_in_app"
                      label={t('users_alerts_in_app')}
                    >
                      <Checkbox.Group options={alertOptions.map(x => {return {value: x, label: t('users_alert_' + x)}})} />
                    </Form.Item>
                  </div>

                  <div className="form-group checkbox-list">
                    <Form.Item
                      name="alerts_email"
                      label={t('users_alerts_email')}
                    >
                      <Checkbox.Group options={alertOptions.map(x => {return {value: x, label: t('users_alert_' + x)}})} />
                    </Form.Item>
                  </div>

                  <div className="form-group checkbox-list">
                    <Form.Item
                      name="alerts_sms"
                      label={t('users_alerts_sms')}
                    >
                      <Checkbox.Group options={alertOptions.map(x => {return {value: x, label: t('users_alert_' + x)}})} />
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="alert_summary"
                      label={t('users_alert_summary')}
                    >
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio value={1}>{t('users_alert_summary_every_time')}</Radio>
                          <Radio value={2}>{t('users_alert_summary_daily_summary')}</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <div className="form-group">
                    <Form.Item
                      name="alert_summary_at"
                      label={t('users_alert_summary_at')}
                    >
                      <Select style={{width: 110}}>
                        {summaryAtOptions.map(x =>
                          <Select.Option
                            key={x.value}
                            value={x.value}
                          >
                            {x.label}
                          </Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                  

                  <div className="form-group checkbox-list">
                    <Form.Item
                      name="reminders_email"
                      label={t('users_reminders_email')}
                    >
                      <Checkbox.Group options={reminderOptions.map(x => {return {value: x, label: t('users_reminder_' + x)}})} />
                    </Form.Item>
                  </div>

                  <div className="form-group checkbox-list">
                    <Form.Item
                      name="reminders_sms"
                      label={t('users_reminders_sms')}
                    >
                      <Checkbox.Group options={reminderOptions.map(x => {return {value: x, label: t('users_reminder_' + x)}})} />
                    </Form.Item>
                  </div>

                </Card>

                <Card
                  title={t('users_header_password')}
                >

                  <div className="form-group">
                    <Form.Item
                      name="password"
                      label={t('users_password')}
                      extra={id ? t('users_password_last_changed_at') : null}
                      rules={[
                        {required: id ? false : true, message: t('feedback_validation_required')},
                        {type: 'string', min: 8, max: 40, message: t('feedback_validation_char_range', {min: 8, max: 40})},
                        {pattern: /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,40}$/, message: t('feedback_validation_password_complexity')},
                      ]}
                    >
                      <Input.Password disabled={isLoading || isSubmitting} />
                    </Form.Item>
                  </div>

                  {props.passwordChangedAt
                    ? <Row>
                        <Col span={layout.main.wrapperCol.span} offset={layout.main.labelCol.span}>
                          <p>{t('users_password_last_changed_at')} <b>{Format.date(props.passwordChangedAt, Config.get('DEFAULT_DATETIME_FORMAT'))}</b></p>
                        </Col>
                      </Row>
                    : null}

                </Card>

              </Col>

              <Col xs={24} lg={6}>
                <Affix offsetTop={10}>
                  <Card title={t('form_metadata_header')}>
                    <FormMetadata
                      id={id}
                      isSubmitting={isSubmitting}
                      delete={props.remove.bind(this)}
                      deleteRedirectTo="UsersScreen"
                      createdAt={props.createdAt}
                      updatedAt={props.updatedAt}
                    />
                  </Card>
                </Affix>
              </Col>

            </Row>
          </Form>
        </div>
      </>
    }</Translation>
  );
}

export default UserForm;

Logger.log('silly', `UserForm loaded.`);
