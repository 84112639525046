import React, {Component} from 'react';
import {Translation} from 'react-i18next';
import {Card, Col, Row, Space, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import CallsList from '../containers/CallsListContainer';
import CallsCSVButton from '../containers/CallsCSVButtonContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Config from '../../../../../Config';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';

class CallsScreen extends Component {

  static defaultProps = {
    limit: Config.get('DEFAULT_LIST_LENGTH', 10),
    isLoading: true,
  }

  scrollToTop = () => {
    Logger.log('debug', `CallsScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }

  render() {

    const loadingIcon = <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />;
    const page = parseInt(this.props.match.params.page ? this.props.match.params.page : 1);
    const {order, ...filter} = QueryString.parse(this.props.location.search);

    return (
      <Translation>{(t) => 
        <div>

          <DocumentHead title={t('calls_route_list')} />

          <Row>
            <Col span={24}>
              <Card
                title={
                  <Space size={10}>
                    {t('calls_list_title')}
                    <CallsCSVButton order={order} filter={filter} />
                  </Space>
                }
                extra={this.props.isLoading ? loadingIcon : null}
              >
                <CallsList
                  key={page}
                  component="CallsScreen"
                  page={page}
                  limit={this.props.limit}
                  order={order || 'priority.asc'}
                  filter={filter}
                />
              </Card>
            </Col>
          </Row>

        </div>
      }</Translation>
    )
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
  }
}

export default CallsScreen;

Logger.log('silly', `CallsScreen loaded.`);
