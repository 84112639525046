import {connect} from 'react-redux';

import PlannerToDosSuggestedScreen from '../components/PlannerToDosSuggestedScreen';

const mapStateToProps = state => {
  return {
    total: state.plannerToDosSuggested.get('total'),
    isLoading: state.plannerToDosSuggested.get('isLoading')
  }
}

const PlannerToDosSuggestedScreenContainer = connect(
  mapStateToProps
)(PlannerToDosSuggestedScreen);

export default PlannerToDosSuggestedScreenContainer;