import {connect} from 'react-redux';

import PlannerAccountCategoriesScreen from '../components/PlannerAccountCategoriesScreen';

const mapStateToProps = state => {
  return {
    total: state.plannerAccountCategories.get('total'),
    isLoading: state.plannerAccountCategories.get('isLoading')
  }
}

const PlannerAccountCategoriesScreenContainer = connect(
  mapStateToProps
)(PlannerAccountCategoriesScreen);

export default PlannerAccountCategoriesScreenContainer;