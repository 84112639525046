import {connect} from 'react-redux';

import ResourceCategoriesScreen from '../components/ResourceCategoriesScreen';

const mapStateToProps = state => {
  return {
    total: state.resourceCategories.get('total'),
    isLoading: state.resourceCategories.get('isLoading')
  }
}

const ResourceCategoriesScreenContainer = connect(
  mapStateToProps
)(ResourceCategoriesScreen);

export default ResourceCategoriesScreenContainer;