import {Map, List} from 'immutable';

import {
  PLANNER_ENTRY_LIST_REQUEST,
  PLANNER_ENTRY_LIST_SUCCESS,
  PLANNER_ENTRY_LIST_FAILURE,
  PLANNER_LIST_REQUEST,
  PLANNER_LIST_SUCCESS,
  PLANNER_LIST_FAILURE,
  PLANNER_READ_REQUEST,
  PLANNER_READ_SUCCESS,
  PLANNER_READ_FAILURE,
  PLANNER_UPDATE_REQUEST,
  PLANNER_UPDATE_SUCCESS,
  PLANNER_UPDATE_FAILURE,
  PLANNER_CREATE_REQUEST,
  PLANNER_CREATE_SUCCESS,
  PLANNER_CREATE_FAILURE,
  PLANNER_DELETE_REQUEST,
  PLANNER_DELETE_SUCCESS,
  PLANNER_DELETE_FAILURE,
  PLANNER_FORM_DESTROY,
  PLANNER_CSV_DOWNLOAD_REQUEST,
  PLANNER_CSV_DOWNLOAD_SUCCESS,
  PLANNER_CSV_DOWNLOAD_FAILURE
} from './actions'
import Logger from '../../../lib/Logger';

export default function planners(
  state=Map({
    isLoading: false,
    isCSVDownloading: false,
    pages: {},
    areEntriesLoading: false,
  }),
  action
) {
  Logger.log('debug', `[planners.reducers] planners(%j, %j)`, state, action);

  switch(action.type) {

    case PLANNER_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case PLANNER_LIST_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        pages: {
          [action.order]: {
            [action.limit]: {
              [action.page]: null
            }
          }
        },
        total: action.total,
        lastUpdated: action.receivedAt
      }).setIn(['pages', action.order, action.limit, action.page], List(action.result));

    case PLANNER_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case PLANNER_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case PLANNER_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case PLANNER_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case PLANNER_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case PLANNER_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case PLANNER_UPDATE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case PLANNER_CREATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null
        }
      });

    case PLANNER_CREATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          created_id: action.id,
          errors: null
        },
        lastUpdated: action.receivedAt
      });

    case PLANNER_CREATE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: false,
          errors: null
        }
      }).setIn(['form', 'errors'], action.error);

    case PLANNER_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true
      });

    case PLANNER_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt
      });

    case PLANNER_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false
      });

    case PLANNER_FORM_DESTROY:
      return state.mergeDeep({
        form: null
      }).set('form', action.form);


    case PLANNER_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true
      });

    case PLANNER_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case PLANNER_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case PLANNER_ENTRY_LIST_REQUEST:
      return state.mergeDeep({
        areEntriesLoading: true
      });

    case PLANNER_ENTRY_LIST_SUCCESS:
      return state.mergeDeep({
        areEntriesLoading: false,
        total: action.total,
        entries: {
          [action.order]: null
        },
        lastUpdated: action.receivedAt
      }).setIn(['entries', action.order], List(action.result));

    case PLANNER_ENTRY_LIST_FAILURE:
      return state.mergeDeep({
        areEntriesLoading: false
      });
  

    default:
      return state;
  }
}

Logger.log('silly', `planners.reducers loaded.`);
