import {connect} from 'react-redux';

import {downloadCallsCSV} from '../../../../../state/modules/calls/actions';
import CallsCSVButton from '../components/CallsCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.calls.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadCallsCSV(order, filter, cb));
    }
  }
}

const CallsCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallsCSVButton);

export default CallsCSVButtonContainer;
