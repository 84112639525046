import {connect} from 'react-redux';

import PlannerModuleEditScreen from '../components/PlannerModuleEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerModules.get('isLoading'),
    success: state.plannerModules.getIn(['form', 'success']),
  }
}

const PlannerModuleEditScreenContainer = connect(
  mapStateToProps
)(PlannerModuleEditScreen);

export default PlannerModuleEditScreenContainer;
