import {connect} from 'react-redux';

import {downloadResourceCategoriesCSV} from '../../../../../state/modules/resourceCategories/actions';
import ResourceCategoriesCSVButton from '../components/ResourceCategoriesCSVButton';

const mapStateToProps = state => {
  return {
    isDownloading: state.resourceCategories.get('isCSVDownloading'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadResourceCategoriesCSV(order, filter, cb));
    }
  }
}

const ResourceCategoriesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceCategoriesCSVButton);

export default ResourceCategoriesCSVButtonContainer;