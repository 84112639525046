import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlanners, deletePlanners} from '../../../../../state/modules/planners/actions';
import PlannersList from '../components/PlannersList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.planners.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['planners', x]),
              schema.planner,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {

          // flatten some nested objects to make table easier to work with
          return {
            count_trusted_helpers: x.count_trusted_helpers,
            owner_id: x.owner ? x.owner.id : '',
            owner_username: x.owner && x.owner.profile && x.owner.profile.user ? x.owner.profile.user.username : '',
            ...x
          };
        })
      : [],
    isLoading: state.planners.get('isLoading'),
    total: state.planners.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlanners(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePlanners(id, cb));
    },
  }
}

const PlannersListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannersList);

export default PlannersListContainer;
