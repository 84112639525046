import { connect } from 'react-redux';

import { downloadPlannerModulesCSV } from '../../../../../state/modules/plannerModules/actions';
import PlannerModulesCSVButton from '../components/PlannerModulesCSVButton';

const mapStateToProps = (state) => {
  return {
    isDownloading: state.plannerModules.get('isCSVDownloading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (order, filter, cb) => {
      dispatch(downloadPlannerModulesCSV(order, filter, cb));
    },
  };
};

const PlannerModulesCSVButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerModulesCSVButton);

export default PlannerModulesCSVButtonContainer;
