import {connect} from 'react-redux';

import PlannerDocumentAddScreen from '../components/PlannerDocumentAddScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.plannerDocuments.get('isLoading'),
    success: state.plannerDocuments.getIn(['form', 'success']),
  }
}

const PlannerDocumentAddScreenContainer = connect(
  mapStateToProps
)(PlannerDocumentAddScreen);

export default PlannerDocumentAddScreenContainer;
