import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannerModules, deletePlannerModule} from '../../../../../state/modules/plannerModules/actions';
import PlannerModulesList from '../components/PlannerModulesList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.plannerModules.getIn(['pages', order, limit, page]);

  const list = result 
  ? result.map(x => {
      return {
        key: x,
        ...denormalize(
          state.entities.getIn(['plannerModules', x]),
          schema.plannerModule,
          state.entities.toJS()
        )
      };
    }).toArray().map(x => {

      // flatten some nested objects to make table easier to work with
      return {
        owner_id: x.owner ? x.owner.id : '',
        owner_username: x.owner && x.owner.profile && x.owner.profile.user ? x.owner.profile.user.username : '',
        state_poa: x.planner_poa ? x.planner_poa.state : 4,
        state_legal: x.planner_legal ? x.planner_legal.state : 4,
        state_budget: x.planner_budget ? x.planner_budget.state : 4,
        state_financial: x.planner_financial ? x.planner_financial.state : 4,
        ...x
      };
    })
  : [];

  return {
    list: list,
    isLoading: state.plannerModules.get('isLoading'),
    total: state.plannerModules.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlannerModules(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePlannerModule(id, cb));
    },
  }
}

const PlannerModulesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerModulesList);

export default PlannerModulesListContainer;
