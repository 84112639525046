import {connect} from 'react-redux';

import ResourceCategoryEditScreen from '../components/ResourceCategoryEditScreen';

const mapStateToProps = state => {
  return {
    isLoading: state.resourceCategories.get('isLoading'),
    success: state.resourceCategories.getIn(['form', 'success']),
  }
}

const ResourceCategoryEditScreenContainer = connect(
  mapStateToProps
)(ResourceCategoryEditScreen);

export default ResourceCategoryEditScreenContainer;
