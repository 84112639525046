import { connect } from 'react-redux';
import { List } from 'immutable';

import {
  loadPlannerResource,
  updatePlannerResource,
  createPlannerResource,
  deletePlannerResource,
  plannerResourceFormDestroy,
} from '../../../../../state/modules/plannerResources/actions';
import {
  getImageUploadURL,
  uploadImage,
  createMediaImage,
} from '../../../../../state/modules/media/actions';
import { sendMessage } from '../../../../../state/actions';
import PlannerResourceForm from '../components/PlannerResourceForm';

const inputs = List([
  'is_pinned',
  'name',
  'description',
  'telephone',
  'website',
  'email',
  'planner',
  'planners',
  'amount_planners_assigned',
  'categories',
  'status',
  'logo',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};

  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(
      ['plannerResources', ownProps.id, val],
      ''
    );

    if (['logo'].includes(val)) {
      errors['logo'] = state.plannerResources.getIn([
        'form',
        'errors',
        val + '_id',
      ]);
    }
    if (state.plannerResources.getIn(['form', 'errors', val])) {
      errors[val] = state.plannerResources.getIn(['form', 'errors', val]);
    }
  }

  Object.keys(data).forEach((x) => {
    // change select number values to strings to work with ant design select inputs
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  const planner = data['planner'] ?? null;

  if (Array.isArray(data['planners'])) {
    if (data['planners'].length < 1) {
      data['planners'] = planner ? [planner] : [];
    }
  } else {
    data['planners'] = planner ? [planner] : [];
  }

  if (data['categories'] === '') {
    data['categories'] = [];
  }

  return {
    isLoading: state.plannerResources.get('isLoading'),
    isSubmitting: state.plannerResources.getIn(['form', 'isSubmitting']),
    success: state.plannerResources.getIn(['form', 'success']),
    created_id: state.plannerResources.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['plannerResources', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['plannerResources', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerResource(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePlannerResource(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPlannerResource(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlannerResource(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(plannerResourceFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    createImage: (data, cb) => {
      dispatch(createMediaImage(data, cb));
    },
  };
};

const PlannerResourceFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerResourceForm);

export default PlannerResourceFormContainer;
