import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLANNER_TO_DO_SUGGESTED_LIST_REQUEST = 'PLANNER_TO_DO_SUGGESTED_LIST_REQUEST';
export const PLANNER_TO_DO_SUGGESTED_LIST_SUCCESS = 'PLANNER_TO_DO_SUGGESTED_LIST_SUCCESS';
export const PLANNER_TO_DO_SUGGESTED_LIST_FAILURE = 'PLANNER_TO_DO_SUGGESTED_LIST_FAILURE';
export const PLANNER_TO_DO_SUGGESTED_READ_REQUEST = 'PLANNER_TO_DO_SUGGESTED_READ_REQUEST';
export const PLANNER_TO_DO_SUGGESTED_READ_SUCCESS = 'PLANNER_TO_DO_SUGGESTED_READ_SUCCESS';
export const PLANNER_TO_DO_SUGGESTED_READ_FAILURE = 'PLANNER_TO_DO_SUGGESTED_READ_FAILURE';
export const PLANNER_TO_DO_SUGGESTED_UPDATE_REQUEST = 'PLANNER_TO_DO_SUGGESTED_UPDATE_REQUEST';
export const PLANNER_TO_DO_SUGGESTED_UPDATE_SUCCESS = 'PLANNER_TO_DO_SUGGESTED_UPDATE_SUCCESS';
export const PLANNER_TO_DO_SUGGESTED_UPDATE_FAILURE = 'PLANNER_TO_DO_SUGGESTED_UPDATE_FAILURE';
export const PLANNER_TO_DO_SUGGESTED_CREATE_REQUEST = 'PLANNER_TO_DO_SUGGESTED_CREATE_REQUEST';
export const PLANNER_TO_DO_SUGGESTED_CREATE_SUCCESS = 'PLANNER_TO_DO_SUGGESTED_CREATE_SUCCESS';
export const PLANNER_TO_DO_SUGGESTED_CREATE_FAILURE = 'PLANNER_TO_DO_SUGGESTED_CREATE_FAILURE';
export const PLANNER_TO_DO_SUGGESTED_DELETE_REQUEST = 'PLANNER_TO_DO_SUGGESTED_DELETE_REQUEST';
export const PLANNER_TO_DO_SUGGESTED_DELETE_SUCCESS = 'PLANNER_TO_DO_SUGGESTED_DELETE_SUCCESS';
export const PLANNER_TO_DO_SUGGESTED_DELETE_FAILURE = 'PLANNER_TO_DO_SUGGESTED_DELETE_FAILURE';
export const PLANNER_TO_DO_SUGGESTED_FORM_DESTROY = 'PLANNER_TO_DO_SUGGESTED_FORM_DESTROY';
export const PLANNER_TO_DO_SUGGESTED_CSV_DOWNLOAD_REQUEST = 'PLANNER_TO_DO_SUGGESTED_CSV_DOWNLOAD_REQUEST';
export const PLANNER_TO_DO_SUGGESTED_CSV_DOWNLOAD_SUCCESS = 'PLANNER_TO_DO_SUGGESTED_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_TO_DO_SUGGESTED_CSV_DOWNLOAD_FAILURE = 'PLANNER_TO_DO_SUGGESTED_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerToDoSuggestedListRequest(page, limit, order, filter) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: PLANNER_TO_DO_SUGGESTED_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function plannerToDoSuggestedListSuccess(data) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedListSuccess(%j)`, data);
  return {
    type: PLANNER_TO_DO_SUGGESTED_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerToDoSuggestedListFailure(error) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedListFailure(%j)`, error);
  return {
    type: PLANNER_TO_DO_SUGGESTED_LIST_FAILURE,
    error: error
  }
}

export function plannerToDoSuggestedReadRequest(id) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedReadRequest(${id})`);
  return {
    type: PLANNER_TO_DO_SUGGESTED_READ_REQUEST,
    id: id
  }
}

export function plannerToDoSuggestedReadSuccess(data) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedReadSuccess(%j)`, data);
  return {
    type: PLANNER_TO_DO_SUGGESTED_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerToDoSuggestedReadFailure(error) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedReadFailure(%j)`, error);
  return {
    type: PLANNER_TO_DO_SUGGESTED_READ_FAILURE,
    error: error
  }
}

export function plannerToDoSuggestedUpdateRequest(id, data) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedUpdateRequest(${id}, %j)`, data);
  return {
    type: PLANNER_TO_DO_SUGGESTED_UPDATE_REQUEST,
  }
}

export function plannerToDoSuggestedUpdateSuccess(data) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedUpdateSuccess(%j)`, data);
  return {
    type: PLANNER_TO_DO_SUGGESTED_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerToDoSuggestedUpdateFailure(error) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedUpdateFailure(%j)`, error);
  return {
    type: PLANNER_TO_DO_SUGGESTED_UPDATE_FAILURE,
    error: error
  }
}

export function plannerToDoSuggestedCreateRequest(data) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedCreateRequest(%j)`, data);
  return {
    type: PLANNER_TO_DO_SUGGESTED_CREATE_REQUEST,
  }
}

export function plannerToDoSuggestedCreateSuccess(data) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedCreateSuccess(%j)`, data);
  return {
    type: PLANNER_TO_DO_SUGGESTED_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerToDoSuggestedCreateFailure(error) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedCreateFailure(%j)`, error);
  return {
    type: PLANNER_TO_DO_SUGGESTED_CREATE_FAILURE,
    error: error
  }
}

export function plannerToDoSuggestedDeleteRequest(id) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedDeleteRequest(${id})`);
  return {
    type: PLANNER_TO_DO_SUGGESTED_DELETE_REQUEST,
    id: id
  }
}

export function plannerToDoSuggestedDeleteSuccess(id) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedDeleteSuccess(${id})`);
  return {
    type: PLANNER_TO_DO_SUGGESTED_DELETE_SUCCESS,
    id: id,
  }
}

export function plannerToDoSuggestedDeleteFailure(error) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedDeleteFailure(%j)`, error);
  return {
    type: PLANNER_TO_DO_SUGGESTED_DELETE_FAILURE,
    error: error
  }
}

export function plannerToDoSuggestedFormDestroy(formState=null) {
  Logger.log('debug', `[plannerToDosSuggested.actions] plannerToDoSuggestedFormDestroy(%j)`, formState);
  return {
    type: PLANNER_TO_DO_SUGGESTED_FORM_DESTROY,
    form: formState
  }
}

export function plannerToDoSuggestedCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.plannerToDosSuggested.actions] plannerToDoSuggestedCSVDownloadRequest()`);
  return {
    type: PLANNER_TO_DO_SUGGESTED_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function plannerToDoSuggestedCSVDownloadSuccess() {
  Logger.log('debug', `[state.plannerToDosSuggested.actions] plannerToDoSuggestedCSVDownloadSuccess()`);
  return {
    type: PLANNER_TO_DO_SUGGESTED_CSV_DOWNLOAD_SUCCESS,
  }
}

export function plannerToDoSuggestedCSVDownloadFailure() {
  Logger.log('debug', `[state.plannerToDosSuggested.actions] plannerToDoSuggestedCSVDownloadFailure()`);
  return {
    type: PLANNER_TO_DO_SUGGESTED_CSV_DOWNLOAD_FAILURE,
  }
}

// API THUNK ACTION CREATORS

export function loadPlannerToDosSuggested(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[plannerToDosSuggested.actions] loadPlannerToDosSuggested(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerToDoSuggestedListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlannerToDosSuggested(page, limit, order, filter);
    let success = false;

    // get planner to do suggested list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API Planner To Do Suggested list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planner_to_dos_suggested']), [schema.plannerToDoSuggested]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerToDoSuggestedListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API planner to do suggested success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerToDoSuggestedListSuccess(data));
      success = true;

    // get planner to do suggested list failure
    } else {
      Logger.log('info', `Get API planner to do suggested list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(plannerToDoSuggestedListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadPlannerToDoSuggested(id, cb=function(){}) {
  Logger.log('debug', `[plannerToDosSuggested.actions] loadPlannerToDoSuggested(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerToDoSuggestedReadRequest(id));

    // call API
    const response = await api.getPlannerToDoSuggested(id);
    let success = false;

    // get planner to do suggested success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner to do suggested success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_to_do_suggested'])], [schema.plannerToDoSuggested]);
      const data = {
        id: response.getIn(['data', 'planner_to_do_suggested', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerToDoSuggestedReadSuccess(data));
      success = true;

    // get planner to do suggested failure
    } else {
      Logger.log('info', `Get API planner to do suggested failure. ID: ${id}.`);
      dispatch(plannerToDoSuggestedReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updatePlannerToDoSuggested(id, data, cb=function(){}) {
  Logger.log('debug', `[plannerToDosSuggested.actions] updatePlannerToDoSuggested(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerToDoSuggestedUpdateRequest(id, data));

    // call API
    const response = await api.putPlannerToDoSuggested(id, data);
    let success = false;

    // put planner to do suggested success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API planner to do suggested success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_to_do_suggested'])], [schema.plannerToDoSuggested]);
      const data = {
        id: response.getIn(['data', 'planner_to_do_suggested', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerToDoSuggestedUpdateSuccess(data));
      success = true;

    // get planner to do suggested failure
    } else {
      Logger.log('info', `PUT API planner to do suggested failure. ID: ${id}.`);
      dispatch(plannerToDoSuggestedUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createPlannerToDoSuggested(data, cb=function(){}) {
  Logger.log('debug', `[plannerToDosSuggested.actions] createPlannerToDoSuggested(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerToDoSuggestedCreateRequest(data));

    // call API
    const response = await api.postPlannerToDoSuggested(data);
    let success = false;

    // post planner to do suggested success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API planner to do suggested success. Post: ${response.getIn(['data', 'plannerToDoSuggested', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_to_do_suggested'])], [schema.plannerToDoSuggested]);
      const data = {
        id: response.getIn(['data', 'planner_to_do_suggested', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerToDoSuggestedCreateSuccess(data));
      success = true;

    // get planner to do suggested failure
    } else {
      Logger.log('info', `POST API planner to do suggested failure.`);
      dispatch(plannerToDoSuggestedCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deletePlannerToDoSuggested(id, cb=function(){}) {
  Logger.log('debug', `[plannerToDosSuggested.actions] deletePlannerToDoSuggested(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerToDoSuggestedDeleteRequest(id));

    // call API
    const response = await api.deletePlannerToDoSuggested(id);
    let success = false;

    // delete planner to do suggested success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API planner to do suggested success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'planner_to_do_suggested', id: id}));
      dispatch(plannerToDoSuggestedDeleteSuccess(id));
      success = true;

    // get planner to do suggested failure
    } else {
      Logger.log('info', `DELETE API planner to do suggested failure. ID: ${id}.`);
      dispatch(plannerToDoSuggestedDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadPlannerToDosSuggestedCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.plannerToDosSuggested.actions] downloadPlannerToDosSuggestedCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(plannerToDoSuggestedCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlannerToDosSuggestedCSV(order, filter);

    // get planner to do suggested CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner to do suggested CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-planner-to-dos-suggested-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(plannerToDoSuggestedCSVDownloadSuccess());

    // get planner to do suggested CSV failure
    } else {
      Logger.log('info', `Get API planner to do suggested CSV failure.`);
      dispatch(plannerToDoSuggestedCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `plannerToDosSuggested.actions loaded.`);
