import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLANNER_OWNER_LIST_REQUEST = 'PLANNER_OWNER_LIST_REQUEST';
export const PLANNER_OWNER_LIST_SUCCESS = 'PLANNER_OWNER_LIST_SUCCESS';
export const PLANNER_OWNER_LIST_FAILURE = 'PLANNER_OWNER_LIST_FAILURE';
export const PLANNER_OWNER_READ_REQUEST = 'PLANNER_OWNER_READ_REQUEST';
export const PLANNER_OWNER_READ_SUCCESS = 'PLANNER_OWNER_READ_SUCCESS';
export const PLANNER_OWNER_READ_FAILURE = 'PLANNER_OWNER_READ_FAILURE';
export const PLANNER_OWNER_UPDATE_REQUEST = 'PLANNER_OWNER_UPDATE_REQUEST';
export const PLANNER_OWNER_UPDATE_SUCCESS = 'PLANNER_OWNER_UPDATE_SUCCESS';
export const PLANNER_OWNER_UPDATE_FAILURE = 'PLANNER_OWNER_UPDATE_FAILURE';
export const PLANNER_OWNER_CREATE_REQUEST = 'PLANNER_OWNER_CREATE_REQUEST';
export const PLANNER_OWNER_CREATE_SUCCESS = 'PLANNER_OWNER_CREATE_SUCCESS';
export const PLANNER_OWNER_CREATE_FAILURE = 'PLANNER_OWNER_CREATE_FAILURE';
export const PLANNER_OWNER_DELETE_REQUEST = 'PLANNER_OWNER_DELETE_REQUEST';
export const PLANNER_OWNER_DELETE_SUCCESS = 'PLANNER_OWNER_DELETE_SUCCESS';
export const PLANNER_OWNER_DELETE_FAILURE = 'PLANNER_OWNER_DELETE_FAILURE';
export const PLANNER_OWNER_FORM_DESTROY = 'PLANNER_OWNER_FORM_DESTROY';
export const PLANNER_OWNER_CSV_DOWNLOAD_REQUEST = 'PLANNER_OWNER_CSV_DOWNLOAD_REQUEST';
export const PLANNER_OWNER_CSV_DOWNLOAD_SUCCESS = 'PLANNER_OWNER_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_OWNER_CSV_DOWNLOAD_FAILURE = 'PLANNER_OWNER_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerOwnerListRequest(page, limit, order, filter) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: PLANNER_OWNER_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function plannerOwnerListSuccess(data) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerListSuccess(%j)`, data);
  return {
    type: PLANNER_OWNER_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerOwnerListFailure(error) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerListFailure(%j)`, error);
  return {
    type: PLANNER_OWNER_LIST_FAILURE,
    error: error
  }
}

export function plannerOwnerReadRequest(id) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerReadRequest(${id})`);
  return {
    type: PLANNER_OWNER_READ_REQUEST,
    id: id
  }
}

export function plannerOwnerReadSuccess(data) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerReadSuccess(%j)`, data);
  return {
    type: PLANNER_OWNER_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerOwnerReadFailure(error) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerReadFailure(%j)`, error);
  return {
    type: PLANNER_OWNER_READ_FAILURE,
    error: error
  }
}

export function plannerOwnerUpdateRequest(id, data) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerUpdateRequest(${id}, %j)`, data);
  return {
    type: PLANNER_OWNER_UPDATE_REQUEST,
  }
}

export function plannerOwnerUpdateSuccess(data) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerUpdateSuccess(%j)`, data);
  return {
    type: PLANNER_OWNER_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerOwnerUpdateFailure(error) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerUpdateFailure(%j)`, error);
  return {
    type: PLANNER_OWNER_UPDATE_FAILURE,
    error: error
  }
}

export function plannerOwnerCreateRequest(data) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerCreateRequest(%j)`, data);
  return {
    type: PLANNER_OWNER_CREATE_REQUEST,
  }
}

export function plannerOwnerCreateSuccess(data) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerCreateSuccess(%j)`, data);
  return {
    type: PLANNER_OWNER_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerOwnerCreateFailure(error) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerCreateFailure(%j)`, error);
  return {
    type: PLANNER_OWNER_CREATE_FAILURE,
    error: error
  }
}

export function plannerOwnerDeleteRequest(id) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerDeleteRequest(${id})`);
  return {
    type: PLANNER_OWNER_DELETE_REQUEST,
    id: id
  }
}

export function plannerOwnerDeleteSuccess(id) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerDeleteSuccess(${id})`);
  return {
    type: PLANNER_OWNER_DELETE_SUCCESS,
    id: id,
  }
}

export function plannerOwnerDeleteFailure(error) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerDeleteFailure(%j)`, error);
  return {
    type: PLANNER_OWNER_DELETE_FAILURE,
    error: error
  }
}

export function plannerOwnerFormDestroy(formState=null) {
  Logger.log('debug', `[plannerOwners.actions] plannerOwnerFormDestroy(%j)`, formState);
  return {
    type: PLANNER_OWNER_FORM_DESTROY,
    form: formState
  }
}

export function plannerOwnerCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.plannerOwners.actions] plannerOwnerCSVDownloadRequest()`);
  return {
    type: PLANNER_OWNER_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function plannerOwnerCSVDownloadSuccess() {
  Logger.log('debug', `[state.plannerOwners.actions] plannerOwnerCSVDownloadSuccess()`);
  return {
    type: PLANNER_OWNER_CSV_DOWNLOAD_SUCCESS,
  }
}

export function plannerOwnerCSVDownloadFailure() {
  Logger.log('debug', `[state.plannerOwners.actions] plannerOwnerCSVDownloadFailure()`);
  return {
    type: PLANNER_OWNER_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadPlannerOwners(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[plannerOwners.actions] loadPlannerOwners(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerOwnerListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlannerOwners(page, limit, order, filter);
    let success = false;

    // get planner owners list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner owners list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planner_owners']), [schema.plannerOwner]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerOwnerListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API planner owners success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerOwnerListSuccess(data));
      success = true;
      
    // get planner owners list failure
    } else {
      Logger.log('info', `Get API planner owners list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(plannerOwnerListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function loadPlannerOwner(id, cb=function(){}) {
  Logger.log('debug', `[plannerOwners.actions] loadPlannerOwner(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerOwnerReadRequest(id));

    // call API
    const response = await api.getPlannerOwner(id);
    let success = false;

    // get planner owner success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner owner success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_owner'])], [schema.plannerOwner]);
      const data = {
        id: response.getIn(['data', 'planner_owner', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerOwnerReadSuccess(data));
      success = true;
      
    // get planner owner failure
    } else {
      Logger.log('info', `Get API planner owner failure. ID: ${id}.`);
      dispatch(plannerOwnerReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updatePlannerOwner(id, data, cb=function(){}) {
  Logger.log('debug', `[plannerOwners.actions] updatePlannerOwner(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerOwnerUpdateRequest(id, data));

    // call API
    const response = await api.putPlannerOwner(id, data);
    let success = false;

    // put planner owner success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API planner owner success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_owner'])], [schema.plannerOwner]);
      const data = {
        id: response.getIn(['data', 'planner_owner', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerOwnerUpdateSuccess(data));
      success = true;
      
    // get planner owner failure
    } else {
      Logger.log('info', `PUT API planner owner failure. ID: ${id}.`);
      dispatch(plannerOwnerUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createPlannerOwner(data, cb=function(){}) {
  Logger.log('debug', `[plannerOwners.actions] createPlannerOwner(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerOwnerCreateRequest(data));

    // call API
    const response = await api.postPlannerOwners(data);
    let success = false;

    // post planner owners success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API planner owners success. Post: ${response.getIn(['data', 'planner_owner', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_owner'])], [schema.plannerOwner]);
      const data = {
        id: response.getIn(['data', 'planner_owner', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerOwnerCreateSuccess(data));
      success = true;
      
    // get planner owners failure
    } else {
      Logger.log('info', `POST API planner owners failure.`);
      dispatch(plannerOwnerCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deletePlannerOwner(id, cb=function(){}) {
  Logger.log('debug', `[plannerOwners.actions] deletePlannerOwner(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerOwnerDeleteRequest(id));

    // call API
    const response = await api.deletePlannerOwner(id);
    let success = false;

    // delete planner owner success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API planner owner success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'planner_owners', id: id}));
      dispatch(plannerOwnerDeleteSuccess(id));
      success = true;
      
    // get planner owner failure
    } else {
      Logger.log('info', `DELETE API planner owner failure. ID: ${id}.`);
      dispatch(plannerOwnerDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadPlannerOwndersCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.plannerOwners.actions] downloadPlannerOwndersCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(plannerOwnerCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlannerOwnersCSV(order, filter);

    // get planner owners CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner owners CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-planner-owners-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(plannerOwnerCSVDownloadSuccess());
      
    // get planner owners CSV failure
    } else {
      Logger.log('info', `Get API planner owners CSV failure.`);
      dispatch(plannerOwnerCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `plannerOwners.actions loaded.`);
