import React from 'react';
import { Translation } from 'react-i18next';
import { Button, Space } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
} from "antd";
import useWindowDimensions from '../../../../../lib/WindowDimensions';


const FormMetadataPlanner = ({ id, isSubmitting, isLoading, ...props }) => {

  const { width } = useWindowDimensions();

  return (
    <Translation>{(t) =>
      <>

        {id ? (
          <div className="form-group">
            <Form.Item name="id" label={t("planner_planner_id")}>
              <span className="ant-form-text">{id}</span>
            </Form.Item>
          </div>
        ) :
          <div className="form-group">
            <Form.Item
              name="id"
              label={t("planner_planner_id")}
              rules={[
                {
                  required: true,
                  message: t("feedback_validation_required"),
                },
              ]}
            >
              <Input disabled={isLoading || isSubmitting} />
            </Form.Item>
          </div>
        }
        <div className="form-actions">

          <Space>
            <Button
              type="primary"
              icon={<CheckCircleOutlined />}
              htmlType="submit"
              loading={isSubmitting}
            >
              {(id) || (width < 992 || width >= 1200) ? t('form_button_submit') : null}
            </Button>

          </Space>

        </div>
      </>
    }</Translation>
  )
}

export default FormMetadataPlanner;
