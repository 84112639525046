import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannerOwners, deletePlannerOwner} from '../../../../../state/modules/plannerOwners/actions';
import {sendMessage} from '../../../../../state/actions';
import PlannerOwnersList from '../components/PlannerOwnersList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.plannerOwners.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['plannerOwners', x]),
              schema.plannerOwner,
              state.entities.toJS()
            )
          };
        }).toArray().map(x => {

          // flatten some nested objects to make table easier to work with
          return {
            profile_id: x.profile ? x.profile.id : '',
            profile_username: x.profile && x.profile.user ? x.profile.user.username : '',
            ...x
          };
        })
      : [],
    isLoading: state.plannerOwners.get('isLoading'),
    total: state.plannerOwners.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlannerOwners(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePlannerOwner(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PlannerOwnersListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerOwnersList);

export default PlannerOwnersListContainer;
