import {normalize} from 'normalizr';

import api from '../../api';
import {schema} from '../../schema';
import {addEntities, removeEntity} from '../../actions';
import Format from '../../../lib/Format';
import Logger from '../../../lib/Logger';

// ACTION TYPES

export const PLANNER_DOCUMENTS_LIST_REQUEST = 'PLANNER_DOCUMENTS_LIST_REQUEST';
export const PLANNER_DOCUMENTS_LIST_SUCCESS = 'PLANNER_DOCUMENTS_LIST_SUCCESS';
export const PLANNER_DOCUMENTS_LIST_FAILURE = 'PLANNER_DOCUMENTS_LIST_FAILURE';
export const PLANNER_DOCUMENTS_READ_REQUEST = 'PLANNER_DOCUMENTS_READ_REQUEST';
export const PLANNER_DOCUMENTS_READ_SUCCESS = 'PLANNER_DOCUMENTS_READ_SUCCESS';
export const PLANNER_DOCUMENTS_READ_FAILURE = 'PLANNER_DOCUMENTS_READ_FAILURE';
export const PLANNER_DOCUMENTS_UPDATE_REQUEST = 'PLANNER_DOCUMENTS_UPDATE_REQUEST';
export const PLANNER_DOCUMENTS_UPDATE_SUCCESS = 'PLANNER_DOCUMENTS_UPDATE_SUCCESS';
export const PLANNER_DOCUMENTS_UPDATE_FAILURE = 'PLANNER_DOCUMENTS_UPDATE_FAILURE';
export const PLANNER_DOCUMENTS_CREATE_REQUEST = 'PLANNER_DOCUMENTS_CREATE_REQUEST';
export const PLANNER_DOCUMENTS_CREATE_SUCCESS = 'PLANNER_DOCUMENTS_CREATE_SUCCESS';
export const PLANNER_DOCUMENTS_CREATE_FAILURE = 'PLANNER_DOCUMENTS_CREATE_FAILURE';
export const PLANNER_DOCUMENTS_DELETE_REQUEST = 'PLANNER_DOCUMENTS_DELETE_REQUEST';
export const PLANNER_DOCUMENTS_DELETE_SUCCESS = 'PLANNER_DOCUMENTS_DELETE_SUCCESS';
export const PLANNER_DOCUMENTS_DELETE_FAILURE = 'PLANNER_DOCUMENTS_DELETE_FAILURE';
export const PLANNER_DOCUMENTS_FORM_DESTROY = 'PLANNER_DOCUMENTS_FORM_DESTROY';
export const PLANNER_DOCUMENTS_CSV_DOWNLOAD_REQUEST = 'PLANNER_DOCUMENTS_CSV_DOWNLOAD_REQUEST';
export const PLANNER_DOCUMENTS_CSV_DOWNLOAD_SUCCESS = 'PLANNER_DOCUMENTS_CSV_DOWNLOAD_SUCCESS';
export const PLANNER_DOCUMENTS_CSV_DOWNLOAD_FAILURE = 'PLANNER_DOCUMENTS_CSV_DOWNLOAD_FAILURE';

// ACTION CREATORS

export function plannerDocumentsListRequest(page, limit, order, filter) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsListRequest(${page}, ${limit}, ${order}, %j)`, filter);
  return {
    type: PLANNER_DOCUMENTS_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter
  }
}

export function plannerDocumentsListSuccess(data) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsListSuccess(%j)`, data);
  return {
    type: PLANNER_DOCUMENTS_LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now()
  }
}

export function plannerDocumentsListFailure(error) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsListFailure(%j)`, error);
  return {
    type: PLANNER_DOCUMENTS_LIST_FAILURE,
    error: error
  }
}

export function plannerDocumentsReadRequest(id) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsReadRequest(${id})`);
  return {
    type: PLANNER_DOCUMENTS_READ_REQUEST,
    id: id
  }
}

export function plannerDocumentsReadSuccess(data) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsReadSuccess(%j)`, data);
  return {
    type: PLANNER_DOCUMENTS_READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerDocumentsReadFailure(error) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsReadFailure(%j)`, error);
  return {
    type: PLANNER_DOCUMENTS_READ_FAILURE,
    error: error
  }
}

export function plannerDocumentsUpdateRequest(id, data) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsUpdateRequest(${id}, %j)`, data);
  return {
    type: PLANNER_DOCUMENTS_UPDATE_REQUEST,
  }
}

export function plannerDocumentsUpdateSuccess(data) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsUpdateSuccess(%j)`, data);
  return {
    type: PLANNER_DOCUMENTS_UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerDocumentsUpdateFailure(error) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsUpdateFailure(%j)`, error);
  return {
    type: PLANNER_DOCUMENTS_UPDATE_FAILURE,
    error: error
  }
}

export function plannerDocumentsCreateRequest(data) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsCreateRequest(%j)`, data);
  return {
    type: PLANNER_DOCUMENTS_CREATE_REQUEST,
  }
}

export function plannerDocumentsCreateSuccess(data) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsCreateSuccess(%j)`, data);
  return {
    type: PLANNER_DOCUMENTS_CREATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now()
  }
}

export function plannerDocumentsCreateFailure(error) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsCreateFailure(%j)`, error);
  return {
    type: PLANNER_DOCUMENTS_CREATE_FAILURE,
    error: error
  }
}

export function plannerDocumentsDeleteRequest(id) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsDeleteRequest(${id})`);
  return {
    type: PLANNER_DOCUMENTS_DELETE_REQUEST,
    id: id
  }
}

export function plannerDocumentsDeleteSuccess(id) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsDeleteSuccess(${id})`);
  return {
    type: PLANNER_DOCUMENTS_DELETE_SUCCESS,
    id: id,
  }
}

export function plannerDocumentsDeleteFailure(error) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsDeleteFailure(%j)`, error);
  return {
    type: PLANNER_DOCUMENTS_DELETE_FAILURE,
    error: error
  }
}

export function plannerDocumentsFormDestroy(formState=null) {
  Logger.log('debug', `[plannerDocuments.actions] plannerDocumentsFormDestroy(%j)`, formState);
  return {
    type: PLANNER_DOCUMENTS_FORM_DESTROY,
    form: formState
  }
}

export function plannerDocumentsCSVDownloadRequest(order, filter) {
  Logger.log('debug', `[state.plannerDocuments.actions] plannerDocumentsCSVDownloadRequest()`);
  return {
    type: PLANNER_DOCUMENTS_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  }
}

export function plannerDocumentsCSVDownloadSuccess() {
  Logger.log('debug', `[state.plannerDocuments.actions] plannerDocumentsCSVDownloadSuccess()`);
  return {
    type: PLANNER_DOCUMENTS_CSV_DOWNLOAD_SUCCESS,
  }
}

export function plannerDocumentsCSVDownloadFailure() {
  Logger.log('debug', `[state.plannerDocuments.actions] plannerDocumentsCSVDownloadFailure()`);
  return {
    type: PLANNER_DOCUMENTS_CSV_DOWNLOAD_FAILURE,
  }
}


// API THUNK ACTION CREATORS

export function loadPlannerDocuments(page=1, limit=10, order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[plannerDocuments.actions] loadPlannerDocuments(${page}, ${limit}, ${order}, %j, ###)`, filter);

  return async function(dispatch) {
    dispatch(plannerDocumentsListRequest(page, limit, order, filter));

    // call API
    const response = await api.getPlannerDocuments(page, limit, order, filter);
    let success = false;

    // get plannerDocuments list success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API plannerDocuments list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`);

      const normalizedEntities = normalize(response.getIn(['data', 'planner_documents']), [schema.plannerDocument]);
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerDocumentsListSuccess(data));
      success = true;

    } else if (1 === page && 204 === response.get('status')) {

      Logger.log('info', `Get API plannerDocuments success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: []
      };
      dispatch(plannerDocumentsListSuccess(data));
      success = true;
      
    // get planner documents list failure
    } else {
      Logger.log('info', `Get API planner documents list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`);
      dispatch(plannerDocumentsListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}


export function loadPlannerDocument(id, cb=function(){}) {
  Logger.log('debug', `[plannerDocuments.actions] loadPlannerDocument(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerDocumentsReadRequest(id));

    // call API
    const response = await api.getPlannerDocument(id);
    let success = false;

    // get planner document success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner document success. ID: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_document'])], [schema.plannerDocument]);
      const data = {
        id: response.getIn(['data', 'planner_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerDocumentsReadSuccess(data));
      success = true;
      
    // get planner document failure
    } else {
      Logger.log('info', `Get API planner document failure. ID: ${id}.`);
      dispatch(plannerDocumentsReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function updatePlannerDocument(id, data, cb=function(){}) {
  Logger.log('debug', `[plannerDocuments.actions] updatePlannerDocument(${id}, %j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerDocumentsUpdateRequest(id, data));

    // call API
    const response = await api.putPlannerDocument(id, data);
    let success = false;

    // put planner document success
    if (200 === response.get('status')) {

      Logger.log('info', `PUT API planner document success. User: ${id}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_document'])], [schema.plannerDocument]);
      const data = {
        id: response.getIn(['data', 'planner_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerDocumentsUpdateSuccess(data));
      success = true;
      
    // get planner document failure
    } else {
      Logger.log('info', `PUT API planner document failure. ID: ${id}.`);
      dispatch(plannerDocumentsUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function createPlannerDocuments(data, cb=function(){}) {
  Logger.log('debug', `[plannerDocuments.actions] createPlannerDocuments(%j, ###)`, data);

  return async function(dispatch) {
    dispatch(plannerDocumentsCreateRequest(data));

    // call API
    const response = await api.postPlannerDocuments(data);
    let success = false;

    // post planner documents success
    if (201 === response.get('status')) {

      Logger.log('info', `POST API planner documents success. Post: ${response.getIn(['data', 'planner_document', 'id'])}.`);

      const normalizedEntities = normalize([response.getIn(['data', 'planner_document'])], [schema.plannerDocument]);
      const data = {
        id: response.getIn(['data', 'planner_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(plannerDocumentsCreateSuccess(data));
      success = true;
      
    // get planner documents failure
    } else {
      Logger.log('info', `POST API planner documents failure.`);
      dispatch(plannerDocumentsCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function deletePlannerDocument(id, cb=function(){}) {
  Logger.log('debug', `[plannerDocuments.actions] deletePlannerDocument(${id}, ###)`);

  return async function(dispatch) {
    dispatch(plannerDocumentsDeleteRequest(id));

    // call API
    const response = await api.deletePlannerDocument(id);
    let success = false;

    // delete planner document success
    if (204 === response.get('status')) {

      Logger.log('info', `DELETE API planner document success. ID: ${id}.`);

      dispatch(removeEntity({entityType: 'plannerDocuments', id: id}));
      dispatch(plannerDocumentsDeleteSuccess(id));
      success = true;
      
    // get planner document failure
    } else {
      Logger.log('info', `DELETE API planner document failure. ID: ${id}.`);
      dispatch(plannerDocumentsDeleteFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  }
}

export function downloadPlannerDocumentsCSV(order=null, filter=null, cb=function(){}) {
  Logger.log('debug', `[state.plannerDocuments.actions] downloadPlannerDocumentsCSV(${order}, ${filter}, ###)`);

  return async function(dispatch) {
    dispatch(plannerDocumentsCSVDownloadRequest(order, filter));

    // call API
    const response = await api.getPlannerDocumentsCSV(order, filter);

    // get planner documents CSV success
    if (200 === response.get('status')) {

      Logger.log('info', `Get API planner documents CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cv-planner-documents-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`);
      document.body.appendChild(link);
      link.click();

      dispatch(plannerDocumentsCSVDownloadSuccess());
      
    // get planner documents CSV failure
    } else {
      Logger.log('info', `Get API plannerDocuments CSV failure.`);
      dispatch(plannerDocumentsCSVDownloadFailure());
    }

    // callback function
    cb();
  }
}

Logger.log('silly', `plannerDocuments.actions loaded.`);
