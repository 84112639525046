import React, { Component } from 'react';
import { Translation } from 'react-i18next';

import PlannerResourceForm from '../containers/PlannerResourceFormContainer';
import DocumentHead from '../../../elements/components/DocumentHead';
import Logger from '../../../../../lib/Logger';

class PlannerResourceAddScreen extends Component {
  scrollToTop = () => {
    Logger.log('debug', `PlannerResourceAddScreen.scrollToTop()`);
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  };

  render() {
    return (
      <Translation>
        {(t) => (
          <div>
            <DocumentHead title={t('planner_resources_route_add')} />
            <PlannerResourceForm />
          </div>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.scrollToTop();
  }
}

export default PlannerResourceAddScreen;

Logger.log('silly', `PlannerResourceAddScreen loaded.`);
