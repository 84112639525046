import {connect} from 'react-redux';
import {List} from 'immutable';
import moment from 'moment';

import {
  loadPlanner,
  updatePlanner,
  createPlanner,
  deletePlanners,
  plannerFormDestroy,
} from '../../../../../state/modules/planners/actions';
import {sendMessage} from '../../../../../state/actions';
import PlannerForm from '../components/PlannerForm';
import Config from '../../../../../Config';

const inputs = List([
  'account_id',
  'owner',
  'trusted_helpers',
  'care_guide',
  'started_at',
  'status',
]);

const mapStateToProps = (state, ownProps) => {

  const plannerId = ownProps.id || ownProps.copyId;

  const data = {}, errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['planners', plannerId, val], '');
    if (['owner', 'care_guide'].includes(val)) {
      errors[val] = state.planners.getIn(['form', 'errors', val + '_id']);
    }
    else {
      errors[val] = state.planners.getIn(['form', 'errors', val]);
    }
  }
  Object.keys(data).forEach(x => {

    // change select number values to strings to work with ant design select inputs
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }

  });

  data['started_at'] = data['started_at']
    ? moment(data['started_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

    return {
    isLoading: state.planners.get('isLoading'),
    isSubmitting: state.planners.getIn(['form', 'isSubmitting']),
    success: state.planners.getIn(['form', 'success']),
    created_id: state.planners.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(['planners', ownProps.id, 'created_at'], null),
    updatedAt: state.entities.getIn(['planners', ownProps.id, 'updated_at'], null),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (id, cb) => {
      dispatch(loadPlanner(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePlanner(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPlanner(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlanners(id, cb))
    },
    destroyForm: (formState) => {
      dispatch(plannerFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PlannerFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerForm);

export default PlannerFormContainer;
