import {connect} from 'react-redux';
import {denormalize} from 'normalizr';

import {schema} from '../../../../../state/schema';
import {loadPlannerBanks, deletePlannerBank} from '../../../../../state/modules/plannerBanks/actions';
import {sendMessage} from '../../../../../state/actions';
import PlannerBanksList from '../components/PlannerBanksList';

const mapStateToProps = (state, ownProps) => {

  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.plannerBanks.getIn(['pages', order, limit, page]);

  return {
    list: result 
      ? result.map(x => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['plannerBanks', x]),
              schema.plannerBank,
              state.entities.toJS()
            )
          };
        }).toArray()
      : [],
    isLoading: state.plannerBanks.get('isLoading'),
    total: state.plannerBanks.get('total'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadPlannerBanks(page, limit, order, filter, cb));
    },
    remove: (id, cb) => {
      dispatch(deletePlannerBank(id, cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
  }
}

const PlannerBanksListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerBanksList);

export default PlannerBanksListContainer;